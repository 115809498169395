import { combineReducers, createStore } from 'redux';

import {
    authReducer,
    commonReducer
} from '../reducers';

const reducers = combineReducers({
    authReducer: authReducer,
    commonReducer: commonReducer
})
const store = createStore(reducers);
export default store;
