import React from 'react';
function WhiteFooter() {

    return (
        <ul className="portal-menu pl-0 d-flex align-items-center flex-wrap myinboxxmeu">
            <li><a href="#" className="font-11 fr ttu black ">About Us</a></li>
            <li><a href="#" className="font-11 fr ttu black">Services</a></li>
            <li><a href="#" className="font-11 fr ttu black">Lenders</a></li>
            <li><a href="#" className="font-11 fr ttu black">Help Centre</a></li>
            <li><a href="#" className="font-11 fr ttu black">Blog</a></li>
            <li><a href="#" className="font-11 fr ttu black">Contact Us</a></li>
        </ul>
    )
}

export default WhiteFooter;
