import React, { Fragment } from 'react'
import images from '../../../utils/imageHandler/imageHelper'
import { NavLink } from 'react-router-dom';
import { baseUrl } from '../../../Config';

const Content = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <a href="index.html" className="logo-bottom">
                            <img src={images.logo} alt="" />
                        </a>
                        <h4 className=" font-14  ttu fb white ls">CONTACT US</h4>

                        <ul className="mb-5">

                            <li>
                                <a href="tel:0203 978 8837">
                                    <img src={images.callIcon} alt="" />
                                    <span> 0203 978 8837 </span>
                                </a>
                            </li>

                            <li>
                                <a href="mailto:info@Capatoolfinancial.co.uk">
                                    <img src={images.mailIcon} alt="" />
                                    <span>
                                        info@Capatoolfinancial.co.uk </span>
                                </a>
                            </li>

                            <li>
                                <a href="#">
                                    <img src={images.liveChat} alt="" />
                                    <span>Live Chat </span>
                                </a>
                            </li>
                        </ul>

                        <h4 className=" font-14 ttu fb white ls">FOLLOW US </h4>

                        <ul className="social">

                            <li>
                                <a href=""> <img src={images.instagram} alt="" />
                                </a>
                            </li>

                            <li>
                                <a href=""> <span>blog</span>
                                </a>
                            </li>

                        </ul>

                    </div>

                    <div className="col-lg-2">
                        <h4 className="  font-14 ttu fb white ls">QUICK LINKS </h4>
                        <ul className="mb-5">

                            <NavLink to={baseUrl + '/login'} exact>
                                <li>
                                    <a href=""> Sign In </a>
                                </li>
                            </NavLink> <NavLink to={baseUrl + '/register'} exact>
                                <li>
                                    <a href=""> Register </a>
                                </li>
                            </NavLink>
                            <NavLink to={baseUrl + '/about-us'} exact>
                                <li>
                                    <a   >About Us</a>
                                </li>
                            </NavLink>
                            <li>
                                <a href=""> Services </a>
                            </li>
                            <NavLink to={baseUrl + '/faq'} exact>
                                <li>
                                    <a href=""> FAQs </a>
                                </li>

                            </NavLink>
                            <NavLink to={baseUrl + '/contact-us'} exact>
                                <li>
                                    <a href=""> Contact </a>
                                </li>
                            </NavLink>

                        </ul>

                    </div>

                    <div className="col-lg-7">

                        <div className=" media">
                            <div className="media-body">
                                <p className="font-13 white ttu"> IMPORTANT INFORMATION
                                </p>
                                <p className="font-13 black ">*THE MORTGAGE PROPERTY (WHICH MAY BE YOUR HOME) MAY BE
                                    REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE*</p>

                                <p className="font-13 black ">Think carefully before securing debt against your home. Your home
                                    may be repossessed if you do not keep up with the repayments. You must be aware that
                                    Capatool Financial does not provide regulated mortgage contracts, where it is either your
                                    or an immediate family member’s intention to occupy at least 40% of this property as a
                                    dwelling, either now or at any time in the future.</p>
                            </div>
                            <img alt="" src={images.favicon2} className="media-object" />
                        </div>


                    </div>

                </div>
            </div>
        </footer>

    )
}
export default Content