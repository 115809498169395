import React from "react";
import images from "../../utils/imageHandler/imageHelper";

function LanderServices(props) {
    return (
        <div  className="slider2">
            <div  className="item">
                <div  className=" media black-box">
                    <div  className="media-middle">
                        <img src={images.finance11} alt="" />
                    </div>
                    <div  className="media-body">
                        <p  className="font-11 white ttu">
                            ELIGIBILITY<br />
                            CALCULATOR
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LanderServices;