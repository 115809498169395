import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import useStyles from './styles';
import images from '../../utils/imageHandler/imageHelper';
import { useEffect } from 'react';
import Helper from '../../utils/apiHelper/apiHelper';
import FormLayout from '../financeApplication/formLayout';
import RegisterUser from '../financeApplication/register';
import InfoModal from '../global/modal/infoModal';
import SkipInfoModal from '../global/modal/skipInfoModal';
import AlmostDone from '../financeApplication/almostDone';
import { baseUrl } from '../../Config';
import { withRouter } from 'react-router';

function CheckoutPage(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formField, setFormData] = useState([]);
  const [details, setDetails] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [steps, setSteps] = useState([0, 1, 2, 3, 4, 5]);
  const [token, setToken] = useState('');
  const [form_id, setFormId] = useState('');
  const [repeat, setRepeat] = useState(1);
  const [showAlmost, setShowAlmost] = useState(false);
  const [warning, setShowWarning] = useState(false);
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    let token = window.localStorage.getItem('token');
    setToken(token);
    setFormId(props?.form_id);
    if (props.form_id == undefined) {
      props.history.push({ pathname: baseUrl + "/" });
      return;
    }
    if (token) {
      setActiveStep(1);
      getFormFields(1);
    }
    //  else {

    //   getFormFields(activeStep);
    // }
  }, [])

  const saveItems = (data, total_steps) => {
    setFormData(data);
    setInitialValues({});
    let values = {}
    data && data.map((item, index) => {
      values[item.id] = item?.value;
      if (item?.slug == 'unfortunately-we-cannot-help-on-this-occasion') {
        if (item?.value.toUpperCase() == "YES") {
          hideFields(false, "unfortunately-we-cannot-help-on-this-occasion", data);
          setShowWarning(true)
        } else {
          hideFields(true, "unfortunately-we-cannot-help-on-this-occasion", data)
          setShowWarning(false)
        }
      }
    })
    setInitialValues(values);
    let stepArray = [];
    for (let i = 1; i <= total_steps + 1; i++) {
      stepArray.push(i);
    }
    setSteps(stepArray)

  }

  const saveMultiStepItems = (data, total_steps) => {
    let dataArray = [];
    setInitialValues({});
    let values = {};
    data.map((item, index) => {
      item.map(field => {
        let id = ((index == 0) ? (field?.id) : (index + 1 + '-' + field?.id));
        field.id = id;
        values[id] = field?.value;
        if (field?.title == "APPLICANT 1") {
          let option_value = field.option_values.replace("APPLICANT 1", `APPLICANT ${index + 1}`)
          field.title = `APPLICANT ${index + 1}`
          field.option_values = option_value;
        }
      })
      dataArray = dataArray.concat(item);
    })
    setFormData(dataArray);
    setInitialValues(values);
    let stepArray = [];
    for (let i = 1; i <= total_steps + 1; i++) {
      stepArray.push(i);
    }
    setSteps(stepArray);

  }

  const getFormFields = (step) => {
    let formData = Helper.renderFormData({ step: step, form_type_id: props.form_id })
    Helper.makeRequest('get-loan-request-form', formData).then(async (res) => {
      if (res.status === 'true') {
        setDetails(res?.data[0]);
        setRepeat(res?.repeat_count);
        if (res?.repeat_count > 1) {
          await saveMultiStepItems(res?.data[0]?.form_step_data, res?.total_steps)
        } else {
          await saveItems(res?.data[0]?.form_step_data, res?.total_steps);
          await hideFields(true, "unfortunately-we-cannot-help-on-this-occasion", res?.data[0]?.form_step_data);
          await hideRentalFields(true, res?.data[0]?.form_step_data)

        }

      }
    });
  }
  const hideFields = (value, slug, data) => {
    let newArray = []
    if (data.length > 0) {
      data.map(item => {
        if (item.slug == slug) {
          item.hide = value
        }
        newArray.push(item)
      })
      setFormData(newArray)
    }
  }
  const hideRentalFields = (value, data) => {
    hideFields(value, "rental-income", data);
    hideFields(value, "residential-rental-income", data);
    hideFields(value, "commercial-rental-income", data);
    hideFields(value, "tenancy-term", data);
    hideFields(value, "residential-tenancy-term", data);
    hideFields(value, "commercial-tenancy-term", data);
  }
  const validate = values => {
    const errors = {};

    Object.keys(values).map(item => {
      let field = formField.filter(data => data.id == item);
      if (field[0].slug == "will-you-or-a-family-member-reside-in-the-property") {
        if (values[item].toUpperCase() == "YES") {
          hideFields(false, "unfortunately-we-cannot-help-on-this-occasion", formField);
          setShowWarning(true)
        } else {
          hideFields(true, "unfortunately-we-cannot-help-on-this-occasion", formField)
          setShowWarning(false)
        }
      }
      if (field[0].slug == "will-property-be-tenanted") {
        if (values[item].toUpperCase() == "YES") {
          hideRentalFields(false, formField)
        } else {
          hideRentalFields(true, formField)
        }
      }

      if (!values[item] && field && field[0]?.is_required == 1) {
        errors[item] = 'is required.';
      } else if (field[0]?.field_type == 'amount') {
        if (values[item] < parseInt(field[0]?.min_length) || values[item] > parseInt(field[0]?.max_length)) {
          errors[item] = `must be between ${field[0].min_length} to ${field[0].max_length}`;
        }
      }
    })
    return errors;
  }

  function _renderStepContent(step, values, handleChange) {
    return <FormLayout formFields={formField} details={details} />;
  }

  function _renderValues(values) {
    if (repeat > 1) {
      let array = [];
      for (let i = 1; i <= repeat; i++) {
        Object.keys(values).map(item => {
          let str = `${i}-`
          if (!item.includes('-')) {
            array[0] = { ...array[0], [item]: values[item] }
          } else if (item.includes(str)) {
            let key = item.split(str)[1];
            array[i - 1] = { ...array[i - 1], [key]: values[item] }
          }
        })
      }
      return array;
    } else {
      return values
    }
  }

  async function _handleSubmit(values, actions) {

    let data = await _renderValues(values);
    let formData = await Helper.renderFormData({ step: activeStep, is_last_step: isLastStep ? 1 : 0, form_type_id: form_id, repeat: repeat, form_data: JSON.stringify(data) })
    await Helper.makeRequest('add-loan-request', formData).then((res) => {
      if (res.status === 'true') {
        if (!isLastStep) {
          actions.setTouched({});
          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);
          getFormFields(activeStep + 1);
          setRepeat(1);
          setShowAlmost(false);
        } else {
          setShowAlmost(true);
        }
      } else {
        Helper.Alert(res?.message, 'error');
      }
    });
  }

  const onNextClick = () => {
    if (!isLastStep) {
      let elements = document.getElementsByClassName("overlay-info");
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = 'block';
      }
      setShowAlmost(false);
    } else {
      setShowAlmost(true);
    }
    setRepeat(1);

  }

  const onPrevClick = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      getFormFields(activeStep - 1);
    }
    setShowAlmost(false);
    setRepeat(1);

  }

  const onPrevClickAlmost = () => {
    getFormFields(activeStep);
    setShowAlmost(false);
    setRepeat(1);
  }

  const registerSubmitHandler = () => {
    let elements = document.getElementsByClassName("overlay");
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].style.display = 'block';
    }
    setActiveStep(activeStep + 1);
    getFormFields(activeStep + 1);
  }

  const addNewHandler = () => {
    setRepeat(prevValue => prevValue + 1);
    setInitialValues({});
    let values = initialValues;
    let array = ((details?.form_step_data[0].length > 0) ? (details?.form_step_data[0]) : (details?.form_step_data))
    array?.map((item, index) => {
      let id = repeat + 1 + '-' + item.id;
      values[id.toString()] = '';
      let options = [];

      if (item.options && item.options.length) {
        item.options.map((opt, index) => {
          let data = {
            id: repeat + 1 + '-' + opt?.id,
            option_value: opt?.option_value,
            field_id: repeat
          }
          options.push(data)
        })
      }
      let option_value = ''
      if (item?.title == "APPLICANT 1") {
        option_value = item.option_values.replace("APPLICANT 1", `APPLICANT ${repeat + 1}`)
      } else {
        option_value = item?.option_values;
      }

      let newFormField = {
        id: id.toString(),
        field_type: item.field_type,
        form_type: item?.form_type,
        form_type_id: item?.form_type_id,
        is_repeat: item?.is_repeat,
        is_required: item?.is_required,
        option_values: option_value,
        options: options,
        tooltip: item?.tooltip,
        title: item?.title,
        value: '',
        placeholder: item?.placeholder
      }
      setFormData(oldArray => [...oldArray, newFormField]);

    })
    setInitialValues(values);
  }

  function toggleModal(className) {
    let elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].style.display = 'none';
    }
  }

  function skipHandler() {
    setActiveStep(activeStep + 1);
    getFormFields(activeStep + 1);
    let elements = document.getElementsByClassName("overlay-info");
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].style.display = 'none';
    }
  }

  function onClickStep(index) {
    let token = window.localStorage.getItem('token');
    let authenticated = window.localStorage.getItem('isAuthenticated');
    if (authenticated && index == 0) {
      return;
    }
    if (token) {
      setActiveStep(index);
      getFormFields(index);
    }


  }

  return (
    <>
      <div className="steps_wizard">
        <div className="all-steps" id="all-steps">
          {steps.length > 0 && steps.map((label, index) => (
            <span onClick={() => onClickStep(index)} className={"cursor-pointer step " + (index === activeStep ? 'active' : '')} key={index}>{index + 1}</span>
          ))}
        </div>
        {!isLastStep ? (activeStep == 0) ?
          <RegisterUser registerSubmitHandler={registerSubmitHandler} /> :
          <React.Fragment>
            {Object.keys(initialValues).length > 0 && <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={validate}
              onSubmit={_handleSubmit}
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit, }) => (
                <Form id={activeStep}>
                  {/* {_renderStepContent(activeStep, values, handleChange)} */}
                  <FormLayout
                    formFields={formField}
                    details={details}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    is_repeat={details?.is_repeat}
                    repeat={repeat}
                    addNewHandler={addNewHandler}
                  />
                  <div className={classes.buttons}>

                    <div className="steps_wizard_continue">
                      <button
                        className="steps_wizard_continue btn1"
                        disabled={isSubmitting || warning}
                        type="submit"
                        className="btn1"
                        data-toggle="modal"
                        data-target="#information">
                        CONTINUE
                      </button>

                      <div id="nextprevious">
                        {(activeStep != 1) ? <button type="button" id="prevBtn" disabled={activeStep == 1} onClick={onPrevClick}>
                          <img src={images.right} alt="" /> BACK</button> : <span></span>}

                        <button type="button" id="nextBtn" disabled={isLastStep} onClick={onNextClick}>SKIP & COME BACK LATER
                          <img src={images.right} alt="" /></button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>}
          </React.Fragment>
          :
          <AlmostDone
            onPrevClick={onPrevClickAlmost}
            activeStep={activeStep}
            isLastStep={isLastStep}
            form_id={form_id}
            repeat={repeat}
            field={formField} />}
        <InfoModal toggleModal={() => toggleModal('overlay')} />
        <SkipInfoModal toggleModal={() => toggleModal('overlay-info')} skipHandler={skipHandler} />
      </div>
    </>
  );
}

export default withRouter(CheckoutPage);