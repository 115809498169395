import React from "react";

function IntelligentLending(props) {
    return (
        <section className="intelligent_legend">
            <div className="container">
                <div className="row">
                    <div className="col-10 mb-4">
                        <span className="black font-11 ttu ls">INTELLIGENT LENDING</span>
                        <h4 className="heading_with_border white fr font-28">Sit back and let Capatool Financial do the work.
						</h4>
                        <p className="white fr font-16">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Duis autem vel
                        eum
							iriure dolor.</p>

                    </div>

                    <div className="col-lg-10 ">
                        <div className="row">

                            <div className="col-lg-6">


                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> No upfront fees and no broker fees
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6">


                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> No brokers to deal with (unless complex)
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6">


                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> Indicative terms within minutes
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6">


                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> Easy to follow process from application
                                        to final drawdown
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> Choose your lender and your value
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className=" media">
                                    <div className=" media-middle">
                                        <span className="circle"></span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="font-16 black fr "> Text/email updates as well as your own Portal
										</h3>

                                        <p className="white font-14">Lorensioauoais u asua sasapoiu ihich iudsi diudsiu
                                        dljsius
                                        piuspu s saiuspiu is
											saiuaspsi siu s hsihsapiasu psau spiuhs pusi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IntelligentLending;