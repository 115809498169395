import React from "react";

import images from "../../utils/imageHandler/imageHelper";

const solutions = [
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    },
    {
        img: '',
        title: ''
    }
]

function LendingOptions(props) {
    return (
        <section className="lending_options">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <span className="primary font-11 fb ttu ls">LENDERS</span>
                        <h4 className="heading_with_border black fr font-28">You'll have the best lending options
						direct to your inbox. </h4>
                        <p className="black font-16">Get the best lending option rates from lenders including these, direct to
						your inbox:</p>

                        <div className="row my-4">
                            {solutions.length && solutions.map((item, index) => {
                                return (<div className="col-lg-3 col-md-6" key={index}>
                                    <div className="box">
                                        <img src={images.lander1} alt="" />
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className="col-lg-12 tc">
                            <a href="#" className="btn1 font-12 ls">SIGN UP FOR FREE FOR THE BEST RATES FROM THESE LENDERS</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LendingOptions;