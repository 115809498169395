import React from 'react';
import images from "../../../utils/imageHandler/imageHelper";
const Warning = (props) => {
    const { tooltip, title } = props;
    return (
        <div class="form-group ">
            <div class="warn">
                <div>
                    <p class=" text-red  ">
                        <span className="warn-span" dangerouslySetInnerHTML={{__html:title}}>
                            {/* <b class="text-red fb ttu"> unfortunately</b>
                        <span class="ttu">- we cannot help on this occasion.
                        </span> */}
                        </span>
                        <span class="datahover" data-hover={tooltip}>
                            <img src={images.info_red} alt="" /></span>
                    </p>
                </div>
                <div> <img src={images.c3} alt="" /></div>
            </div>
        </div >
    );
}
export default Warning;
