import React, { useEffect, useState } from "react";
import SolutionTypes from './solutionsTypes';
import { withRouter } from "react-router-dom";
import images from "../../utils/imageHandler/imageHelper";
import Helper from '../../utils/apiHelper/apiHelper';
import { baseUrl } from "../../Config";
import axios, { Axios } from 'axios';
const solutions = [
    {
        icon: images.finance1,
        hover: images.hover1,
        title: 'AUCTION<br/> FINANCE'
    },
    {
        icon: images.finance2,
        hover: images.hover2,
        title: 'Residential<br/> Investment<br/> Bridge <br/>(Bridge Loan)'
    },
    {
        icon: images.finance3,
        hover: images.hover3,
        title: 'BUY TO LET <br/>MORTGAGES'
    },
    {
        icon: images.finance4,
        hover: images.hover4,
        title: 'COMMERCIAL <br/>BRIDGE'
    },
    {
        icon: images.finance5,
        hover: images.hover5,
        title: 'SEMI <br/>COMMERCIAL <br/>BRIDGE'
    },
    {
        icon: images.finance6,
        hover: images.hover6,
        title: 'COMMERCIAL <br/>MORTGAGES'
    },
    {
        icon: images.finance7,
        hover: images.hover7,
        title: 'SEMI <br/>COMMERCIAL <br/>MORTGAGES'
    },
    {
        icon: images.finance8,
        hover: images.hover8,
        title: 'DEVELOPMENT <br/>FINANCE'
    },
    {
        icon: images.finance9,
        hover: images.hover9,
        title: 'DEVELOPMENT <br/>EXIT <br/>FINANCE'
    },
    {
        icon: images.finance10,
        hover: images.hover10,
        title: 'LAND <br/>LOANS'
    },
    {
        icon: images.finance11,
        hover: images.hover11,
        title: 'SECOND <br/>CHARGE <br/>BRIDGE'
    },
    {
        icon: images.finance12,
        hover: images.hover12,
        title: 'SECOND <br/>CHARGE <br/>MORTGAGES'
    }
]

function FinancialSolutions(props) {
    const [services, setServices] = useState([]);

    useEffect(() => {
        Helper.makeRequest('get-services', {}, 'GET').then((res) => {
            if (res.status === 'true') {
                setServices(res?.data)
            } else {
            }
        })
        // axios.get('https://capatool-be.dreamztesting.com/api/get-services', {}).then((res) => {
        // console.log('res', res)
        // })
    }, [])

    const solutionsClickHandler = (item, id) => {
        const { history } = props;
        if (history)
            history.push({ pathname: baseUrl + "/finance", state: { title: item, id: id } });

    }

    return (
        <section className="financial_solutions">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mb-4">
                        <h4 className="heading_with_border white fr font-28">Financial solutions at your fingertips.</h4>
                        <p className="white fr font-16"><b className="fb">Welcome to a better way to access high end lending.</b> We
                            take the fuss out of
                            finance and
                            make lending super simple. Simpy choose your lending service below and we will deliver the very
                            best lending solutions direct to your inbox.
                            Our eligibility calculators help you see within just a few clicks, what amounts of finance you’d
                            be able to obtain.
                        </p>
                    </div>
                    <div className="col-xl-10 ">
                        <div className="row">
                            {services.length > 0 && services.map((item, index) => {
                                return <SolutionTypes key={index} item={item} solutionsClickHandler={solutionsClickHandler} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withRouter(FinancialSolutions);