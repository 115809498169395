
import React from 'react';
import images from '../../utils/imageHandler/imageHelper';
import AddMoreUpload from './addMoreUpload';
import ExperianCreditReport from './experianCreditReport';
import LinkAgency from './linkAgency';
import MultipleDocumentUpload from './multipleDocumentUpload';
import PersonalDetails from './personalDetails';
import ProofIncome from './proofIncome';
import SolicitorDetails from './solicitorDetails';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import Helper from '../../utils/apiHelper/apiHelper';
import IDUpload from './idUpload';
import { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { baseUrl } from '../../Config';
import DocumentApproved from './docApproved';
import LoanList from '../userLoans/loansList';

function Documents(props) {
    const { user, history } = props;

    const [status, setStatus] = useState(0);
    const [documentType, setDocumentType] = useState('');
    const [proofIncomeType, setProofIncomeType] = useState('EMPLOYED - Paid Monthly');
    const [documentData, setDocumentData] = useState({});
    const [loanDocuments, setLoanDocumentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState('');
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        getDocuments();
        getLoanDocuments();
    }, [])

    const selectTypeHandler = (e) => {
        setDocumentType(e.target.value)
    }
    const getDocuments = () => {
        Helper.makeRequest('get-upload-document', {}).then((res) => {
            if (res.status === 'true') {
                if (res.data.document_type) {
                    setStatus(1);
                }
                setDocumentType(res?.data?.document_type);
                setDocumentData(res?.data)
            }
        })
    }
    const getLoanDocuments = () => {
        Helper.makeRequest('get-document-form', {}).then((res) => {
            if (res.status === 'true') {
                setLoanDocumentData(res?.data)
            }
        })
    }
    const imageSelectHandler = (e, type) => {
        if (!documentType) {
            Helper.Alert('Please select the document type.', 'error');
            return;
        }
        if (e.target.files && e.target.files.length) {
            if (e.target.files[0]?.type != ('image/jpeg' || 'image/jpeg')) {
                Helper.Alert('Please select only jpeg or jpg format.', 'error');
                clearInputBox(type);
                return;
            }
            let formData = Helper.renderFormData({
                [type]: e.target.files[0],
                document_type: documentType
            });

            Helper.makeRequest('document-upload', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert(res.message);
                    getDocuments();
                }
                else {
                    Helper.Alert(res.message, 'error');
                    clearInputBox(type);
                }
            })
        }
    }
    const clearInputBox = (type) => {
        if (type == 'iduploadfront')
            document.getElementById("iduploadfront").value = "";
        else if (type == 'iduploadback')
            document.getElementById("iduploadfront").value = "";
        else document.getElementById("iduploadphoto").value = "";

    }
    function validFile(type) {
        switch (type.toLowerCase()) {
            case 'image/jpg':
            case 'image/jpeg':
            case 'application/pdf':
                return true;
        }
        return false;
    }
    const uploadDocuments = (e, form_id, loan_id, id, type, field_type, slug) => {
        let target_id = e.target.id;
        let file_type = e.target.files[0]?.type;

        if (e.target.files && e.target.files.length) {
            if (!validFile(file_type)) {
                Helper.Alert('Please select only jpeg, pdf or jpg format.', 'error');
                document.getElementById(target_id).value = "";
                return;
            }
            let data = {
                file: e.target.files[0],
                loan_request_id: loan_id,
                form_type_id: form_id,
                document_id: id,
                document_type: slug
            }
            if (field_type == 'proof of income') {
                data.other = proofIncomeType;
            }
            let formData = Helper.renderFormData(data);

            Helper.makeRequest('add-document', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert(res.message);
                    document.getElementById(target_id).value = "";
                    getLoanDocuments();
                }
                else {
                    Helper.Alert(res.message, 'error');
                    document.getElementById(target_id).value = "";
                }
            })
        }
    }
    const submitHandler = (form_id, loan_id, id, slug, type) => {
        let loan = loanDocuments.filter(item => item?.id == addDocuments);
        let form = loan[0]?.document_form.filter(item => item?.slug == slug);
        let link = form[0].document_other_value;
        if (!link && type == 'link') {
            Helper.Alert('Please enter online agency.', 'error');
            return;
        }
        let data = {
            file: {},
            loan_request_id: loan_id,
            form_type_id: form_id,
            document_id: id,
            document_type: slug,
            other: type == 'link' ? link : (link === "true" ? false : true)
        }
        let formData = Helper.renderFormData(data);
        Helper.makeRequest('add-document', formData).then((res) => {
            if (res.status === 'true') {
                Helper.Alert('Form updated successfully');
                getLoanDocuments();
            }
            else {
                Helper.Alert(res.message, 'error');
            }
        })
    }
    const handleChange = (e, type, slug) => {
        let loan = [...loanDocuments];
        loan.map(item => {
            if (item?.id == addDocuments) {
                item?.document_form.length && item?.document_form.map(data => {
                    if (data?.slug == slug) {
                        data.document_other_value = e.target.value;
                    }
                })
            }
        })
        setLoanDocumentData(loan);
    }
    const deleteHandler = (id) => {
        if (window.confirm('Are you sure you want to delete the document?')) {
            let data = {
                file_id: id
            }
            let formData = Helper.renderFormData(data);
            Helper.makeRequest('delete-document', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert(res.message);
                    getLoanDocuments();
                }
                else {
                    Helper.Alert(res.message, 'error');
                }
            })
        }
    }
    const showDocumentHandler = (index) => {
        setAddDocuments(index)
    }
    const viewMyFormHandler = (id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/form", state: { title: title, id: id } });
        }
    }
    
    const viewMyProposalsHandler = (_id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/proposals", state: { title: title, id: _id } });
        }
    }
    const updateSolicitor = (id) => {
        if (editable) {
            let loan = loanDocuments.filter(item => item?.id == addDocuments);
            let data = {
                form_data: JSON.stringify(loan[0]?.solicitor_details),
                loan_request_id: id
            }
            let formData = Helper.renderFormData(data);
            Helper.makeRequest('update-solicitor-details', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert('Form updated successfully');
                    getLoanDocuments();
                }
                else {
                    Helper.Alert(res.message, 'error');
                }
            })
        }
        setEditable(!editable)

    }
    const onChangeHandler = (e, form, id) => {
        let loan = [...loanDocuments];
        loan.map(item => {
            if (item?.id == addDocuments) {
                item?.solicitor_details.length && item?.solicitor_details.map(data => {
                    if (data?.slug == form?.slug) {
                        data.field_value = e.target.value;
                    }
                })
            }
        })
        setLoanDocumentData(loan);

    }
    return (
        <>
            <h4 className=" black fr  font-28 ttc">{Helper.getGreetings()}  {user?.name}.</h4>
            <p className="heading_with_border black font-28 mb-5">Your Capatool Financial Documents Area:</p>
            <b className="font-16"> Your safe place to store copies of the required documents for your loan. </b>
            <p className="black font-16"> <span className="primary">100% secure.</span> We only share these documents with the
                lender/s that you have
                selected to proceed with.</p>
            <div className="border-0 card mb-5">

                {status == 0 && <div className="px-4 py-3   border-bottom">
                    <div className="p-4 df jcb border-bottom">
                        <b className=" font-18">ID Verification Required for ALL Loans: </b>
                    </div>
                    <button
                        type="button"
                        className="add-section"
                        onClick={() => setStatus(1)}> <img src={images.add} alt="" />
                        <span>ADD YOUR PHOTO ID FOR VERIFICATION
                        </span>
                    </button>
                </div>}
                {status == 1 &&
                    <>
                        {(documentData?.document_status == 'Approved') ?
                            <DocumentApproved
                                type={documentType}
                                documentData={documentData}
                                selectTypeHandler={selectTypeHandler} />
                            :
                            < IDUpload
                                type={documentType}
                                documentData={documentData}
                                selectTypeHandler={selectTypeHandler}
                                imageSelectHandler={imageSelectHandler}
                                onClickImageUpload={clearInputBox} />}
                    </>
                }
            </div>
            <div className="border-0 card mb-5">
                <div className="p-4 df jcb border-bottom">
                    <b className=" font-18">Your Current Application Forms & Documentation: </b>
                </div>
                {loanDocuments.length > 0 && loanDocuments.map((item, index) => (
                    <Fragment key={index}>
                        <LoanList
                            close={true}
                            amount={item?.amount}
                            loan_request_date={item?.loan_request_date}
                            loan_title={item?.loan_title}
                            service={item?.service}
                            viewMyFormHandler={viewMyFormHandler}
                            addDocuments={addDocuments != item?.id}
                            showDocumentHandler={showDocumentHandler}
                            viewMyProposalsHandler={viewMyProposalsHandler}
                            form_type_id={item?.form_type_id}
                            id={item?.id}
                        />
                        {addDocuments == item?.id ?
                            <><div className="p-4 df jcs border-bottom">
                                <b className=" font-18">Documentation Required: </b>
                                <span className="primary font-18 ls4"> YOUR DOCUMENTS:</span>
                            </div>
                                {item.document_form.length > 0 && item.document_form.map((doc, i) => (
                                    <Fragment>
                                        {doc?.field_type == "image" && <AddMoreUpload
                                            title={doc?.title}
                                            description={doc?.description}
                                            imageSelectHandler={uploadDocuments}
                                            loan_request_id={item?.id}
                                            form_type_id={item?.form_type_id}
                                            deleteHandler={deleteHandler}
                                            field_id={doc?.id}
                                            other={doc?.document_other_value}
                                            slug={doc?.slug}
                                            value={doc?.document_value}
                                            multiple={doc?.is_repeat_count > 1 ? true : false} />}

                                        {(doc?.field_type == "last 3 months personal bank statements") &&
                                            (doc?.field_type == "last 3 months personal bank statements")
                                            && <MultipleDocumentUpload
                                                title={doc?.title}
                                                description={doc?.description}
                                                imageSelectHandler={uploadDocuments}
                                                loan_request_id={item?.id}
                                                form_type_id={item?.form_type_id}
                                                field_id={doc?.id}
                                                slug={doc?.slug}
                                                deleteHandler={deleteHandler}
                                                other={doc?.document_other_value}
                                                value={doc?.document_value}
                                                multiple={doc?.is_repeat_count > 1 ? true : false} />}

                                        {(doc?.field_type == "proof of income")
                                            && <ProofIncome
                                                title={doc?.title}
                                                description={doc?.description}
                                                imageSelectHandler={uploadDocuments}
                                                loan_request_id={item?.id}
                                                form_type_id={item?.form_type_id}
                                                field_id={doc?.id}
                                                handleChange={handleChange}
                                                field_type={doc?.field_type}
                                                slug={doc?.slug}
                                                deleteHandler={deleteHandler}
                                                other={doc?.document_other_value}
                                                value={doc?.document_value}
                                                multiple={doc?.is_repeat_count > 1 ? true : false} />}

                                        {(doc?.field_type == "experian consent to search")
                                            && <ExperianCreditReport
                                                title={doc?.title}
                                                description={doc?.description}
                                                loan_request_id={item?.id}
                                                form_type_id={item?.form_type_id}
                                                slug={doc?.slug}
                                                submitHandler={submitHandler}
                                                field_id={doc?.id}
                                                other={doc?.document_other_value}
                                                value={doc?.document_value} />}

                                        {(doc?.slug == "link-to-online-agency-to-show-the-property-on-the-market")
                                            && <LinkAgency
                                                title={doc?.title}
                                                slug={doc?.slug}
                                                description={doc?.description}
                                                loan_request_id={item?.id}
                                                form_type_id={item?.form_type_id}
                                                field_id={doc?.id}
                                                handleChange={handleChange}
                                                submitHandler={submitHandler}
                                                other={doc?.document_other_value}
                                                slug={doc?.slug}
                                                value={doc?.document_value} />}
                                    </Fragment>
                                ))}
                                <SolicitorDetails
                                    updateSolicitor={updateSolicitor}
                                    data={item?.solicitor_details}
                                    editable={editable}
                                    id={item?.id}
                                    onChangeHandler={onChangeHandler} />
                                <PersonalDetails />
                                <div className=" submit_btn_form mb-20">
                                    <button type="submit" className="d-block w-100 btn1 tc font-18  ls4"> SUBMIT THIS INFORMATION</button>
                                </div>
                            </>
                            :
                            ''}
                    </Fragment>
                ))}

            </div>
            <a href="" className="w-100 d-block btn1 tc font-18 mt-5 mb-5 ls4"> EMAIL US AT: SUPPORT@CAPATOOL.COM</a>
            <WhiteFooter />
        </>
    );
}

export default withRouter(Documents);
