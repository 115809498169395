import React from 'react';
import images from '../../utils/imageHandler/imageHelper';

const ExperianCreditReport = (props) => {
    const { title, description, other, form_type_id, loan_request_id, field_id, field_type, slug, submitHandler, handleChange, value } = props;
    return (
        <div className="px-4 py-3   border-bottom">
            <div className="row">
                <div className=" form-group col-lg-9 col-md-8">
                    <span className="font-18 ">Experian Credit Report</span>
                    <div className="mt-3 custom_checkbox at">
                        <input
                            type="checkbox"
                            id="check-1"
                            checked={other === "true" ? true : false}
                            value={other}
                            onClick={() => submitHandler(form_type_id, loan_request_id, field_id, slug, 'report')} />
                        <label htmlFor="check-1" className="font-18"> <span className="check_content">I confirm that I am happy
                            for you to carry out an <b>Experian Credit Report</b> <br />
                            check on me for the purpose of this loan / finance package.</span></label>
                        <p className="pl-4 primary font-18"> (Please tick to confirm your consent)</p>
                    </div>

                </div>

                <div className=" form-group col-lg-3 col-md-4 text-md-right">


                    <img src={images.export} alt="" />
                </div>
            </div>
        </div>

    )
}
export default ExperianCreditReport;