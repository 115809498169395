import React from "react";
import images from "../../utils/imageHandler/imageHelper";

function EligibilityCalculatorBanner(props) {
    return (
        <>
            <section className="eligibilty-cal-banner d-flex align-items-center">
                <div className="container py-md-4 py-5">
                    <div className="fix-container mx-auto">
                        <h2 className="font-28 white">Calculate Your Eligibility for<br />Auction Finance</h2>
                        <p className="font-16 white"><strong>Calculate out how much you could be eligible to borrow for your
                        Auction Finance needs.</strong></p>
                        <p className="font-16 white">Use our eligibility calculator to see how likely it is that you'll qualify
                    for Auction Finance before you apply. </p>
                        <p className="font-16 white">You can check your eligibility without impacting your credit score.</p>
                    </div>
                </div>
            </section>
            <section className="calculate-sec">
                <div className="container d-flex align-items-center h-100 position-relative">
                    <span className="calualtor-icon"><img src={images.mfCalculator} alt="" /></span>
                    <h3 className="fr">Calculate How Much You Could Borrow:</h3>
                </div>
            </section>
        </>
    );
}

export default EligibilityCalculatorBanner;