import React from "react";
import Slider from "react-slick";
import images from "../../utils/imageHandler/imageHelper";
import LanderServices from "./landerServices";

const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
};

const next = () => {
    slider.slickNext();
}

const previous = () => {
    slider.slickPrev();
}

let slider = ''
function GetStartedFinance(props) {
    return (
        < section className="get_started_section" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <span className="primary font-11 fb ttu ls">GET STARTED</span>
                        <h4 className="heading_with_border black fr font-28">Auction Finance Comparison </h4>
                        <p className="black font-14">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                            tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
                            illum dolore.
                </p>
                        <p className="black font-14">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                            tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
                            illum dolore.
                </p>

                        <button className="btn1 font-12 d-block mt-5">FIND HOW MUCH YOU COULD BORROW FOR YOUR PROJECT
                </button>

                        <hr className="hr" />
                    </div>

                    <div className="col-lg-12 ">
                        <span className="primary font-11 fb ttu ls">LENDING SERVICES</span>
                        <h4 className="heading_with_border black fr font-28">Our other lending services include: </h4>
                        <p className="black font-14">
                            Get the very best lending option rates from top industry lenders, direct to your inbox:
                </p>
                    </div>
                </div>
            </div>

            <div className="owl-section">
                <Slider ref={ref => slider = ref} {...settings}>
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                    <LanderServices />
                </Slider>
                <div className="previous_next">
                <img className="next" src={images.right} onClick={next} />
                <img className="previous" src={images.left} onClick={previous} />
            </div>
            </div>
           
        </section >
    );
}

export default GetStartedFinance;