import React, { useEffect, useRef } from 'react';
import images from '../../utils/imageHandler/imageHelper';
import { withRouter } from 'react-router-dom';
import { InputField as Input } from '../FormFields';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { login } from '../../redux/actions/authActions';
import { hideHeader } from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import LeftLayout from '../preLogin/leftLayout';
import { baseUrl } from '../../Config';

const ForgetPassword = (props) => {
    const _isMounted = useRef(true);
    const { history } = props;
    useEffect(() => {

        if (_isMounted) props.hideHeader(false);

        return () => {
            props.hideHeader(true);
            _isMounted.current = false;
        }
    }, [])
    const onRegisterHandle = () => {
        if (history) history.push(baseUrl + "/login");
    }
    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    return (
        <>
            <div className="load">
                <img src={images.logo} alt="" />
            </div>
            <div className="auction-finance-Application-Form">

                <section className="application_from">

                    <LeftLayout title="Login" />

                    <div className="right">

                        <div className="steps_wizard">
                            <Formik
                                initialValues={{ email: '' }}
                                validate={validate}
                                onSubmit={(values, { setSubmitting }) => {
                                    let formData = ApiHelper.renderFormData(values)
                                    ApiHelper.makeRequest('forgot-password', formData).then((res) => {
                                        if (res.status === 'true' || res.status) {
                                            ApiHelper.Alert(res.message, 'success');
                                            // if (history) history.push("/login");
                                        } else {
                                            ApiHelper.Alert(res.message, 'error');
                                        }
                                        setSubmitting(false);
                                    })
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => {
                                    return (
                                        <form id="regForm" onSubmit={handleSubmit}>
                                            <div className="tab">

                                                <div className="form_heading  jcc">
                                                    YOUR CONTACT INFORMATION::
                                                                </div>

                                                <p className="black font-18">Already Have an Account?</p>

                                                <a onClick={onRegisterHandle} className="db btn1 mb-5"> SIGN IN</a>

                                                <a href="#" className="primary font-13 fr tdu ">You can view our privacy policy here.</a>



                                                <div className="fields">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="font-16"> Email </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email} />
                                                        <span className="hint">
                                                            {errors.email && touched.email && errors.email}
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn1" disabled={isSubmitting}>SUBMIT </button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>


                        </div>

                    </div>
                </section>


            </div>
        </>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));
