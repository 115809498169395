import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Helper from '../../utils/apiHelper/apiHelper';
import CheckoutPage from '../CheckoutPage/CheckoutPage';

function UserPortalLoanForm(props) {
    const [id, setID] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        setID(props?.location?.state?.id); 
        setTitle(props?.location?.state?.title)
    }, [])
    return (
        <div className="portal-right-wrapper portal_setting">
            <h4 className=" black fr  font-28 ttc">{Helper.getGreetings()}  {props?.user?.name}.</h4>
            <p className="heading_with_border black font-28 mb-5">Apply for Loan here...</p>

            <b className="font-16"> Here you can Apply for Loan here.</b>
            <p className="black font-16"> From your logins and contact details to your profile image if you wish to include one!
            </p>
            <div className="border-0 card mb-5 loan-form">
                <CheckoutPage form_id={props?.location?.state?.id} title={title} />
            </div>
        </div> 
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    // hideHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPortalLoanForm));

