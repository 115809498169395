import React from 'react';

const InfoModal = (props) => {
    const { toggleModal } = props;
    return (
        <>
            <div className="overlay">
                <div className="info-modal-content">
                    <button type="button" onClick={toggleModal} className="close" data-dismiss="modal" id="nextBtn">&times;</button>
                    <div className="info-modal-body">
                        <p><b>Thank you!</b> <br />
                            To verify your email address, please check<br />
                            your inbox and click on the link to continue<br />
                            with your application.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InfoModal;