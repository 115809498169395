import React from 'react';
import images from '../../../utils/imageHandler/imageHelper';

const SkipInfoModal = (props) => {
    const { toggleModal, skipHandler } = props;
    return (
        <>
            <div className="overlay-info">
                <div className="info-modal-content">
                    <button type="button" onClick={toggleModal} className="close" data-dismiss="modal" id="nextBtn">&times;</button>
                    <div className="info-modal-body">
                        <img src={images.alert} className="alert_img" alt="" />
                        <p><b>You can skip, that's fine!</b> <br />
                            But until you complete <b> all </b> the registration <br />
                            steps, we cannot pass on your loan application <br />
                            to potential lenders. So remember to come <br />
                            back later and fill in the info.</p>

                        <button type="button" id="nextBtn" className="btn3" data-toggle="modal"
                            data-target="#skip" onClick={skipHandler}>CONTINUE SKIPPING & COME BACK
                            LATER</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SkipInfoModal;