import React, { useEffect } from 'react';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { withRouter } from 'react-router-dom';
import LeftLayout from '../preLogin/leftLayout';
import { hideHeader } from '../../redux/actions/commonActions';
import { connect } from 'react-redux';

const SendOtp = (props) => {
    const { user, history } = props;

    useEffect(() => {
        props.hideHeader(false);
        return () => {
            props.hideHeader(true);
        }
    }, [])

    const validate = values => {
        const errors = {};
        if (!values.mobile) {
            errors.mobile = 'required';
        } else if (!/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(values.mobile)) {
            errors.mobile = 'Invalid mobile number';
        }
        return errors;
    }

    return (
        <div className="auction-finance-Application-Form">
            <section className="application_from">
                <LeftLayout title="Capatool Login" />
                <div className="right">

                    <div className="steps_wizard">
                        <Formik
                            initialValues={{ mobile: '' }}
                            validate={validate}
                            onSubmit={(values, { setSubmitting }) => {
                                let data = {
                                    mobile: values.mobile,
                                    country_code: 91
                                }
                                let formData = ApiHelper.renderFormData(data)
                                ApiHelper.makeRequest('otp', formData).then((res) => {
                                    if (res.status === 'true') {
                                        ApiHelper.Alert(res.message, 'success');
                                    } else {
                                        ApiHelper.Alert(res.message, 'error');
                                    }
                                    setSubmitting(false);
                                })
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => {
                                return (
                                    <form id="regForm" onSubmit={handleSubmit}>
                                        <div className="tab">

                                            <div className="form_heading  jcc">Two Factor Authentication:</div>

                                            <a href="#" className="primary font-13 fr tdu ">You can view our privacy policy here.</a>

                                            <div className="fields">

                                                <div className="form-group">
                                                    <label className="font-18 "> Mobile Number</label>
                                                    <input
                                                        type="text"
                                                        name="mobile"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.mobile} />
                                                    <span className="hint"> {errors.mobile && touched.mobile && errors.mobile}</span>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn1" disabled={isSubmitting}>Submit </button>
                                            </div>
                                        </div>
                                    </form>

                                )
                            }}
                        </Formik>
                    </div>

                </div>
            </section>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendOtp));