import React, { useEffect, Fragment } from "react";
import AmountInput from "../global/form/amoutInput";
import Input from '../global/form/input';
import Radio from "../global/form/radio";
import Select from '../global/form/select';
import TextArea from "../global/form/textarea";
import HTMLText from "../global/form/htmlText";
import Warning from "../global/form/warning";
import AddButton from "../global/form/button";
import DatePicker from "../global/form/datePicker";

const FormLayout = (props) => {
    const { formFields, values, errors, repeat, is_repeat, touched, onBlur, handleChange, addNewHandler } = props;
    useEffect(() => {
    })
    const btnTitle = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FIFTH', 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'EIGHT']
    const getHtmlText = (option_values) => {
        return (
            <HTMLText
                option_value={option_values}
            />)
    }
    return (
        <div className="tab">
            <div className="form_heading jcl">
                {props?.details?.title}
            </div>

            <p className="black font-13 fr mb-1">Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam <a href="#"
                    className="primary font-13 fr tdu ">privacy policy etc.</a></p>

            <div className="fields">
                {formFields && formFields.length > 0 && formFields.map((item, index) => (

                    <Fragment key={index}>
                        {(item?.field_type == 'text' || item?.field_type == 'email' || item?.field_type == 'number') && !item.hide && <Input
                            label={item?.title}
                            type={item?.field_type}
                            name={item?.id}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            handleChange={(e) => handleChange(e)}
                            value={values[item?.id]}
                            placeholder={item?.placeholder} />}
                        {item?.field_type == 'select' && <Select
                            label={item?.title}
                            options={item?.options}
                            name={item?.id}
                            handleChange={(e) => handleChange(e)}
                            value={values[item?.id]}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            placeholder={item?.placeholder} />}
                        {item?.field_type == 'amount' && !item.hide && <AmountInput
                            label={item?.title}
                            options={item?.options}
                            name={item?.id}
                            handleChange={(e) => handleChange(e)}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            value={values[item?.id]}
                            placeholder={item?.placeholder} />}
                        {item?.field_type == 'radio' && <Radio
                            label={item?.title}
                            options={item?.options}
                            name={item?.id}
                            handleChange={(e) => handleChange(e)}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            value={values[item?.id]}
                            placeholder={item?.placeholder} />}
                        {item?.field_type == 'textarea' && <TextArea
                            label={item?.title}
                            options={item?.options}
                            name={item?.id}
                            handleChange={(e) => handleChange(e)}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            value={values[item?.id]}
                            placeholder={item?.placeholder} />}
                        {(item?.field_type == 'date') && <DatePicker
                            label={item?.title}
                            type={item?.field_type}
                            name={item?.id}
                            onBlur={onBlur}
                            touched={touched}
                            errors={errors}
                            handleChange={(e) => handleChange(e)}
                            value={values[item?.id]}
                            placeholder={item?.placeholder} />}
                        {item?.field_type == 'warn' && !item.hide && <Warning
                            title={item?.option_values}
                            tooltip={item?.tooltip}
                        />}
                        {item?.field_type == 'html' && !item.hide && getHtmlText(item?.option_values)}
                    </Fragment>
                ))}
                {is_repeat == 1 && <AddButton
                    onClickHandler={addNewHandler}
                    repeat={repeat}
                    label={`Add ${btnTitle[repeat]} ${props?.details?.title}`}
                />}
            </div>
        </div>
    )
}

export default FormLayout;