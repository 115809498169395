import React from 'react';
import { withRouter } from 'react-router-dom';
import Setting from '../../components/userSettings/settings';
import { connect } from 'react-redux'
import { baseUrl } from '../../Config';

function UserSettings(props) {

    const { user } = props;

    const changePasswordHandler = () => {
        const { history } = props;
        if (history) history.push(baseUrl + "/change-password");
    }
    return (
        <div className="user-portal-home">
            <Setting user={user?.userData} changePasswordHandler={changePasswordHandler} />
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(UserSettings));