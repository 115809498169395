import React from "react";
import Slider from "react-slick";
import images from "../../utils/imageHandler/imageHelper";
import CarouselLayout from "../global/carousel";
function CustomerFeedback() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		autoplay: true,
		slidesToShow: 2,
		slidesToScroll: 2,
	};
	const next = () => {
		slider.slickNext();
	}
	const previous = () => {
		slider.slickPrev();
	}
	let slider = ''
	return (
		<section className="customer_feedback">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 mb-4">
						<span className="primary font-11 ttu ls">CUSTOMER FEEDBACK</span>
						<h4 className="heading_with_border black fr font-28">Capatool Financial comes well recommended.</h4>
						<p className="black font-16">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
						nibh euismod tincidunt
						ut laoreet dolore magna aliquam erat volutpat. Duis autem vel eum iriure dolor.</p>
					</div>
					<div className="col-lg-12">
						<Slider ref={ref => slider = ref} {...settings}>
							<CarouselLayout />
							<CarouselLayout />
							<CarouselLayout />
							<CarouselLayout />
						</Slider>
						<div className="previous_next">
							<img className="next" src={images.right} onClick={next} />
							<img className="previous" src={images.left} onClick={previous} />
						</div>
						<a href="index.html" className="viewmore primary ttu fb ls font-14"> view more</a>

					</div>
				</div>
			</div>
		</section>

	);
}

export default CustomerFeedback;