import React from 'react';
import images from '../../utils/imageHandler/imageHelper';

const Description = () => {
    return (
        <section className="eligibilty-cal-sec">
            <div className="container">
                <div className="fix-container mx-auto">
                    <h6 className="primary font-11 ttu ls">Peace of mind</h6>
                    <h2 className="heading_with_border font-28 black">Why should I use the eligibility calculator?</h2>

                    <div className="mb-5">
                        <p className="font-16 fr black">We match your information with the eligibility criteria of the
                        lenders we work with to return a result on their willingness to lend to you. This will give
                        you conﬁdence and clarity when applying for Auction
                        Finance for your project and when speaking to a mortgage broker. No advice or recommendation
                        in relation to speciﬁc Development Finance lenders is given nor implied.</p>
                    </div>

                    <ul className="eligibility-lists d-flex flex-wrap pl-0 justify-content-center mb-0">
                        <li>
                            <div className="image-block"><img src={images.loanCalculator} alt="" /></div>
                            <h6 className="font-14 primary3 ttu fb">Loan calculator.</h6>
                            <p className="font-14 black fb ttu">
                                Quick tool to budget for specific lending needs.
                        </p>
                        </li>
                        <li>
                            <div className="image-block"><img src={images.softCheck} alt="" /></div>
                            <h6 className="font-14 primary3 ttu fb">Soft checks.</h6>
                            <p className="font-14 black fb ttu">
                                No impact on your credit rating.
                        </p>
                        </li>
                        <li>
                            <div className="image-block"><img src={images.accurate} alt="" /></div>
                            <h6 className="font-14 primary3 ttu fb">Accurate.</h6>
                            <p className="font-14 black fb ttu">
                                Uses lender criteria and your credit record.
                        </p>
                        </li>
                        <li>
                            <div className="image-block"><img src={images.savesTime} alt="" /></div>
                            <h6 className="font-14 primary3 ttu fb">Saves time.</h6>
                            <p className="font-14 black fb ttu">
                                10 minutes to see your aggregated eligibility results.
                        </p>
                        </li>
                        <li>
                            <div className="image-block"><img src={images.bestRates} alt="" /></div>
                            <h6 className="font-14 primary3 ttu fb">Best rates.</h6>
                            <p className="font-14 black fb ttu">
                                Capatool financial always delivers you the very best rates.
                        </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default Description;