import React from "react";
import { IMAGE_URL } from "../../Config";

function SolutionsTypes(props) {
    const { title, hover_image, image, id } = props.item;
    return (
        <div className="col-lg-4 col-md-6" onClick={() => props.solutionsClickHandler(title, id)}>
            <div className="media">
                <div className=" media-middle">
                    <img alt="" src={IMAGE_URL + image} className="media-object" />
                    <img alt="" src={IMAGE_URL + hover_image} className="media-object hover_img" />
                </div>
                <div className="media-body">
                    <p className="font-11 white ttu">
                        <div dangerouslySetInnerHTML={{ __html: title }}></div>
                    </p>
                </div>
                <a className="stretched-link"></a>
            </div>
        </div>
        // <div className="col-lg-4 col-md-6" onClick={props.solutionsClickHandler}>
        //     <div className="media">
        //         <div className="media-middle">
        //             <img alt="" src={icon} className="media-object" />
        //         </div>
        //         <div className="media-body">
        //             <p className="font-11 white ttu">
        //                 <div dangerouslySetInnerHTML={{ __html: title }}></div>
        //             </p>
        //         </div>
        //         {/* <a href="auction-finance-home.html" className="stretched-link"></a> */}
        //     </div>
        // </div>
    );
}

export default SolutionsTypes;
