import React, { useState } from 'react';
import images from '../../utils/imageHandler/imageHelper';

const PersonalDetails = () => {
    const [asset, setAssetOpen] = useState(false);
    const [liabilities, setLiabilitiesOpen] = useState(false);
    return (
        <>
            <div className="px-4 py-3  pb-0  border-bottom">
                <div className="row">
                    <div className=" form-group col-md-12 mb-0">
                        <p className="font-18   fb  mb-0">Your Personal Assets & Liabilities Statement:
                        </p>
                    </div>
                </div>
            </div>


            <div id="accordion" className="px-2 mb-5">
                <div className="card border-0">
                    <div className="border-bottom py-2" id="headingTwo">
                        <h5 className="mb-0">
                            <button className=" ls4 btn btn-link collapsed" 
                            data-toggle="collapse"
                                onClick={() => setAssetOpen(!asset)}
                                data-target="#collapseTwo"
                                 aria-expanded="false" 
                                 aria-controls="collapseTwo">
                                <img src={images.right2} className="mr-2 " /> YOUR ASSETS
                            </button>
                        </h5>
                    </div>
                    {asset && 
                    // <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                            richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                            brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                            shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                            cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                            Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore sustainable VHS.
                        {/* </div> */}
                    </div>}
                </div>


                <div className="card border-0">
                    <div className="border-bottom py-2" id="headingThree">
                        <h5 className="mb-0">
                            <button className="ls4 btn btn-link collapsed"
                                onClick={() => setLiabilitiesOpen(!liabilities)}
                                data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <img src={images.right2} className="mr-2" /> YOUR LIABILITIES
                            </button>
                        </h5>
                    </div>
                    {liabilities &&
                        // <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                            richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                            brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                            shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                            cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                            Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore sustainable VHS.
                        {/* </div> */}
                        </div>}
                </div>


            </div>
        </>
    );
}

export default PersonalDetails;