import React from 'react';
import { withRouter } from 'react-router-dom';
import Inbox from '../../components/userInbox/userInbox';
import { connect } from 'react-redux'
import { useState } from 'react';
import Helper from '../../utils/apiHelper/apiHelper';
import { useEffect } from 'react';
import moment from 'moment';
import { hideHeader, saveInboxData } from './../../redux/actions/commonActions';

function UserInbox(props) {
    const { user, history } = props;
    const [inboxData, setInboxData] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    useEffect(() => {
        getInbox(activePage);
    }, [])
    const getInbox = (page) => {
        let formData = Helper.renderFormData({
            page: page,
            from_date: from_date,
            to_date: to_date
        })
        Helper.makeRequest('get-inbox', formData).then(async (res) => {
            if (res.status === 'true') {
                setInboxData(res.data)
                props.inboxData(res?.total_unread_count)
            }
        })
    }
    const emailViewHandler = (data) => {
        if (history) {
            history.push({ pathname: 'email-view', state: data })
        }
    }
    const paginationClickHandler = (type) => {
        if (type == 'next') {
            if (activePage < inboxData?.last_page) {
                setActivePage(activePage + 1);
                getInbox(activePage + 1);
            }
        } else {
            if (activePage > 1) {
                setActivePage(activePage - 1);
                getInbox(activePage - 1);
            }
        }
    }
    const fetchMoreData = () => {
        let formData;
        if (!inboxData) {
            formData = Helper.renderFormData({
                page: 1,
                from_date: from_date,
                to_date: to_date
            })
        } else {
            formData = Helper.renderFormData({
                page: activePage + 1,
                from_date: from_date,
                to_date: to_date
            })
        }
        Helper.makeRequest('get-inbox', formData).then(async (res) => {
            if (res.status === 'true') {
                if (res?.data?.data?.length) {
                    if (inboxData.data && res.data.data) {
                        res.data.data = inboxData?.data.concat(res?.data?.data);
                    }
                    setInboxData(res.data);
                    setActivePage(activePage + 1);
                }
                if (res.data && res.data.last_page) {
                    if (res.data.last_page == activePage) {
                        setHasMore(false)
                    }
                }
            }
        })
    }
    const filterSubmitHandler = () => {
        if (!from_date) {
            Helper.Alert('Please select the from date.', 'error')
        } else if (!to_date) {
            Helper.Alert('Please select the start date.', 'error')
        } else if (from_date > to_date) {
            Helper.Alert('From Date can not be grater than to the To Date.', 'error')
        } else {
            getInbox(activePage);
        }
    }
    const onEndDateChange = (value) => {
        let date = moment(value).format('YYYY-MM-DD')
        setEndDate(value);
        setToDate(date);
    }
    const onStartDateChange = (value) => {
        let date = moment(value).format('YYYY-MM-DD')
        setStartDate(value);
        setFromDate(date);

    }
    return (
        <div className="user-portal-home">
            <Inbox
                user={user?.userData}
                list={inboxData}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                emailViewHandler={emailViewHandler}
                activePage={activePage}
                endDate={endDate}
                startDate={startDate}
                submitFilter={filterSubmitHandler}
                paginationClickHandler={paginationClickHandler}
                setEndDate={onEndDateChange}
                setStartDate={onStartDateChange} />
        </div>
    );
}


const mapStateToProps = state => ({
    user: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    inboxData: (data) => dispatch(saveInboxData(data))

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInbox));
