import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Collapse } from 'react-bootstrap';
function FAQ(props) {
    const [toggle, setToggle] = useState(false)
    const [id, setID] = useState('');

    const toggleFaq = (index) => {
        if (id == index) {
            setToggle(!toggle);
            setID(index);
        } else {
            setToggle(true);
            setID(index);
        }
    }
    return (
        <section className="accordion-section">
        <div className="container">

            <h4 className="heading_with_border white text-center fr font-28">FAQ's about Auction Finance </h4>

            <div className="row">
                <div className="col-lg-9 m-auto">

                    <div className="accordion" id="accordionExample">
                        {props?.data && props.data.map((item, index) => (
                                <div className=" acc-section" key={index}>
                                    <div className="" id="headingOne">
                                        <h2 className="mb-0">
                                            <button type="button"
                                                className="btn btn-link collapsed"
                                                onClick={() => toggleFaq(index)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={toggle && id == index}
                                                data-toggle="collapse"
                                                data-target="#collapseOne">
                                                <span className="img"></span>{item.title} </button>
                                        </h2>
                                    </div>

                                    <Collapse in={toggle && id == index}>
                                        <div id="example-collapse-text"
                                            data-parent="#accordionExample">
                                            <div className="text-area">
                                                <p>{item?.description}</p>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>

                            ))}
                    </div>

                </div>

            </div>
        </div>
    </section>

      
    );
}

export default withRouter(FAQ);