const commonReducer = (state = {
    sidebarToggle: false,
    header: window.localStorage.getItem('header'),
    count: window.localStorage.getItem('count'),
    loanCount: window.localStorage.getItem('loanCount'),
    sidebar_item: window.localStorage.getItem('sidebar_item'),
}, action) => {
    switch (action.type) {
        case 'HIDE_HEAD_FOOTER':
            state = {
                ...state,
                header: action.payload
            }
            localStorage.setItem('header', action.payload);
            break;
        case 'TOGGLE_SIDEBAR':
            state = {
                ...state,
                sidebarToggle: !state.sidebarToggle
            }
            break;
        case 'INBOX_COUNT':
            state = {
                ...state,
                count: action.payload
            }
            localStorage.setItem('count', action.payload);
            break;
        case 'LOAN_COUNT':
            state = {
                ...state,
                loanCount: action.payload
            }
            localStorage.setItem('loanCount', action.payload);
            break;
        case 'SIDEBAR_ITEM':
            state = {
                ...state,
                sidebar_item: action.payload
            }
            localStorage.setItem('sidebar_item', action.payload);
            break;
        default:
            break;
    }
    return state;
};
export default commonReducer;