import React, { useEffect, useRef, useState } from 'react';
import images from '../../utils/imageHandler/imageHelper';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { login } from './../../redux/actions/authActions';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import LeftLayout from '../preLogin/leftLayout';
import { baseUrl, RECAPTCHA_CLIENT_KEY } from '../../Config';
import ReCAPTCHA from "react-google-recaptcha";

const Login = (props) => {
    const _isMounted = useRef(true); 
    const { history } = props;
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [showResendEmail, setShowResendEmail] = useState(1);
    useEffect(() => {
        if (_isMounted) props.hideHeader(false);
        return () => {
            props.hideHeader(true);
            if (history.action === "POP") {
                history.push('/login')
            }
            _isMounted.current = false;
        }
    }, [])

    const onRegisterHandle = () => {
        if (history) history.push(baseUrl + "/register");
    }
    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!values.password) {
            errors.password = 'Password is required.';
        }
        return errors;
    }
    const handlePasswordHide = (id) => {
        let type = document.getElementById(id).type; 
        document.getElementById(id).type = type == 'password' ? 'text' : 'password'
    }
    const forgetPasswordHandler = () => {
        if (history) history.push(baseUrl + "/forget-password");
    }
    const sendOtpHandler = (values) => {
        const data = {
            country_code: values?.country_code,
            mobile: values.mobile
        }
        let formData = ApiHelper.renderFormData(data);
        ApiHelper.makeRequest('otp', formData).then((res) => {
            if (res.status === 'true') {
                ApiHelper.Alert(res.message);
            } else {
                ApiHelper.Alert(res.message, 'error');
            }
        })
    }
    function onChange(value) {
        setToken(value);
        setError('');
    }
    const privacyPolicyHandler = () => {
        if (history) {
            history.push(baseUrl + "/privacy-policy");
        }
    }
    const resendEmail = () => {
        let formData = ApiHelper.renderFormData({ email: email })
        ApiHelper.makeRequest('resend-verification', formData).then((res) => {
            if (res.status === 'true') {
                ApiHelper.Alert(res.message, 'success');
            } else {
                ApiHelper.Alert(res.message, 'error');
            }
        })
    }
    return (
        <>
            <div className="auction-finance-Application-Form">
                <section className="application_from">
                    <LeftLayout title="Login" />
                    <div className="right">
                        <div className="steps_wizard">
                            <Formik
                                initialValues={{ email: '', password: '' }}
                                validate={validate}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (!token) {
                                        setError('Please validate reCaptcha');
                                        setSubmitting(false);
                                        return;
                                    }
                                    setError('')
                                    let formData = ApiHelper.renderFormData(values);
                                    setEmail(values.email)
                                    ApiHelper.makeRequest('login', formData).then(async (res) => {
                                        console.log('resssssssssss', res)
                                        setShowResendEmail(res?.data?.status)
                                        if (res.status === 200) {
                                            if (res.data.is_enable_verification) {
                                                props.hideHeader(false)
                                                await sendOtpHandler(res.data);
                                                if (history) {
                                                    await history.push({
                                                        pathname: baseUrl + "/authentication/verify", state: res
                                                    });
                                                }

                                            } else {
                                                ApiHelper.Alert(res.message, 'success');
                                                props.hideHeader(false)
                                                props.onLogin(res);
                                                if (history) {
                                                    await history.push(baseUrl + "/");
                                                }
                                            }
                                        } else {
                                            ApiHelper.Alert(res.message, 'error');
                                        }
                                        setSubmitting(false);
                                    })
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => {
                                    return (
                                        <form id="regForm" onSubmit={handleSubmit}>
                                            <div className="tab">

                                                <div className="form_heading  jcc">YOUR CONTACT INFORMATION:</div>

                                                <p className="black font-18">Don't Have an Account?</p>

                                                <a onClick={onRegisterHandle} className="db btn1 mb-5"> REGISTER NOW</a>

                                                <p className="black font-18">No Account Yet? No Problem... </p>
                                                <p className="black font-13 fr mb-1">All the following registration pages are skippable, but
                                                    this one is
                                                    required in order to
                                                    proceed with initial registration. We're going to be asking you some questions that will
                                                    include some information about your finance needs. Let's keep that info safe and secure
                                                    by creating your free account.</p>

                                                <a onClick={privacyPolicyHandler} className="primary font-13 fr tdu ">You can view our privacy policy here.</a>

                                                {showResendEmail != 1 ? <>
                                                    <br />
                                                    <br /> <span className="black font-13 fr mb-1">
                                                        We have sent an email verification link to your email address to verify your email address.
                                                        Didn't receive it?&nbsp;&nbsp;
                                                    </span>
                                                    <span onClick={resendEmail}
                                                        className="primary font-13 fr tdu cursor-pointer">
                                                        please click here to send it again
                                                    </span></> : ''}
                                                <div className="fields">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="font-16"> Email </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email} />

                                                        <span className="hint">
                                                            {errors.email && touched.email && errors.email}
                                                        </span>
                                                    </div>

                                                    <div className="form-group password">
                                                        <label htmlFor="" className="font-16"> Password </label>
                                                        <span className="eye" onClick={() => handlePasswordHide('password')}></span>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                        />

                                                        <span className="hint">
                                                            {errors.password && touched.password && errors.password}
                                                        </span>
                                                        <p className="info">(Must be alphanumeric, be at least 8 characters long and contain at least one special character)
                                                        </p>
                                                    </div>
                                                    <a onClick={forgetPasswordHandler} className="primary font-13 fr tdu ">Forgot password?</a>
                                                    <div className="form-group">
                                                        <ReCAPTCHA
                                                            className="g-recaptcha"
                                                            sitekey={RECAPTCHA_CLIENT_KEY}
                                                            onChange={onChange}
                                                        />
                                                        <span className="hint">
                                                            {error}
                                                        </span>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn1" disabled={isSubmitting}>SUBMIT </button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>

                    </div>
                </section>


            </div>
        </>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    onLogin: (data) => dispatch(login(data)),
    hideHeader: (data) => dispatch(hideHeader(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
