import React from 'react';
import { IMAGE_URL } from '../../Config';
import images from '../../utils/imageHandler/imageHelper';

const ProofIncome = (props) => {
    const { title, description, other, field_type, slug, imageSelectHandler, handleChange, deleteHandler, form_type_id, loan_request_id, field_id, value } = props;
    return (
        <div className="px-4 py-3   border-bottom">
            <div className="row">
                <div className=" form-group col-lg-9 col-md-8">
                    <span className="font-18 ">{title}</span>
                    <p className="font-18 mt-2 mb-0 primary">
                        We accept either PDF, JPG or PNG.</p>

                    <div className="mt-3">
                        <div className=" custom_checkbox">
                            <input
                                id="check-4"
                                type="radio"
                                onChange={(e) => handleChange(e, field_type, slug)}
                                checked={other == 'EMPLOYED - Paid Monthly'}
                                value="EMPLOYED - Paid Monthly" />
                            <label htmlFor="check-4" className="font-18">EMPLOYED - Paid Monthly</label>
                            <p className="pl-4 primary font-18"> (Please provide last 3 months wage slips)</p>
                        </div>
                        <div className="custom_checkbox mb-2">
                            <input
                                id="check-2"
                                type="radio"
                                onChange={(e) => handleChange(e, field_type, slug)}
                                checked={other == 'EMPLOYED - Paid Weekly'}
                                value="EMPLOYED - Paid Weekly" />
                            <label htmlFor="check-2" className="font-18">EMPLOYED - Paid Weekly</label>
                        </div>

                        <div className=" custom_checkbox">
                            <input
                                type="radio"
                                id="check-3"
                                onChange={(e) => handleChange(e, field_type, slug)}
                                checked={other == 'SELF EMPLOYED'}
                                value="SELF EMPLOYED" />
                            <label htmlFor="check-3" className="font-18">SELF EMPLOYED</label>
                        </div>
                    </div>

                </div>

                <div className=" form-group col-lg-3 col-md-4 text-md-right">
                    {value.length > 0 && value[0] ?
                        <div className="small_id mb-2">
                            <embed
                                src={IMAGE_URL + value[0]?.photo}
                                height="100"
                                width="200"
                                onClick={() => {
                                    window.open(IMAGE_URL + value[0]?.photo, '_blank').focus();
                                }}
                                className="mr-2 w-100  cursor-pointer" />
                            {/* <a href={IMAGE_URL + value[1]?.photo} target="_blank" >test</a> */}

                            <img
                                src={images.close}
                                className="close cursor-pointer"
                                height="25"
                                onClick={() => deleteHandler(value[0]?.id)} />
                        </div>
                        :
                        <button className="border-0 btn-blue uploadfile ls mb-2">
                            <input type="file" id={field_id + '1'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title, field_type, slug)} /> UPLOAD FILE 1
                        </button>}

                    {value.length > 1 && value[1] ?
                        <div className="small_id mb-2">
                            <embed
                                src={IMAGE_URL + value[1]?.photo}
                                height="100"
                                width="200"
                                onClick={() => {
                                    window.open(IMAGE_URL + value[1]?.photo, '_blank').focus();
                                }}
                                className="mr-2 w-100  cursor-pointer" />
                            <img
                                src={images.close}
                                className="close cursor-pointer"
                                height="25"
                                onClick={() => deleteHandler(value[1]?.id)} />
                        </div>
                        :
                        <button className="border-0 btn-blue uploadfile ls mb-2">
                            <input type="file" id={field_id + '2'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title, field_type, slug)} /> UPLOAD FILE 2
                        </button>}

                    {value.length > 2 && value[2] ?
                        <div className="small_id mb-2">
                            <embed
                                src={IMAGE_URL + value[2]?.photo}
                                height="100"
                                width="200"
                                onClick={() => {
                                    window.open(IMAGE_URL + value[2]?.photo, '_blank').focus();
                                }}
                                className="mr-2 w-100  cursor-pointer" />
                            <img
                                src={images.close}
                                className="close cursor-pointer"
                                height="25"
                                onClick={() => deleteHandler(value[2]?.id)} />
                        </div>
                        :

                        <button className="border-0 btn-blue uploadfile ls mb-2">
                            <input type="file" id={field_id + '3'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title, field_type, slug)} /> UPLOAD FILE 3
                        </button>}

                    {/* <button className="border-0 btn-blue uploadfile ls mb-2">
                        <input type="file" id={field_id + '1'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title)} /> UPLOAD FILE 1
                    </button>

                    <button className="border-0 btn-blue uploadfile ls mb-2">
                        <input type="file" id={field_id + '2'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title)} /> UPLOAD FILE 2
                    </button>

                    <button className="border-0 btn-blue uploadfile ls mb-2">
                        <input type="file" id={field_id + '3'} onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title)} /> UPLOAD FILE 3
                    </button> */}
                </div>
            </div>
        </div>

    )
}

export default ProofIncome;