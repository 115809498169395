import React from "react";

function IndicativeQuote(props) {
    return (
            <div className="loan-details-right ml-md-auto mx-auto">
                <div className="loan-details-block">
                    <h2 className="font-28 black">Auction Finance<br />
                                    Indicative quote:</h2>
                    <ul className="list">
                        <li className="d-flex font-16 black">Total security value<span
                            className="ml-auto pl-4">£400,000.00</span></li>
                        <li className="d-flex font-16 black">Gross loan amount<span
                            className="ml-auto pl-4">£280,000.00</span></li>
                        <li className="d-flex font-16 black">Gross LTV<span
                            className="ml-auto pl-4">70.00%</span></li>
                        <li className="d-flex font-16 black">Interest rate <span className="ml-auto pl-4">0.75%
                                            *</span></li>
                    </ul>
                    <ul className="list">
                        <li className="d-flex font-16 black">Total arrangement fee<br />(2.00%)<span
                            className="ml-auto pl-4">£5,600.00</span></li>

                        <li className="d-flex font-16 black">Title Insurance fee<span
                            className="ml-auto pl-4">£280.00</span></li>
                        <li className="d-flex font-16 black">General Insurance fee<span
                            className="ml-auto pl-4">£250.00</span></li>
                        <li className="d-flex font-16 black">1 month interest retained<br />(0.75% pcm)<span
                            className="ml-auto pl-4">£2,100.00</span></li>
                        <li className="d-flex font-16 black">Fund Transfer Fee<span
                            className="ml-auto pl-4">£25.00</span></li>
                    </ul>
                    <ul className="list">
                        <li className="d-flex font-16 black">Net loan amount<span
                            className="ml-auto pl-4">£272,025.00</span></li>
                    </ul>
                </div>
                <p className="font-16 black">* For loans over £500,000 and over 70% LTV, rates are available
                                on request</p>

                <a  className="btn1 w-100 d-block text-center ttu font-12 mt-3 ls">See all
                                indicative quotes</a>
            </div>
    );
}

export default IndicativeQuote;