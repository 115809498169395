import React, { useEffect } from 'react';
import Sidebar from './sidebar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { logout } from './../../redux/actions/authActions';
import { hideHeader, saveInboxData } from './../../redux/actions/commonActions';
import Helper from '../../utils/apiHelper/apiHelper';

const UserLayout = (props) => {
    const onLogoutHandler = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            Helper.makeRequest('logout', {});
            props.isHeader(false)
            props.logout()
        }
    }

    return (
        <div className="user-portal-home">
            <Sidebar onLogoutHandler={onLogoutHandler} />
            {props.children}
        </div>
    )
}

const mapStateToProps = state => ({
    isHeader: state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    isHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserLayout));
