import React, { Fragment } from 'react'
import CopyRight from './copyright'
import Content from './content'
import images from '../../../utils/imageHandler/imageHelper'
import { withRouter } from 'react-router-dom'
const footer = (props) => {
    const privacyPolicyClickHandler = () => {
        if (props.history) {
            props.history.push('privacy-policy');
        }
    }

    return (
        <>
            <Content />
            <CopyRight privacyPolicyClickHandler={privacyPolicyClickHandler} />
            <span id="back-to-top"><img src={images.up} /></span>
        </>
    )
}
export default withRouter(footer);