export function login(data) {
    return {
        type: 'LOGIN',
        payload: data
    }
}
export function logout() {
    return {
        type: 'LOGOUT',
        payload: []
    }
}
export function updateProfile(data) {
    return {
        type: 'UPDATE_PROFILE',
        payload: data
    }
}