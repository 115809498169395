import React, { useState } from "react";
import Helper from "../../utils/apiHelper/apiHelper";

function GetStarted(props) {
    const [email, setEmail] = useState('');
    const [clicked, setClicked] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const clickContinueHandler = (e) => {
        e.preventDefault();
        if (!email.length > 0) {
            setMessage('Email is required.');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setMessage('Invalid Email Address.');
        } else {
            setMessage('');
            setClicked(true);
            let formData = Helper.renderFormData({ email: email });
            Helper.makeRequest('send-inquiry', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert(res.message);
                }
                else {
                    Helper.Alert(res.message, 'error');
                }
                setClicked(false);
            })
        }
    }
  
    const onChangeHandler = (e) => {
        setEmail(e.target.value);
        if (!e.target.value) {
            setMessage('Email is required.');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
            setMessage('Invalid Email Address.');
        } else {
            setMessage('');
        }

    }
    return (
        <section className="get_started">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <span className="primary font-11 fb ttu ls">GET STARTED</span>
                        <h4 className="heading_with_border black fr font-28">Ready to simplify your lending? </h4>
                        <p className="black font-16">Enter your email below to open the doors to your high end finance options
						with Capatool Financial.</p>
                    </div>
                    <form className="col-lg-5" action="">
                        <div className="form-group">
                            <input type="text" className="form-control" value={email} onChange={onChangeHandler} placeholder="Your Email" />
                            <span className="hint">{message}</span>
                        </div>
                        <button className="btn2 font-14 d-block" disabled={clicked} onClick={clickContinueHandler}>CONTINUE</button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default GetStarted;