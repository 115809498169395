
import React from 'react'
import images from '../../../utils/imageHandler/imageHelper';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { baseUrl } from '../../../Config';

class NavItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    }
  }

  toggleNavbar = () => {
    if (this.state.toggle) {
      let element = document.getElementById("collapsibleNavbar");
      element.classList.remove("show");
      this.setState({ toggle: !this.state.toggle });
    } else {
      let element = document.getElementById("collapsibleNavbar");
      element.classList.add("show");
      this.setState({ toggle: !this.state.toggle });
    }

  }

  render() {
    const { onSignUpClick, onLoginInClick } = this.props;
    return (
      <header>
        <div className="load">
          <img src={images.logo} alt={baseUrl + "/"} />
        </div>
        <nav className="navbar navbar-expand-lg ">
          <a className="navbar-brand" href={baseUrl + "/"}>
            <img src={images.logo} alt="" />
          </a>
          <button className="navbar-toggler collapsed" onClick={this.toggleNavbar} type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon">
              <img src={images.menu} alt="" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto">
              <NavLink to={baseUrl + '/about-us'} exact>
                <li className="nav-item">
                  <a className="nav-link"  >About Us</a>
                </li>
              </NavLink>

              <li className="nav-item">
                <a className="nav-link" href="#"> Services </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> Lenders </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#"> HELP CENTRE </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> BLOG </a>
              </li>

              <NavLink to={baseUrl + '/contact-us'} exact>
                <li className="nav-item">
                  <a className="nav-link" > Contact Us </a>
                </li>
              </NavLink>
              {!this.props.auth.isAuthenticated && <>
                <li className="nav-item signup">
                  <a className="nav-link" onClick={onSignUpClick}> SIGN UP </a>
                </li>
                <li className="nav-item signin">
                  <a className="nav-link" onClick={onLoginInClick}> SIGN IN </a>
                </li>
              </>}
            </ul>
          </div>
        </nav>
      </header>

    );
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer
});

export default connect(mapStateToProps)(NavItems);