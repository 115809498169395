import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import LeftLayout from '../preLogin/leftLayout';
import ReCAPTCHA from "react-google-recaptcha";
import { baseUrl, RECAPTCHA_CLIENT_KEY } from '../../Config';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const RegisterUser = (props) => {
    const { history } = props;

    const onLoginHandle = () => {
        if (history) history.push(baseUrl + "/login");
    }
    useEffect(() => {
    }, [])

    const handlePasswordHide = (id) => {
        let type = document.getElementById(id).type;
        document.getElementById(id).type = type == 'password' ? 'text' : 'password'
    }
    const [setLoading, loading] = useState(false);
    const [code, setCode] = useState('');
    const [mobile, setMobile] = useState('');
    const [codeMobile, setCodeMobile] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Name is required.';
        } else if (!/^[a-zA-Z ]*$/i.test(values.name)) {
            errors.name = 'Invalid name';
        }

        if (!values.email) {
            errors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (values.telephone && !/^[0-9]*$/.test(values.telephone)) {
            errors.telephone = 'Invalid telephone number';
        } else if (values.telephone && !(values.telephone.length > 8 && values.telephone.length < 15)) {
            errors.telephone = 'Telephone number must be 9 to 14 digits';
        }
        if (!values.password) {
            errors.password = 'Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.password)) {
            errors.password = 'Must be alphanumeric and contain at least one special character';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.confirmPassword)) {
            errors.confirmPassword = 'Must be alphanumeric and contain at least one special character';
        }

        if (values.password != values.confirmPassword) {
            errors.confirmPassword = 'Password and confirm password should be same.';
        }
        return errors;
    }

    function onChange(value) {
        setToken(value);
        setError('');
    }

    const mobileNumberHandler = (value, code) => {
        setCode(code?.dialCode);
        setCodeMobile(value)
        let number = value.substring(code?.dialCode?.length);
        setMobile(number)
        if (!number) {
            setMobileError('Mobile is required.');
        } else if (number && !(number.length > 8 && number.length < 15)) {
            setMobileError('Mobile number must be 9 to 14 digits');
        } else {
            setMobileError('');
        }
    }

    const privacyPolicyHandler = () => {
        if (history) {
            history.push(baseUrl + "/privacy-policy");
        }
    }

    return (
        <>
            <Formik
                initialValues={{ name: '', email: '', telephone: '', mobile: codeMobile, password: '', confirmPassword: '', country_code: code }}
                validate={validate}
                onSubmit={(values, { setSubmitting }) => {
                    if (!token) {
                        setError('Please validate reCaptcha');
                        setSubmitting(false);
                        return;
                    }
                    values.mobile = mobile;
                    values.country_code = code;
                    values.register_type = "loan_request";
                    let formData = ApiHelper.renderFormData(values)
                    ApiHelper.makeRequest('register', formData).then(async (res) => {
                        if (res.status === 'true') {
                            ApiHelper.Alert(res.message, 'success');
                            window.localStorage.setItem('token', res?.security_token)
                            props.registerSubmitHandler();
                        } else {
                            ApiHelper.Alert(res.message, 'error');
                        }
                        setSubmitting(false);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => {
                    return (
                        <form id="regForm" onSubmit={handleSubmit}>
                            <div className="tab">
                                <div className="form_heading  jcc">
                                    YOUR CONTACT INFORMATION:
                                </div>

                                <p className="black font-18">Already Have an Account?</p>

                                <a onClick={onLoginHandle} className="db btn1 mb-5"> SIGN IN</a>

                                <p className="black font-18">No Account Yet? No Problem... </p>
                                <p className="black font-13 fr mb-1">All the following registration pages are skippable, but
                                    this one is
                                    required in order to
                                    proceed with initial registration. We're going to be asking you some questions that will
                                    include some information about your finance needs. Let's keep that info safe and secure
                                    by creating your free account.</p>

                                <a onClick={privacyPolicyHandler} className="primary font-13 fr tdu ">You can view our privacy policy here.</a>

                                <p className="ttu  my_note"> <span> NOTE</span> <sup>*</sup> are required fields</p>

                                <div className="fields">

                                    <div className="form-group">
                                        <label htmlFor="" className="font-16"> Name <sup className="primary mandatory">*</sup></label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name} />
                                        <span className="hint">
                                            {errors.name && touched.name && errors.name}
                                        </span>

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="" className="font-16"> Email <sup className="primary mandatory">*</sup></label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email} />
                                        <span className="hint">
                                            {errors.email && touched.email && errors.email}
                                        </span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="" className="font-16"> Telephone <span className="fr gray-200">
                                            (Optional)</span></label>
                                        <input
                                            type="text"
                                            name="telephone"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telephone} />
                                        <span className="hint">
                                            {errors.telephone && touched.telephone && errors.telephone}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="font-16"> Mobile <sup className="primary mandatory">*</sup></label>
                                        <PhoneInput
                                            name="text"
                                            className="form-control"
                                            placeholder=""
                                            disableInitialCountryGuess={false}
                                            enableLongNumbers={true}
                                            country={"gb"}
                                            disableCountryGuess={true}
                                            countryCodeEditable={false}
                                            onBlur={handleBlur}
                                            onChange={mobileNumberHandler}
                                            value={codeMobile} />
                                        <span className="hint">
                                            {mobileError}
                                        </span>
                                        <p className="info">(Mobile number must be 9 to 14 digits)</p>
                                    </div>

                                    <div className="form-group password">
                                        <label htmlFor="" className="font-16"> Create Password <sup
                                            className="primary mandatory">*</sup></label>
                                        <span className="eye" onClick={() => handlePasswordHide('password')}></span>

                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password} />
                                        <span className="hint">
                                            {errors.password && touched.password && errors.password}
                                        </span>
                                        <p className="info">(Must be alphanumeric, be at least 8 characters long and contain at least one special character)</p>
                                    </div>

                                    <div className="form-group password">
                                        <label htmlFor="" className="font-16"> Confirm Password <sup
                                            className="primary mandatory">*</sup> </label>
                                        <span className="eye" onClick={() => handlePasswordHide('confirmPassword')}></span>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmPassword} />
                                        <span className="hint">
                                            {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                                        </span>
                                        <p className="info">(Must be alphanumeric, be at least 8 characters long and contain at least one special character)</p>
                                    </div>
                                    <div className="form-group">
                                        <ReCAPTCHA
                                            className="g-recaptcha"
                                            sitekey={RECAPTCHA_CLIENT_KEY}
                                            onChange={onChange}
                                        />
                                        <span className="hint">
                                            {error}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn1"
                                        disabled={isSubmitting}
                                    >CONTINUE </button>
                                </div>
                            </div>

                        </form>
                    )
                }}
            </Formik>
        </>
    );
}

export default withRouter(RegisterUser);
