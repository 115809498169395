const authReducer = (state = {
    isAuthenticated: (localStorage.getItem('isAuthenticated') && localStorage.getItem('token')) ? true : false,
    token: (localStorage.getItem('token')) ? localStorage.getItem('token') : '',
    userData: (localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : [],
}, action) => {
    switch (action.type) {
        case 'LOGIN':
            state = {
                ...state,
                isAuthenticated: true,
                token: action.payload.security_token,
                userData: action.payload.data,
            }
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('token', action.payload.security_token);
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
            break;
        case 'UPDATE_PROFILE':
            state = {
                ...state,
                userData: action.payload.data,
            }
            localStorage.setItem('userData', JSON.stringify(action.payload.data));
            break;
        case 'LOGOUT':
            state = {
                ...state,
                isAuthenticated: false,
                token: "",
                userData: [],
            }
            localStorage.clear();
            break;
        default:
            break;
    }
    return state;
};
export default authReducer