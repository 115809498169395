import React from 'react';
import images from '../../utils/imageHandler/imageHelper';
import CheckoutPage from '../CheckoutPage/CheckoutPage';

const FinanceApplication = (props) => {
    return (
        <section className="application_from">
            <div className="left">
                <a onClick={props.sendToHome}>
                    <img src={images.logoWhite} alt="" />
                </a>
                <div className="content">
                    <p className="primary">{props.title}
                    </p>
                    <p className="white">Application <br />
                        Form</p>
                </div>
                <a onClick={props.sendToHome} className="primary home"><img src={images.home} alt="" /> RETURN TO HOMEPAGE</a>
            </div>
            <div className="right">
                <CheckoutPage form_id={props?.form_id} title={props.title} />
            </div>
        </section>
    )
}

export default FinanceApplication;