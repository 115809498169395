
import { Provider } from 'react-redux';
import store from './redux/store';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { withRouter } from 'react-router-dom';
import Layout from './components/layout';
import Routers from '../src/routes';
import ErrorBoundary from '../src/utils/errorHandler/errorBoundary';
import ProtectedRoutes from './routes/protectedRoutes';
import UserLayout from './components/userLayout';
import { useEffect } from 'react';
import { Socket } from './utils/socket';
import Helper from './utils/apiHelper/apiHelper';

function App(props) {

  useEffect(() => {
    let isAuthenticated = window.localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
      // Socket.makeConnection();
      // Socket.socket.on('notification_response', (notification) => {
      //   Helper.Notification(notification?.data?.message, notification?.data?.title)
      // });
    }
  },[])

  let isAuthenticated = window.localStorage.getItem('isAuthenticated');

  return (
    <Provider store={store}>
      <ErrorBoundary>
        {!isAuthenticated ?
          <Layout>
            <Routers />
          </Layout>
          :
          <UserLayout>
            <ProtectedRoutes />
          </UserLayout>
        }
      </ErrorBoundary>
    </Provider>
  );
}

export default withRouter(App);
