import React from 'react';
import images from '../../utils/imageHandler/imageHelper';

const LoanListDraft = (props) => {
    const { service, amount, loan_title, showDocumentHandler, viewMyFormHandler, form_type_id, loan_request_date, id, addDocuments } = props;
    return (
        <div className="border-0 card mb-4">
            <div className="px-4 py-3 border-bottom">
                <div className="media ">
                    <div className="outer-left mr-3">
                        <div className="leftbox ">
                            <img src={images.sidebarLogo} alt="" />
                            <p className="m-0 fl font-16 primary"> {service}</p>
                            <p className="fb font-16 white"> Application <br />
                                Form
                            </p>
                        </div>

                        <span
                            className="btn1 cursor-pointer"
                            onClick={() => viewMyFormHandler(form_type_id, loan_title)} >
                            <img src={images.eye} /> View Form</span>
                    </div>
                    <div className="media-body">
                        <div className="out">
                            {props.close && <img src={images.close}
                                onClick={() => showDocumentHandler('')} className="close" />}
                            <div className="mb-4 pr-5">
                                <p className="font-18 mb-0">{service}:

                                </p>
                                <p className="font-18 mb-0">{loan_title}.</p>
                            </div>

                            <div className="mb-4 ">
                                <p className="font-18 mb-0">Loan Amount:
                                </p>
                                <p className="font-18 mb-0">{amount}</p>
                            </div>
                            <div className="last_media">
                                <div>
                                    <p className="font-18 mb-0">Application Date Start:
                                    </p>
                                    <p className="font-18 mb-0">{loan_request_date?.split(' ')[0]}</p>
                                </div>

                                {props.statusPreApplication && <div>
                                    <div className="status_pre">
                                        <button type="button" className=" btn-blue-border datahover"
                                            data-hover="We're waiting for you to upload your required documentation. ">
                                            <span> STATUS: PRE-APPLICATION</span>

                                            <img src={images.info} alt="" />
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <a className="btn1 "
                            onClick={() => viewMyFormHandler(form_type_id, loan_title)} >
                            EDIT / UPDATE FORM</a>
                    </div>
                </div>
                {addDocuments && <div className="mt-5 mb-4">
                    <button type="button" onClick={() => showDocumentHandler(id)} className="add-section "> <img src={images.add} alt="" />
                        <span>ADD YOUR REQUIRED DOCUMENTS FOR THIS LOAN
                        </span>
                    </button>
                </div>}

            </div>
        </div>
    )
}
export default LoanListDraft;