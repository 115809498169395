import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { withRouter } from 'react-router-dom';
import LeftLayout from '../preLogin/leftLayout';
import { login } from '../../redux/actions/authActions';
import { hideHeader } from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { baseUrl } from '../../Config';

const ValidateMobile = (props) => {
    const [mobile, setMobile] = useState('');
    const [country_code, setCountryCode] = useState('');
    const [userData, setUserData] = useState({});

    useEffect(() => {
        props.isHeader(false)
        setUserData(props?.location?.state)
        const mobile = props?.location?.state?.data?.mobile;
        const country_code = props?.location?.state?.data.country_code;
        setMobile(mobile);
        setCountryCode(country_code);
    }, [])

    const validate = values => {
        const errors = {};
        if (!values.otp) {
            errors.otp = 'OTP is required';
        } else if (!/^[0-9]{4}$/.test(values.otp)) {
            errors.otp = 'OTP must be number only and must be of 4 digits';
        }
        return errors;
    }

    return (
        <div className="auction-finance-Application-Form">
            <section className="application_from">
                <LeftLayout title="Registration" />
                <div className="right">
                    <div className="steps_wizard">
                        <Formik
                            initialValues={{ otp: '' }}
                            validate={validate}
                            onSubmit={(values, { setSubmitting }) => {
                                let data = {
                                    otp: parseInt(values.otp),
                                    mobile: parseInt(mobile),
                                    country_code: parseInt(country_code)
                                }
                                let formData = ApiHelper.renderFormData(data)
                                ApiHelper.makeRequest('verify-otp', formData).then((res) => {
                                    if (res.status === 'true') {
                                        ApiHelper.Alert(res.message, 'success');
                                        // props.login(userData);
                                        props.isHeader(false)
                                        if (props.history) props.history.push(baseUrl + "/login");
                                    } else {
                                        ApiHelper.Alert(res.message, 'error');
                                    }
                                    setSubmitting(false);
                                })
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => {
                                return (
                                    <form id="regForm" onSubmit={handleSubmit}>
                                        <div className="tab">
                                            <div className="form_heading  jcc">MOBILE VALIDATION:</div>

                                            {/* <a href="#" className="primary font-13 fr tdu ">You can view our privacy policy here.</a> */}

                                            <div className="align-step">
                                                <div className="fields">
                                                    <div className="form-group">
                                                        <label className="font-18 "> Enter OTP</label>
                                                        <input
                                                            type="text"
                                                            name="otp"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.otp} />
                                                        <span className="hint"> {errors.otp && touched.otp && errors.otp}</span>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn1" disabled={isSubmitting}>SUBMIT </button>
                                                </div>
                                                <p className="gray-300 font-14 fr">Note:
                                                    We have sent an email verification link to your email address to verify your email address.</p>

                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>

                </div>
            </section>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    isHeader: (data) => dispatch(hideHeader(data)),
    login: (data) => dispatch(login(data)),
});
export default withRouter(connect(null, mapDispatchToProps)(ValidateMobile));