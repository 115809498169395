import React from "react";
import Header from "./header";
import Footer from "./footer";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

function Layout(props) {
    const handleLogoutData = () => {
    };
    const { auth, isHeader } = props;
    return (
        <>
            <div className="layout">
                {(isHeader.header || auth.isAuthenticated) && <Header handle={() => handleLogoutData()} />}
                <div className="main-part-home">
                    {props.children}
                </div>
                {(isHeader.header || auth.isAuthenticated) && < Footer />}
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
    isHeader: state.commonReducer
});

export default withRouter(connect(mapStateToProps)(Layout));