import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FinanceSolutionHome from '../containers/financeSolutionHome';
import Home from '../containers/home';
import EligibilityCalculator from '../containers/eligibilityCalculator';
import ApplicationForm from '../containers/applicationForm';
import Register from '../components/register';
import Login from '../components/login';
import ForgetPassword from '../components/forgetPassword';
import ResetPassword from '../components/resetPassword';
import SendOtp from '../components/twoFactorAuthentication/sendOtp';
import VerifyOtp from '../components/twoFactorAuthentication/verifyOtp';
import { baseUrl } from '../Config';
import ValidateMobile from '../components/twoFactorAuthentication/validateMobile';
import StaticPage from '../components/staticPage';
import pages from '../components/staticPage/staticPages';

const Router = () => {
    return (
        < Switch >
            <Route exact path={baseUrl + "/"} component={Home} />
            <Route exact path={baseUrl + "/login"} component={Login} />
            <Route exact path={baseUrl + "/forget-password"} component={ForgetPassword} />
            <Route exact path={baseUrl + "/register"} component={Register} />
            <Route exact path={baseUrl + "/reset/:code"} component={ResetPassword} />
            <Route exact path={baseUrl + "/authentication/otp"} component={SendOtp} />
            <Route exact path={baseUrl + "/authentication/verify"} component={VerifyOtp} />
            <Route exact path={baseUrl + "/validate-mobile"} component={ValidateMobile} />
            <Route exact path={baseUrl + "/finance"} component={FinanceSolutionHome} />
            <Route exact path={baseUrl + "/eligibility-calculator"} component={EligibilityCalculator} />
            <Route exact path={baseUrl + "/application-form"} component={ApplicationForm} />
            {pages.map(page => (
                <Route exact path={baseUrl + page.path} component={StaticPage} />
            ))}

            <Redirect exact path="*" to={baseUrl + "/"} />
        </Switch >
    );
}

export default Router;
