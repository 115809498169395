import React from "react";

function QuickCompletions(props) {
    return (
        <section className="Completions">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 tc">
                        <h4 className="heading_with_border white fr font-28">Quick Completions</h4>
                        <p className="white font-16">Capatool Financial is ideal for sourcing the perfect auction finance,
                        development finance, development exit finance, BTL bridge/term loans, land loans, second charge
						bridge/term loans for your exact needs.</p>
                        <a href="#" className="ls font-12 white">SIGN UP FOR FREE</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QuickCompletions;