import React from 'react';
import { IMAGE_URL } from '../../Config';
import images from '../../utils/imageHandler/imageHelper';

const AddMoreUpload = (props) => {
    const { title, description, multiple, imageSelectHandler, deleteHandler, form_type_id, loan_request_id, field_id, value, field_type, slug } = props;
    return (
        <div className="px-4 py-3   border-bottom">
            <div className="row">

                <div className=" form-group col-lg-9 col-md-9">
                    <span className="font-18 ">{title}

                    </span>
                    <p className="font-18 mt-2 mb-0 primary">
                        {description}</p>
                </div>

                <div className=" form-group col-lg-3 col-md-4 text-md-right">
                    {value.length > 0 ? <div className=" form-group col-md-12">
                        {value.map((val, i) => (
                            <div className="small_id mb-2">
                                <embed src={IMAGE_URL + val?.photo} className="mr-2 w-100 " />

                                <img
                                    src={images.close}
                                    className="close cursor-pointer"
                                    height="25"
                                    onClick={() => deleteHandler(val?.id)} />
                            </div>
                        ))}
                    </div> :
                        <button className="border-0 btn-blue uploadfile ls mb-2">
                            <input
                                type="file" id={field_id}
                                onChange={(e) =>
                                    imageSelectHandler(e, form_type_id, loan_request_id, field_id, title, field_type, slug)} /> UPLOAD FILE
                        </button>}
                    {multiple && <button type="button" className="uploadfile add-section add-section-gray ">
                        <img src={images.add2} alt="" />
                        <span>UPLOAD MORE FILES
                        </span>
                        <input
                            type="file"
                            id={field_id}
                            onChange={(e) => imageSelectHandler(e, form_type_id, loan_request_id, field_id, title, field_type, slug)} /> 
                    </button>}

                </div>
            </div>
        </div >
    );
}

export default AddMoreUpload;