
import React from 'react';

function UploadedID(props) {
    const { type } = props;
    return (
        <div className="px-4 py-3   border-bottom">
            <div className="row">
                <div className=" form-group col-md-9">
                    <span className="font-18 ">Photo or Scan of Your Picture ID Document - <b>{type}</b>
                    </span>
                    <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                </div>
                <div className=" form-group col-md-3 text-md-right">
                    <button className=" btn-blue-border"> PENDING CHECKS</button>
                    <p className="mt-2 text-md-left text-blue"><b>Thank you</b> - Your ID has been
                        submitted for verification by the
                        Capatool Financial team.
                        Please check again shortly.</p>
                </div>
            </div>
        </div>);
}

export default UploadedID;
