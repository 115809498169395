import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
const OtpModal = (props) => {
    const { onChange, otp, error, submitHandler, closeHandler, resendHandler, ...rest } = props;
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <span>Verify mobile number</span>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group p-5">
                    <label htmlFor="" className="font-16"> OTP <sup className="primary mandatory">*</sup></label>
                    <input
                        type="number"
                        name="otp"
                        className="form-control"
                        onChange={onChange}
                        value={otp}
                    />
                    <span className="hint">
                        {error}
                    </span>
                </div>
                <div className="">
                    <span className="opt-not-received">didn't receive opt? &nbsp;</span>
                    <span className="opt-resend" onClick={resendHandler}>Resend otp</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="opt-cancel ml-1 btn1 py-2 px-3" onClick={closeHandler}>CANCEL</button>
                <button className="ml-1 btn1 py-2 px-3" onClick={submitHandler} >SUBMIT</button>
            </Modal.Footer>
        </Modal>
    );
}

export default OtpModal