
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { baseUrl, IMAGE_URL } from '../../Config';
import Helper from '../../utils/apiHelper/apiHelper';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';

function Valuations(props) {
    const { user, history } = props;
    const [loan, setLoanProposals] = useState([]);
    const [loan_amount, setNetLoanAmount] = useState('');
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        setTitle(history?.location?.state?.title);
        setId(history?.location?.state?.id);
        getLenders();
    }, [])

    const getLoanProposals = (lender_id) => {
        let formData = Helper.renderFormData({ request_id: id, valuer_id: lender_id })
        Helper.makeRequest('pick-the-valuer', formData).then((res) => {
            if (res.status === 'true') {
                Helper.Alert(res?.message, "success");
                getLenders();
            } else {
                Helper.Alert(res?.message, "error");
            }
        })
    }

    const getLenders = () => {
        let formData = Helper.renderFormData({ request_id: history?.location?.state?.id })
        Helper.makeRequest('get-valuer-list', formData).then((res) => {
            if (res?.status === 'true') {
                setLoanProposals(res?.data)
                setNetLoanAmount(res?.data?.amount)
            }
        })
    }
    const viewValuationReport = (request_id) => {
        if (history) {
            history.push({ pathname: baseUrl + "/view-valuation", state: { id: request_id } });
        }
    }
    return (
        <div className="portal-right-wrapper">
            <div class="container">
                {loan?.length > 0 ? <> <h6 class="primary font-11 ttu ls">Indicative {title}</h6>
                    <h2 class="heading_with_border font-28 black">{loan?.length} Valuer we
                        could arrange for you:</h2>
                    <p class="mb-0 font-16 black"><strong class="fb">Great news!</strong> We have lenders who could the valuation for your project.</p>
                    <p class="mb-0 font-16 black">We've outlined these indicative packages below for you. Click your
                        preferred option below to see more details:</p>

                    <div class="table-responsive d-none d-md-block">
                        <table class="table table-bordered packages-table bg-white">
                            <thead>
                                <tr>
                                    <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">VALUER
                                    </th>
                                    <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">EMAIL
                                    </th>
                                    <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">PHONE</th>
                                    <th scope="col" class="font-16 primary fb ttu text-left ls4 border-bottom-0">Fees*
                                    </th>
                                    <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loan.length > 0 && loan.map((item, index) => {
                                    return (<tr key={index}>
                                        <th scope="row">
                                            <table className="w-100">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '60px', paddingRight: '20px' }}><img
                                                            src={item?.lender_data?.profile_picture != null ? IMAGE_URL + item?.profile_picture : images.favicon} alt="" /></td>
                                                        <td>
                                                            <h6 className="font-15 fb ttu mb-0 ls">VALUER</h6>
                                                            <h3 className="font-22 fb ttu mb-0 primary">{item?.name}</h3>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </th>
                                        <td>{item?.email}</td>
                                        <td>{item?.country_code}{item?.mobile}</td>
                                        <td>{item?.commission}</td>
                                        <td>
                                            {!item?.is_picked_for_valuation ? <button
                                                type="button"
                                                onClick={() => getLoanProposals(item.id)}
                                                class="btn1 w-100 d-block text-center ttu font-12 ls">
                                                Select For Valuation</button> :
                                                !item?.is_valuation_done ? <h6 className="font-15 fb ttu mb-0 ls"> Already Asked</h6> :
                                                    <button
                                                        type="button"
                                                        onClick={() => viewValuationReport(id)}
                                                        class="btn1 w-100 d-block text-center ttu font-12 ls">
                                                        View Valuation Report</button>
                                            }
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="mobile_custom_table">
                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                    </div> */}
                </> :
                    <p class="mb-0 font-16 black" style={{ height: '400px' }}><strong class="fb">Sorry!</strong> We have no Valuer who could oﬀer you
                        the {title} for your project.<br /> Please wait for some time....</p>}
            </div>
            <WhiteFooter />
        </div >
    );
}

export default withRouter(Valuations);
