import React, { useState, useEffect } from 'react';
const Radio = (props) => {
    const { label, options, name, onBlur, touched, errors, handleChange, value } = props;
    const [error, setError] = useState('');
    // console.log('options', name)
    useEffect(() => {
        if (errors && errors[name] && touched && touched[name]) {
            setError(label + ' ' + errors[name])
        } else {
            setError('')
        }
    })
    return (
        <div style={{
            marginBottom: '40px',
        }}>
            <div className="checkbox_outer mt-5">
                <p className="font-16"> {label}</p>
                {options.length > 0 && options.map((item, index) => {
                    return (
                        <div className="form-group custom_checkbox">
                            <input
                                name={name}
                                id={item?.id}
                                type="radio"
                                onChange={handleChange}
                                onBlur={onBlur}
                                checked={item?.option_value == value}
                                value={item?.option_value}
                            />
                            <label htmlFor={item?.id}>{item?.option_value}</label>
                        </div>
                    )
                }
                )}
            </div>
            {error && < span className="hint">
                {error}
            </span>}
        </div>

    );
}
export default Radio;
