import React from 'react';
import images from '../../../utils/imageHandler/imageHelper';
const CarouselLayout = () => {
    return (
        <div className="slider1">
            <div className="item">
                <div className="card">
                    <div className="card-header">
                        <img src={images.rating} alt="" /></div>
                    <div className="card-body">

                        <div className="media">
                            <div className=" media-middle">
                                <img src={images.user} alt="" className="media-object" />
                            </div>
                            <div className="media-body">
                                <h4 className="font-13 gray-800 "> sophie kane </h4>
                                <img src={images.edit} alt="" className="media-object" />
                                <span className="font-13 gray-200">1
                        review</span>
                            </div>
                        </div>
                        <div className="df">
                            <div>
                                <img src={images.start} alt="" />
                                <img src={images.start} alt="" />
                                <img src={images.start} alt="" />
                                <img src={images.start} alt="" />
                                <img src={images.start} alt="" />
                            </div>
                            <div className="font-13 gray-200">16 Jul 2020</div>
                        </div>
                        <h3 className="gray-800 fb font-14">Highly recommended</h3>

                        <p className="black font-12">Highly recommended, the Capatool Financial team were on hand
                        to
                        support and guide us in our application. This company went above and
                        beyond. Thank you for a smooth transaction.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselLayout