import React from 'react';

const LinkAgency = (props) => {
    const { title, description, other, form_type_id, loan_request_id, field_id, field_type, slug, submitHandler, handleChange, value } = props;

    return (
        <div className="px-4 py-3   border-bottom search_part">
            <div className="row">
                <div className=" form-group col-md-12">
                    <span className="font-18 ">Link to Online Agency to Show the Property on The Market</span>
                    <p className="font-18 mt-2 mb-0 primary">
                        For example Zoopla, Rightmove, Savills, etc.</p>

                    <div className="df">
                        <input
                            type="text"
                            className="form-control "
                            placeholder="Enter link here..."
                            onChange={(e) => handleChange(e, field_type, slug)}
                            value={other}
                        />
                        <button
                            className="border-0 btn-blue uploadfile ls mb-2 submitbtn2"
                            onClick={() => submitHandler(form_type_id, loan_request_id, field_id, slug,'link')}  > SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LinkAgency;