
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import images from '../../utils/imageHandler/imageHelper';
import SolutionsTypes from '../home/solutionsTypes';
import UserPortalFooter from '../userLayout/footer';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import Helper from '../../utils/apiHelper/apiHelper';
import { baseUrl } from '../../Config';
import FinanceSolutionType from '../financeSolutionHome/financeSolutionType';

function UserPortalHome(props) {

    const { history, user, hideHeader } = props;
    const [services, setServices] = useState([]);
    const [id, setID] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        setID(props?.location?.state?.id);
        setTitle(props?.location?.state?.title)
    }, [])

    const handleEligibilityCalculator = () => {
        if (history) history.push({ pathname: baseUrl + "/calculator", state: { title: title, id: id } });
    }

    const handleApplyLoan = () => {
        if (history) history.push({ pathname: baseUrl + "/form", state: { title: title, id: id } });
    }

    return (
        <div className="user-portal-content portal-home">
            <section className="residential-investment-bridge finance-user-portal">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <h4 className="heading_with_border white fr font-28">{props?.location?.state?.title} </h4>
                            <p className="white fr font-16"><b>Auction Finance is a Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit,
                                sed diam nonummy nibh.</b> Ut wisi enim ad minim veniam, quis nostrud exerci tation
                                ullamcorper
                                suscipit
                                lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in
                                hendrerit in
                                vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero
                                eros
                                et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit. Start
                                finding your
                                best lending solutions below...</p>
                        </div>
                        <div className="col-lg-10 ">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="media cursor-pointer" onClick={handleEligibilityCalculator}>
                                        <div className=" media-middle img1"></div>
                                        <div className="media-body">
                                            <p className="font-11 yellow ttu"> ELIGIBILITY<br />CALCULATOR
                                            </p>
                                            <p className="font-11 white ttu">
                                                {props?.location?.state?.title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="media cursor-pointer" onClick={handleApplyLoan}>
                                        <div className=" media-middle img2"> </div>
                                        <div className="media-body">
                                            <p className="font-11 yellow ttu"> APPLY FOR
                                            </p>
                                            <p className="font-11 white ttu">
                                                {props?.location?.state?.title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 pt-5">
                            <button type="button" className="btn1 mb-3">NEED HELP? CONTACT US</button>

                            <p className="white fb font-16 mb-0">We take the fuss out of finance and make lending super simple.
                            </p>
                            <p className="white fr font-16">We’ll deliver the very best Auction Finance lending solutions direct
                                to your inbox.</p>
                        </div>
                    </div>
                </div>
            </section>
            <UserPortalFooter />
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPortalHome));
