
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Description from '../../components/eligibilityCalculator/description';
import EligibilityCalculatorBanner from '../../components/eligibilityCalculator/eligibilityCalculatorBanner';
import IndicativeAuctionFinance from '../../components/eligibilityCalculator/indicativeAuctionFinance';
import LoanDetails from '../../components/eligibilityCalculator/loanDetails';
import FAQ from '../../components/financeSolutionHome/faq';
import LendingOptions from '../../components/home/lendingOptions';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';

function EligibilityCalculator(props) {
    const { header } = props;
    useEffect(() => {
        header(true);
    }, [])


    return (

        <div className="auction-finance-home-eligibility-calculator">
            <EligibilityCalculatorBanner />
            <LoanDetails />
            <IndicativeAuctionFinance />
            <Description />
            <FAQ />
            <LendingOptions />
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    header: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EligibilityCalculator));