
import React from 'react';
import images from '../../utils/imageHandler/imageHelper';
import UploadedID from './uploadedID';

function IDUpload(props) {
    const { selectTypeHandler, imageSelectHandler, type, documentData, onClickImageUpload } = props;
    return (
        <div className="border-0 card mb-5">
            <div className="p-4 df jcb border-bottom">
                <b className=" font-18">ID Verification Required for ALL Loans: </b>
            </div>
            <div className="px-4 py-3 border-bottom">
                <div className="row">
                    <div className=" form-group col-md-6">
                        <span className="font-18 ">Document Verification

                        </span>
                        <p className="font-18 mt-2 mb-0 primary"> Choose your ID type for us to verify.</p>
                    </div>

                    <div className=" form-group col-md-6 text-md-right">

                        <select className="form-control custom_select2 ls" value={type} onChange={selectTypeHandler}>
                            <option value="" className="ttu"> PLEASE SELECT... </option>
                            <option value="Driving License" className="ttu"> Driving License</option>
                            <option value="Passport" className="ttu">Passport</option>
                        </select>
                    </div>
                </div>
            </div>
            {!documentData.identity_front ?
                <div className="px-4 py-3   border-bottom">

                    <div className="row">
                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo or Scan of Your Picture ID Document - <b>Front</b>
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>

                        <div className=" form-group col-md-6 text-md-right">
                            <label htmlFor="iduploadfront">
                                <span htmlFor="iduploadfront" className="border-0 btn-blue uploadfile cursor-pointer"> ID UPLOAD - FRONT</span>
                            </label>
                            <input id="iduploadfront" type="file" onClick={() => onClickImageUpload('identity_front')} onChange={(e) => imageSelectHandler(e, 'identity_front')} className="imgUpload" />
                        </div>

                        <div className="col-md-12">
                            {/* <!-- ######### card --> */}
                            <div className="card rounded-0">
                                <div className="rounded-0 card-header bg_primary white ls4">
                                    <img style={{ width: '25px' }}
                                        src={images.check3} className="mr-2" /> HOW
                                    TO
                                    UPLOAD A GOOD ID EXAMPLE:</div>

                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-5 ">
                                            <img src={images.id11} className="mr-2 w-100" />

                                            <div className=" custom_checkbox mt-3">
                                                <input type="checkbox" id="check-111" defaultChecked />
                                                <label htmlFor="check-111" className="font-16 fb ttu ">THIS IS A <b
                                                    className="primary mx-2">
                                                    GOOD </b>
                                                    EXAMPLE</label>

                                            </div>

                                        </div>
                                        <div className="col-md-7 text-left">
                                            <p className="font-16 fb">A <b className="primary">GOOD</b> example clearly shows:
                                            </p>

                                            <ul className="example-data">
                                                <li className="font-14"> ID document fully in frame</li>
                                                <li className="font-14"> A colour photo, not black & white </li>
                                                <li className="font-14"> Text is clearly visible </li>
                                                <li className="font-14"> Minimum background space</li>
                                                <li className="font-14"> ID is valid and in date</li>
                                            </ul>

                                            <p className="font-14"><b>The image should NOT be edited, resized or rotated.</b>
                                                <br />
                                                The file uploaded must be .png or .jpg under 7MB in size.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                :
                <UploadedID title="Photo or Scan of Your Picture ID Document" type="Front" />}
            {!documentData.identity_back ?
                <div className="px-4 py-3   border-bottom">

                    <div className="row">

                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo or Scan of Your Picture ID Document - <b>Back</b>
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>

                        <div className=" form-group col-md-6 text-md-right">
                            <label htmlFor="iduploadback">
                                <span htmlFor="iduploadback" className="border-0 btn-blue uploadfile cursor-pointer"> ID UPLOAD - back</span>
                            </label>
                            <input id="iduploadback" type="file" onClick={() => onClickImageUpload('identity_back')} onChange={(e) => imageSelectHandler(e, 'identity_back')} className="imgUpload" />
                        </div>
                    </div>

                    {/* <!-- ######### card --> */}
                    <div className="card rounded-0">


                        <div className="rounded-0 card-header bg_primary white ls4"> <img style={{ width: '25px' }}
                            src={images.check3} className="mr-2" /> HOW TO UPLOAD A GOOD BACK OF ID EXAMPLE:</div>

                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-5 ">
                                    <img src={images.id22} className="mr-2 w-100" />

                                    <div className=" custom_checkbox mt-3">
                                        <input type="checkbox" id="check-112" defaultChecked />
                                        <label htmlFor="check-112" className="font-16 fb ttu ">THIS IS A <b className="primary mx-2">
                                            GOOD </b>
                                            EXAMPLE</label>

                                    </div>

                                </div>
                                <div className="col-md-7 text-left">
                                    <p className="font-16 fb">A <b className="primary">GOOD</b> example clearly shows:
                                    </p>

                                    <ul className="example-data">
                                        <li className="font-14"> The reverse face of the ID document</li>
                                        <li className="font-14"> ID document fully in frame
                                        </li>
                                        <li className="font-14"> A colour photo, not black & white
                                        </li>
                                        <li className="font-14"> Text is clearly visible
                                        </li>
                                        <li className="font-14"> Minimum background space</li>
                                    </ul>

                                    <p className="font-14"><b>The image should NOT be edited, resized or rotated.</b> <br />
                                        The file uploaded must be .png or .jpg under 7MB in size.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                :
                <UploadedID title="Photo or Scan of Your Picture ID Document" type="Back" />}
            {!documentData.photo ?
                <div className="px-4 py-3   border-bottom">

                    <div className="row">

                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo (Selfie) of You Holding Your Picture ID Document
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>
                        <div className=" form-group col-md-6 text-md-right">
                            <label htmlFor="iduploadphoto">
                                <span htmlFor="iduploadphoto" className="border-0 btn-blue uploadfile cursor-pointer">  PHOTO UPLOAD</span>
                            </label>
                            <input id="iduploadphoto" type="file" onClick={() => onClickImageUpload('photo')} onChange={(e) => imageSelectHandler(e, 'photo')} className="imgUpload" />
                        </div>
                    </div>

                    {/* <!-- ######### card --> */}
                    <div className="card rounded-0">
                        <div className="rounded-0 card-header bg_primary white ls4"> <img style={{ width: '25px' }}
                            src={images.check3} className="mr-2" /> HOW TO UPLOAD A GOOD ID & SELFIE EXAMPLE:</div>

                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-5 ">
                                    <img src={images.id33} className="mr-2 w-100" />

                                    <div className=" custom_checkbox mt-3">
                                        <input type="checkbox" id="check-113" defaultChecked />
                                        <label htmlFor="check-113" className="font-16 fb ttu ">THIS IS A <b className="primary mx-2">
                                            GOOD </b>
                                            EXAMPLE</label>

                                    </div>

                                </div>
                                <div className="col-md-7 text-left">
                                    <p className="font-16 fb">A <b className="primary">GOOD</b> selfie example clearly shows:
                                    </p>

                                    <ul className="example-data">
                                        <li className="font-14"> You holding your photo ID
                                        </li>
                                        <li className="font-14"> The image of your ID must be clear </li>
                                        <li className="font-14"> Your image must be clear and not blurred </li>

                                        <li className="font-14"> We must be able to facially verify that you and <br /> the photo
                                            ID are the same person
                                        </li>
                                    </ul>

                                    <p className="font-14"><b>The image should NOT be edited, resized or rotated.</b> <br />
                                        The file uploaded must be .png or .jpg under 7MB in size.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                :
                <UploadedID title="Photo (Selfie) of You Holding Your Picture ID Document" type="" />}
        </div>
    );
}

export default IDUpload;
