
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { baseUrl, IMAGE_URL } from '../../Config';
import Helper from '../../utils/apiHelper/apiHelper';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';

function ViewValuations(props) {
    const { user, history } = props;
    const [loan, setLoanProposals] = useState([]);
    const [loan_amount, setNetLoanAmount] = useState('');
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        setTitle(history?.location?.state?.title);
        setId(history?.location?.state?.id);
        getLenders();
    }, [])


    const getLenders = () => {
        let formData = Helper.renderFormData({ request_id: history?.location?.state?.id })
        Helper.makeRequest('get-valuation-reports', formData).then((res) => {
            if (res?.status === 'true') {
                setLoanProposals(res?.data)
                setNetLoanAmount(res?.data?.amount)
            }
        })
    }

    return (
        <div className="portal-right-wrapper">
            <div class="container">
                {/* {loan?.length > 0 ? <>  */}
                <h4 className=" black fr  font-28 ttc">{Helper.getGreetings()}  {props?.user?.name}.</h4>
                {/* <h2 class="heading_with_border font-28 black">Valuation has been done by the valuer</h2> */}
                <p class="mb-0 font-16 black"><strong class="fb">Great news!</strong> We have generated valuation report for your project</p>
                {/* <p class="mb-0 font-16 black">We've outlined these indicative packages below for you. Click your
                        preferred option below to see more details:</p> */}

                <div class="table-responsive d-none d-md-block">
                    <div className="loan-details-right ml-md-auto mx-auto">
                        <div className="loan-details-block">
                            <h2 className="font-28 black">Auction Finance Valuation Report</h2>
                            <ul className="list">
                                <li className="d-flex font-16 black">Service<span
                                    className="ml-auto pl-4">{loan?.service_name}</span></li>
                                <li className="d-flex font-16 black">Lender<span
                                    className="ml-auto pl-4">{loan?.lender_data?.name}</span></li>
                                <li className="d-flex font-16 black">Valuer<span
                                    className="ml-auto pl-4">{loan?.valuer_data?.name}</span></li>
                            </ul>
                            <ul className="list">
                                <li className="d-flex font-16 black">PII Level(Processing Fee)<span
                                    className="ml-auto pl-4">£{loan?.processing_fee}</span></li>

                                <li className="d-flex font-16 black"> SLA(Number of Days)<span
                                    className="ml-auto pl-4">{loan?.sla}</span></li>
                                <li className="d-flex font-16 black">Distance<span
                                    className="ml-auto pl-4">{loan?.distance}</span></li>
                                <li className="d-flex font-16 black">PII Expiry(Expected Renewal Date)<span
                                    className="ml-auto pl-4">{loan?.expected_renewal_date?.split(' ')[0]}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="mobile_custom_table">
                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                        <div className="table_box">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                    <div className="media">
                                        <img src={images.favicon} alt="" />
                                        <div className="media-body">
                                            <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                            <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <a href="auction-finance-Application-Form.html"
                                        className="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a>
                                </div>



                            </div>

                            <div className="row mt-2">

                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                    <p className="font-14 mb-0 ">£250K</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                    <p className="font-14 mb-0 ">12%</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                    <p className="font-14 mb-0">24 MONTHS</p>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                    <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                    <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                                </div>
                                <div className="col-4">
                                    <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                        AMOUNT</h3>
                                    <p className="font-14 mb-0"> £250K</p>
                                </div>
                            </div>



                        </div>

                    </div> */}
            </div>
            <WhiteFooter />
        </div >
    );
}

export default withRouter(ViewValuations);
