import React from 'react';
function CopyRight(props) {
    const { privacyPolicyClickHandler } = props;
    return (
        <div className="copyrights">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <p className="m-0 white font-12">Capatool Financial is a trading name of Capatool Wealth Ltd which is
                            authorised and regulated by the
                            financial conduct authority. Our FCA number is 929391. You can check this on the financial
                            services
                            register by visiting the FCA Website www.fca.org.uk/Register Capatool Financial’s registered
                            office is
                            Clarence Street Chambers, 32 Clarence Street, Southend-On-Sea, England, SS1 1BD.</p>
                    </div>
                    <div className="col-lg-4">
                        <p className="m-0 white font-12 tr">© 2020 Capatool Financial.
                            <a onClick={privacyPolicyClickHandler}>Privacy Policy</a> <br />
                            Website Design by
                            <a >Ever Increasing Circles</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyRight