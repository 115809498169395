import React from 'react';
import { withRouter } from 'react-router-dom';
import DraftForms from '../../components/userDrafts/draftForms';
import { connect } from 'react-redux'

function UserDraft(props) {
    const { user } = props;
    return (
        <div className="user-portal-home">
            <DraftForms user={user?.userData} />
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(UserDraft));

