import React from 'react';
import { withRouter } from 'react-router-dom';
import UserPortalHome from '../../components/userPortalHome/userPortalHome';
import { connect } from 'react-redux'

function UserPortal(props) {
    const { user } = props;

    return (
        <div className="user-portal-home">
            <UserPortalHome user={user?.userData} />
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(UserPortal));