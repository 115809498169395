import React, { useState, useEffect } from 'react';

const Input = (props) => {
    const { label, placeholder, type, name, onBlur, touched, errors, handleChange, value } = props;
    const [error, setError] = useState('');
    useEffect(() => {
        if (errors && errors[name] && touched && touched[name]) {
            setError(label + ' ' + errors[name])
        } else {
            setError('')
        }
    })
    return (
        <div className="form-group">
            <label htmlFor="" className="font-16"> {label}</label>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                className="form-control "
                onChange={handleChange}
                onBlur={onBlur}
                value={value} />
            {error && < span className="hint">
                {error}
            </span>}
        </div >

    );
}
export default Input;
