import React, { useEffect } from 'react';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { hideHeader } from '../../redux/actions/commonActions';
import LeftLayout from '../preLogin/leftLayout';
import { baseUrl } from '../../Config';

const ResetPassword = (props) => {
    const { history } = props;
    const { code } = useParams();

    useEffect(() => {
        props.hideHeader(false)
    }, [])
    const validate = values => {
        const errors = {};
        if (!values.password) {
            errors.password = 'Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.password)) {
            errors.password = 'Must be alphanumeric, be at least 8 characters long and contain at least one special character';
        }
        if (!values.confirm_password) {
            errors.confirm_password = 'Confirm Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.confirm_password)) {
            errors.confirm_password = 'Must be alphanumeric, be at least 8 characters long and contain at least one special character';
        }

        if (values.password != values.confirm_password) {
            errors.confirm_password = 'Password and confirm password should be same.';
        }
        return errors;
    }

    return (
        <div className="auction-finance-Application-Form">
            <section className="application_from">
                <LeftLayout title="Reset Password" />
                <div className="right">
                    <div className="steps_wizard">
                        <Formik
                            initialValues={{ password: '', confirm_password: '' }}
                            validate={validate}
                            onSubmit={(values, { setSubmitting }) => {
                                let formData = ApiHelper.renderFormData({ ...values, code: code })
                                ApiHelper.makeRequest('reset-forgot', formData).then(async (res) => {
                                    if (res.status === 'true' || res.status) {
                                        ApiHelper.Alert(res.message, 'success');
                                        if (history) {
                                            await history.push(baseUrl + "/login");
                                        }
                                    } else {
                                        ApiHelper.Alert(res.message, 'error');
                                    }
                                    setSubmitting(false);
                                })
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => {
                                return (
                                    <form id="regForm" onSubmit={handleSubmit}>
                                        <div className="tab">

                                            <div className="form_heading  jcc">RESET PASSWORD FORM:</div>

                                            {/* <p className="black font-18">Don't Have an Account?</p> */}
                                            {/* <a onClick={onRegisterHandle} className="db btn1 mb-5"> Register Now</a> */}

                                            <p className="black font-13 fr mb-1">All the following registration pages are skippable, but
                                            this one is
                                            required in order to
                                            proceed with initial registration. We're going to be asking you some questions that will
                                            include some information about your finance needs. Let's keep that info safe and secure
                                            by creating your free account.</p>

                                            <a href="#" className="primary font-13 fr tdu ">You can view our privacy policy here.</a>

                                            <div className="fields">
                                                <div className="form-group">
                                                    <label className="font-18"> Password </label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password} />
                                                    <span className="hint"> {errors.password && touched.password && errors.password}</span>
                                                </div>

                                                <div className="form-group">
                                                    <label className="font-18"> Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        name="confirm_password"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.confirm_password} />
                                                    <span className="hint">{errors.confirm_password && touched.confirm_password && errors.confirm_password}</span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn1" disabled={isSubmitting}>SUBMIT </button>
                                            </div>
                                        </div>
                                    </form>

                                )
                            }}
                        </Formik>

                    </div>
                </div>
            </section>
        </div>
    );
} 

const mapStateToProps = state => ({
    user: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));