import React from 'react';
import { withRouter } from 'react-router';
import Chat from '../../components/liveChat/chat';

function LiveChat() {

    return (
        <div className="user-portal-home">
            <Chat />
        </div>
    );
}

export default withRouter(LiveChat);
