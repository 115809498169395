
import React from 'react';
import Documents from '../../components/userDocuments/userDcuments';
import { connect } from 'react-redux'

function UserDocuments(props) {
    const { user } = props;
    return (
        <div className="portal-right-wrapper portal_mydocument ">
            <Documents user={user?.userData} />
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer,
});

export default connect(mapStateToProps)(UserDocuments);
