
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import IntelligentLending from '../../components/home/intelligentLending';
import ChooseLender from '../../components/home/chooseLender';
import CustomerFeedback from '../../components/home/customerFeedback';
import QuickCompletions from '../../components/home/quickCompletions';
import Recommended from '../../components/home/recommended';
import LendingOptions from '../../components/home/lendingOptions';
import FinanceSolutionType from '../../components/financeSolutionHome/financeSolutionType';
import FAQ from '../../components/financeSolutionHome/faq';
import GetStartedFinance from '../../components/financeSolutionHome/getStartedFinance';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { baseUrl } from '../../Config';
import Helper from '../../utils/apiHelper/apiHelper';
import { useState } from 'react';

function FinanceSolutionHome(props) {
    const { match, header } = props;
    const auctionType = match.params;
    const [faq, setFAQ] = useState([]);
    const [id, setID] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        getFaq(props?.location?.state?.id);
        setID(props?.location?.state?.id);
        setTitle(props?.location?.state?.title)
        return () => {
            header(false);
        }
    }, [])
    const getFaq = async (id) => {
        let formData = await Helper.renderFormData({ service_id: id })
        await Helper.makeRequest('get-faq', formData).then(async (res) => {
            if (res.status === 'true') {
                setFAQ(res.data)
            }
        })
    }
    const handleEligibilityCalculator = () => {
        const { history } = props;
        if (history) history.push(baseUrl + "/eligibility-calculator");
    }

    const handleApplyLoan = () => {
        const { history } = props;
        if (history) history.push({ pathname: baseUrl + "/application-form", state: { title: title, id: id } });
    }

    return (
        <div className="auction-finance-home">
            <FinanceSolutionType
                handleEligibilityCalculator={handleEligibilityCalculator}
                handleApplyLoan={handleApplyLoan} />
            <IntelligentLending />
            <CustomerFeedback />
            <ChooseLender />
            <QuickCompletions />
            <Recommended />
            <FAQ data={faq} />
            <LendingOptions />
            <GetStartedFinance />
        </div>
    );
}


const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    header: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinanceSolutionHome));
