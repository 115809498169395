
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import images from '../../utils/imageHandler/imageHelper';
import SolutionsTypes from '../home/solutionsTypes';
import UserPortalFooter from '../userLayout/footer';
import { hideHeader } from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import Helper from '../../utils/apiHelper/apiHelper';
import { baseUrl } from '../../Config';
import FinanceSolutionType from '../financeSolutionHome/financeSolutionType';
import LoanCalculatorForm from '../eligibilityCalculator/loanCalculatorForm';
import IndicativeQuote from '../eligibilityCalculator/indicativeQuote';

function UserPortalEligibilityCalculator(props) {

    const { history, user } = props;

    useEffect(() => {
    }, [])

    return (
        <div className="portal-right-wrapper portal_setting">
            <h4 className=" black fr  font-28 ttc">{Helper.getGreetings()}  {props?.user?.name}.</h4>
            <p className="heading_with_border black font-28 mb-5">Apply for Loan here...</p>

            <b className="font-16"> Here you can Apply for Loan here.</b>
            <p className="black font-16"> From your logins and contact details to your profile image if you wish to include one!
                </p>
            <div className="border-0 card mb-5 loan-form">
            </div>
            {/* <div className="user-portal-content user-portal-loan-details">
                <section className="loan-detail-sec">
                    <div className="container"> */}
            <div className="loan-detail-inner mx-auto">
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <LoanCalculatorForm />
                    </div>
                    <div className="col-lg-6">
                        <IndicativeQuote />
                    </div>
                </div>
            </div>
        </div>
        //     </section >
        // </div >
    )
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPortalEligibilityCalculator));
