import React, { useEffect, useState } from 'react';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import { Socket } from '../../utils/socket';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import ReactTimeAgo from 'react-time-ago'
import Helper from '../../utils/apiHelper/apiHelper';
import dateFormat from "dateformat";
import { IMAGE_URL } from '../../Config';

const Chat = (props) => {
    const [msg, setMsg] = useState('');
    const [thread, setUserThread] = useState([]);
    const [threadLimit, setThreadLimit] = useState(10);
    const [loanId, setLoanId] = useState();
    const [userList, setUserList] = useState([]);
    const [userListSearch, setUserListSearch] = useState([]);
    const [activeUser, setActiveUser] = useState();
    const [userDetails, setActiveUserDetails] = useState('');
    const [searchKey, setSearchKey] = useState('');

    let userData = localStorage.getItem('userData');
    let user = JSON.parse(userData);
    useEffect(() => {
        if (!activeUser) {
            getUserInbox();
        }
    }, [])

    useEffect(() => {
        if (userList.length > 0) {
            window.document.getElementById("input_box")
                .addEventListener("keyup", function (event) {
                    event = event || window.event;
                    event.preventDefault();
                    if (event.keyCode === 13) {
                        document.getElementById("send_btn").click();
                    }
                });
        }
    }, [userList])
    Socket.socket.on('receive_message', (res) => {
        let usrThread = res?.data[0];
        setUserThread([...thread, usrThread]);
    });

    const getUserInbox = async () => {
        const payload = {
            user_id: user.id,
        }
        let formData = ApiHelper.renderFormData(payload)
        await ApiHelper.makeRequest('get-conversations', formData).then(async res => {
            if (res?.status == "true") {
                let data = res?.data;
                if (data.length > 0) {
                    let user_list = data;
                    await setUserList(user_list);
                    if (user_list[0]?.user_id == user.id) {
                        await setLoanId(user_list[0]?.loan_id);
                        await setActiveUser(user_list[0]?.other_user_data);
                        await setActiveUserDetails(user_list[0]?.request_type?.service);
                        await getUserThread(user_list[0]?.other_user_data?.id, user_list[0]?.loan_id);
                        await setUserListSearch(user_list);
                    } else {
                        await setLoanId(user_list[0]?.loan_id);
                        await setActiveUser(user_list[0]?.user_data);
                        await setActiveUserDetails(user_list[0]?.request_type?.service);
                        await getUserThread(user_list[0]?.user_data?.id, user_list[0]?.loan_id);
                        await setUserListSearch(user_list);
                    }
                }
            }
        })

        // await Socket.socket.on('get_inbox', async (res) => {
        // });
    }

    const getUserThread = async (user_id, loan_id) => {
        const data = {
            user_id: user.id,
            other_user_id: user_id,
            limit: 1000,
            loan_id: loan_id,
            last_id: '',
            keyword: "",
            type: 'after'
        }

        await Socket.socket.emit('get_user_thread', data);
        await Socket.socket.on('get_chat_thread_response', async (data) => {
            let user_thread = await data?.data?.reverse();
            await setUserThread(user_thread);
            let objDiv = window.document.getElementById('msg_view');
            objDiv.scrollTop = objDiv.scrollHeight;
        });

        setThreadLimit(threadLimit + 10)
    }

    const onChangeHandler = (e) => {
        setMsg(e.target.value);
    }

    const sendMessage = async (type = 'TEXT', msg, file_name, file_size) => {
        if (!msg.length) {
            return
        }
        let data = {
            message: msg,
            user_id: user.id,
            msg_type: type,
            other_user_id: activeUser?.id,
            loan_id: loanId,
            other_data: {},
            file_name: file_name,
            file_size: file_size,
            created_at: new Date().toISOString()
        }
        await Socket.socket.emit('send_message', data);
        await setUserThread([...thread, data])
        let objDiv = window.document.getElementById('msg_view');
        objDiv.scrollTop = objDiv.scrollHeight;
        await setMsg('');
    }
    const searchChangeHandler = (e) => {
        let key = e.target.value;
        setSearchKey(key);
        let regex = new RegExp(key);
        let result = userListSearch.filter((item) => regex.test(item?.other_user_data?.name))
        if (key == '') {
            getUserInbox();
        } else {
            setUserList(result);
        }

    }
    let date = ''
    const managePrevDate = (newDate) => {
        if (!newDate) {
            return true
        }
        if (date == '') {
            date = newDate.substring(0, 16);
            return false
        }
        if (date != newDate.substring(0, 16)) {
            date = newDate.substring(0, 16);
            return true
        } else {
            return false
        }
        date = newDate.substring(0, 16);

    }

    const imageSelectHandler = (e) => {
        if (e.target.files && e.target.files.length) {
            if (e.target.files[0]?.type != ('image/jpeg' || 'image/jpg')) {
                Helper.Alert('Please select only jpeg or jpg format.', 'error');
                document.getElementById("imgupload-icon").value = "";
                return;
            }
        }
        let fileType = e.target.files[0]?.type.split('/')[0];
        if (fileType == 'image') {
            fileType = "Image";
        } else if (fileType == 'video') {
            fileType = "Video";
        } else if (fileType == 'audio') {
            fileType = "Audio";
        }
        let data = {
            content_type: fileType,
            upload: e.target.files[0]
        }
        let formData = ApiHelper.renderFormData(data)
        ApiHelper.makeRequest('send-message', formData).then(async (res) => {
            if (res?.status === 'true') {
                await setMsg(res?.file)
                await sendMessage('IMAGE', res?.file, res?.file_name, res?.file_size);
            } else {
                ApiHelper.Alert(res?.message, 'error');
            }
        })
    }

    const docSelectHandler = (e) => {
        if (e.target.files && e.target.files.length) {
            if (e.target.files[0]?.type != ('application/pdf' || 'video/mp4' || 'audio/mp3' || 'text/plain')) {
                Helper.Alert('Please select only pdf format.', 'error');
                document.getElementById("imgupload-img").value = "";
                return;
            }
        }
        let fileType = e.target.files[0]?.type.split('/')[0];
        if (fileType == 'image') {
            fileType = "Image";
        } else if (fileType == 'video') {
            fileType = "Video";
        } else if (fileType == 'audio') {
            fileType = "Audio";
        } else {
            fileType = 'Document'
        }
        let data = {
            content_type: 'File',
            upload: e.target.files[0]
        }
        let formData = ApiHelper.renderFormData(data)
        ApiHelper.makeRequest('send-message', formData).then((res) => {
            if (res?.status === 'true') {
                setMsg(res?.file)
                sendMessage('IMAGE', res?.file, res?.file_name, res?.file_size);
            } else {
                ApiHelper.Alert(res?.message, 'error');
            }
        })
    }

    const userClickHndler = (user, data) => {
        setActiveUser(user);
        setLoanId(data?.loan_id);
    }
    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    return (
        <div className="main-panel my-inbox">
            <div className="container">
                {userList.length > 0 ? <>
                    <div className="page-inner">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card listing_c">

                                    <div className="top ">
                                        <form className="navbar-left navbar-form nav-search">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    {/* <button type="submit" className="btn btn-search pr-1">
                                                    <span className="icon-search f30"></span>
                                                </button> */}
                                                </div>
                                                <input type="text" onChange={searchChangeHandler}
                                                    value={searchKey} placeholder="Search" className="form-control f18" />
                                            </div>
                                        </form>
                                    </div>


                                    <ul className="list-unstyled left-nav">
                                        {userList.length > 0 && userList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item?.user_id == user.id ?
                                                        <a className="msg-in active" onClick={() => userClickHndler(item?.other_user_data, item)}>
                                                            <div className="media">
                                                                <img className="mr-2" height={40} src={item?.other_user_data?.profile_picture ? IMAGE_URL + item?.other_user_data?.profile_picture : images.user}
                                                                />
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 fblack f13 ">{item?.other_user_data?.name} - {item?.request_type?.service} </h5>
                                                                    <strong className="f16 fyellow">{item?.other_user_data?.name}</strong>
                                                                </div>
                                                                <span className="fblack f11">
                                                                    <ReactTimeAgo date={new Date(item?.created_at)} locale="en-US" />
                                                                </span>
                                                            </div>
                                                        </a> : <a className="msg-in active" onClick={() => userClickHndler(item?.user_data)}>
                                                            <div className="media">
                                                                <img className="mr-2" height={40} src={item?.user_data?.profile_picture ? IMAGE_URL + item?.user_data?.profile_picture : images.user}
                                                                />
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 fblack f13 ">{item?.user_data?.name} - {item?.request_type?.service?.title} </h5>
                                                                    <strong className="f16 fyellow">{item?.user_data?.name}</strong>
                                                                </div>
                                                                <span className="fblack f11">
                                                                    <ReactTimeAgo date={new Date(item?.created_at)} locale="en-US" />
                                                                </span>
                                                            </div>
                                                        </a>}
                                                </li>
                                            )
                                        })}
                                    </ul>

                                </div>
                            </div>

                            <div className="col-xl-8" >
                                <div className="card listing_c h-100 d-flex flex-colum" id="chat">
                                    <div className="flex">
                                        <div className="top d-flex justify-content-between ">
                                            <div className="media">
                                                <img className="mr-2" height={40} src={activeUser?.profile_picture ? IMAGE_URL + activeUser?.profile_picture : images.user}
                                                />
                                                <div className="media-body">
                                                    <h5 className="mt-0 fblack f13">{activeUser?.name} - {userDetails} </h5>
                                                    <strong className="f16 fyellow">{activeUser?.name}</strong>
                                                </div>
                                            </div>
                                            <div className="align-self-center top-icon" >
                                                <span className="icon-search"></span>
                                                <span className="icon-star"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="msg-box" id="msg_view">
                                        {thread.length > 0 && thread.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        item?.user_id != user.id ? <div className="other-msg d-flex">
                                                            <img src={item?.other_user_data?.profile_picture ? item?.other_user_data?.profile_picture : images.user} alt="" />
                                                            <div className="msg">
                                                                {item?.msg_type == 'TEXT' ?
                                                                    <div className="b-m-box">
                                                                        {item?.message}
                                                                    </div>
                                                                    : <div className="b-m-box">
                                                                        <div className="media">
                                                                            <img className="mr-2" src={images.psd_img}
                                                                            />
                                                                            <div className="media-body">
                                                                                <h5 className="my-0 f13">{item?.file_name}</h5>
                                                                                <strong className="f16">{bytesToSize(item?.file_size)}</strong>
                                                                            </div>
                                                                            <a download={item?.file_name} className="download-doc cursor-pointer" href={IMAGE_URL + item?.message} title="ImageName">
                                                                                <img height="20" src={images.download}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="time">
                                                                    {managePrevDate(thread[index + 1]?.created_at) ? <div className="time text-right">
                                                                        {dateFormat(item?.created_at, "mmm dS, yyyy, h:MM TT")}
                                                                    </div> : ''}
                                                                    {/* <ReactTimeAgo date={new Date(item?.created_at)} locale="en-US" /> */}
                                                                </div>
                                                            </div>
                                                        </div> :
                                                            <div className="my-msg d-flex justify-content-end">
                                                                <div className="msg">
                                                                    {item?.msg_type == 'TEXT' ?
                                                                        <div className="b-m-box">
                                                                            {item?.message}
                                                                        </div>
                                                                        : <div className="b-m-box">
                                                                            <div className="media">
                                                                                <img className="mr-2" src={item?.msg_type == 'Image' ? images.psd_img : images.psd_img} />
                                                                                <div className="media-body">
                                                                                    <h5 className="my-0 f13">{item?.file_name}</h5>
                                                                                    <strong className="f16">{bytesToSize(item?.file_size)}</strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {managePrevDate(thread[index + 1]?.created_at) ? <div className="time text-right">
                                                                        {dateFormat(item?.created_at, "mmm dS, yyyy, h:MM TT")}
                                                                    </div> : ''}
                                                                </div>
                                                                <img src={item?.user_data?.profile_picture ? item?.user_data?.profile_picture : images.user} alt="" />
                                                            </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="chat-send">
                                        <div className="d-flex">
                                            <div className="msg-area">
                                                <div className="icon-area">
                                                    <input type="file" id="imgupload-icon" style={{ display: 'none' }} onChange={docSelectHandler} className="icon-attach" />
                                                    <label className="icon-attach" htmlFor='imgupload-icon' >
                                                        <a>
                                                            {/* <span className="icon-attach"></span> */}
                                                            <img height={20} src={images.attach} />

                                                        </a>
                                                    </label>
                                                    <input type="file" id="imgupload-img" style={{ display: 'none' }} onChange={imageSelectHandler} className="icon-attach" />
                                                    <label className="icon-attach" htmlFor='imgupload-img' >
                                                        <a>
                                                            {/* <span className="icon-image f19"></span> */}
                                                            <img height={20} src={images.gallary} />

                                                        </a>
                                                    </label>
                                                </div>

                                                <input
                                                    type="text"
                                                    placeholder="Type your message"
                                                    value={msg}
                                                    name="msg"
                                                    id="input_box"
                                                    onChange={onChangeHandler}
                                                    className="input-box" />
                                            </div>
                                            <a className="send-btn"
                                                onClick={() => sendMessage('TEXT', msg, '', '')}
                                                id="send_btn"
                                            >
                                                {/* <span className="icon-send"> */}
                                                <img height={25} src={images.send} />
                                                {/* </span> */}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                    <div className="chat_msg_box">
                        <p className="chat_info_msg">No Lender Available to Chat!</p>
                    </div>}
            </div>
        </div >
    )
}

export default Chat;