import React from 'react';

const LoanCalculatorForm = () => {
    return (
        <form>
            <div className="d-sm-flex d-block">
                <div className="loan-detail-content pt-1">
                <h2 className="font-20 mb-5">Loan details:</h2>

                    <div className="form-group">
                        <label className="font-16 page-label">Total security value</label>
                        <div className="input-currency">
                            <span className="font-22 black fr currency-text">£</span>
                            <input type="text" className="form-control input-full text-right"
                                placeholder="400,000" />
                        </div>
                    </div>

                    <div className="form-group mb-5">
                        <label className="font-16 page-label">Funds required</label>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" id="fundreqiuredradio1"
                                name="fundreqiured" value="customEx" />
                            <label className="custom-control-label"  htmlFor="fundreqiuredradio1">Net loan
                        amount</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" id="fundreqiuredradio2"
                                name="fundreqiured" value="customEx"   />
                            <label className="custom-control-label"  htmlFor="fundreqiuredradio2">Gross
                        LTV</label>
                        </div>
                    </div>

                    <div className="form-group side-border">
                        <label className="font-16 page-label">Gross LTV required</label>
                        <input type="text" className="form-control input-sm" placeholder="70%" />
                    </div>

                    <div className="form-group">
                        <label className="font-16 page-label">Retained interest period</label>
                        <div className="d-flex align-items-center">
                            <input type="text" className="form-control input-sm text-right"
                                placeholder="1" />
                            <p className="font-16 black mb-0 pl-2">month/s</p>
                        </div>
                    </div>

                    <div className="form-group mb-md-5 mb-3 pb-3">
                        <label className="font-16 page-label">Interest Rate</label>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" id="interestrateradio1"
                                name="interestrate" value="customEx"   />
                            <label className="custom-control-label"  htmlFor="interestrateradio1">Capatool
                        Financial</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" id="interestrateradio2"
                                name="interestrate" value="customEx" />
                            <label className="custom-control-label"
                                 htmlFor="interestrateradio2">Custom</label>
                        </div>
                    </div>

                    <div className="form-group side-border">
                        <div className="mb-4">
                            <label className="font-16 page-label">Capatool fee</label>
                            <input type="text" className="form-control input-sm" placeholder="1%" />
                        </div>

                        <div>
                            <label className="font-16 page-label">Broker fee</label>
                            <input type="text" className="form-control input-sm" placeholder="1%" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-sm-flex d-block">
                <div className="loan-detail-content pt-1">
                <h2 className="font-20 mb-5">Additional details:</h2>

                    <div className="form-group mb-5">
                        <label className="font-16 page-label">Property security type</label>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                id="propertysectyperadio1" name="propertysectype" value="customEx"
                                  />
                            <label className="custom-control-label"
                                 htmlFor="propertysectyperadio1">Residential</label>
                        </div>

                    </div>

                    <div className="form-group">
                        <label className="font-16 page-label">Add estimated valuation and legal
                    fees?</label>

                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                id="estimatedvaluationradio1" name="estimatedvaluation"
                                value="customEx" />
                            <label className="custom-control-label"
                                 htmlFor="estimatedvaluationradio1">Yes</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                id="estimatedvaluationradio2" name="estimatedvaluation"
                                value="customEx"   />
                            <label className="custom-control-label"
                                 htmlFor="estimatedvaluationradio2">No</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LoanCalculatorForm;