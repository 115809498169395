
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { baseUrl } from '../../Config';
import Helper from '../../utils/apiHelper/apiHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import LoanList from './loansList';

function Loans(props) {
    const { user, history } = props;
    const [loanHistory, setLoanHistory] = useState([]);
    const [loan, setLoan] = useState([]);

    useEffect(() => {
        getLoans();
        getLoanHistory();
    }, [])

    const getLoans = () => {
        Helper.makeRequest('get-loan-request-list', {}).then((res) => {
            if (res.status === 'true') {
                setLoan(res?.data?.data)
            }
        })
    }

    const getLoanHistory = () => {
        Helper.makeRequest('get-previous-loan-history', {}).then((res) => {
            if (res.status === 'true') {
                setLoanHistory(res?.data?.data)
            }
        })
    }

    const viewMyProposalsHandler = (_id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/proposals", state: { title: title, id: _id } });
        }
    }

    const viewMyFormHandler = (id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/form", state: { title: title, id: id } });
        }
    }

    const showValuationHandler = (id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/valuations", state: { title: title, id: id } });
        }
    }
    return (
        <div className="portal-right-wrapper">
            <h4 className=" black fr  font-28 ttc">{Helper.getGreetings()} {user?.name}.</h4>
            <p className="heading_with_border black font-28 mb-5">Your Capatool Financial Applied Loan Details:</p>
            <b className="font-16"> Your safe place to store copies of the required documents for your loan. </b>
            <p className="black font-16"> <span className="primary">100% secure.</span> We only share these documents with the
                lender/s that you have
                selected to proceed with.</p>

            <div className="border-0 card mb-5">

                <div className="p-4 df jcb border-bottom">
                    <b className=" font-18">Your Current Application Forms & Documentation:</b>
                </div>

                {loan.length > 0 && loan.map((item, index) => {
                    return <LoanList
                        key={index}
                        amount={item?.amount}
                        loan_request_date={item?.loan_request_date}
                        loan_title={item?.loan_title}
                        service={item?.service}
                        viewMyFormHandler={viewMyFormHandler}
                        viewMyProposalsHandler={viewMyProposalsHandler}
                        form_type_id={item?.form_type_id}
                        showValuationHandler={showValuationHandler}
                        is_assigned_valuer={item?.is_assigned_valuer}
                        id={item?.id} />
                })}
            </div>
            <div className="border-0 card mb-5">

                <div className="p-4 df jcb border-bottom">
                    <b className=" font-18">Your Previous Loan History:</b>
                </div>

                {loanHistory.length > 0 && loanHistory.map((item, index) => {
                    return <LoanList
                        key={index}
                        amount={item?.amount}
                        loan_request_date={item?.loan_request_date}
                        loan_title={item?.loan_title}
                        service={item?.service}
                        viewMyFormHandler={viewMyFormHandler}
                        viewMyProposalsHandler={viewMyProposalsHandler}
                        form_type_id={item?.form_type_id}
                        id={item?.id} />
                })}
            </div>
            <WhiteFooter />
        </div>
    );
}

export default withRouter(Loans);
