import React from 'react';
function UserPortalFooter() {

    return (
        <ul className=" mt-5 portal-menu pl-0 d-flex align-items-center flex-wrap">
            <li><a href="#" className="font-11 fr white ttu">Home</a></li>
            <li><a href="#" className="font-11 fr white ttu">About Us</a></li>
            <li><a href="#" className="font-11 fr white ttu">Services</a></li>
            <li><a href="#" className="font-11 fr white ttu">Lenders</a></li>
            <li><a href="#" className="font-11 fr white ttu">Help Centre</a></li>
            <li><a href="#" className="font-11 fr white ttu">Blog</a></li>
            <li><a href="#" className="font-11 fr white ttu">Contact Us</a></li>
        </ul>
    )
}

export default UserPortalFooter;
