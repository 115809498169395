import React, { useEffect, useState } from 'react';
import Helper from '../../utils/apiHelper/apiHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import { connect } from 'react-redux';
import { updateProfile } from '../../redux/actions/authActions';
import images from '../../utils/imageHandler/imageHelper';
import OtpModal from '../global/modal/otpModal';
import ApiHelper from '../../utils/apiHelper/apiHelper';

function Setting(props) {
	const { user, updateUser } = props;
	const [edit, setEdit] = useState('');
	const [btn, setBtn] = useState('Edit');
	const [details, editDetails] = useState({});
	const [update, updateItems] = useState('');
	const [error, setError] = useState('');
	const [checked, setChecked] = React.useState(user?.is_enable_verification);
	const [modalShow, setModalShow] = React.useState(false);
	const [otp, setOtp] = React.useState('');
	const [otpError, setOtpError] = React.useState('');
	const [mobileVerified, setMobileVerified] = React.useState(1);
	const [emailVerified, setEmailVerified] = React.useState(1);

	useEffect(() => {
		getUserProfile();
		editDetails({
			name: user?.name,
			email: user?.email,
			telephone: user?.telephone,
			country_code: user?.country_code,
			mobile: user?.mobile,
			is_enable_verification: user?.is_enable_verification
		});
		setMobileVerified(user?.is_verify);
		setEmailVerified(user?.status);
	}, [])

	const onEditHandler = (value) => {
		if (value == edit && edit) {
			setBtn('Save');
			setEdit('');
			updateProfile();
		} else {
			setBtn('Edit');
			setEdit(value);
		}
	}

	const updateProfile = () => {
		let value = {
			name: details?.name,
			email: details?.email,
			country_code: details?.country_code,
			mobile: details?.mobile,
		}
		if (details.telephone) value.telephone = details?.telephone;
		let formData = Helper.renderFormData(value);
		if (!update) {
			return;
		}
		Helper.makeRequest('update-profile', formData).then((res) => {
			if (res.status === 'true') {
				Helper.Alert(res.message);
				getUserProfile();
				editDetails({
					name: res?.data?.name,
					email: res?.data?.email,
					telephone: res?.data?.telephone,
					mobile: res?.data?.mobile,
					country_code: res?.data?.country_code,
					is_enable_verification: res?.data?.is_enable_verification
				})
				updateUser(res);
			}
			else {
				Helper.Alert(res.message, 'error');
			}
			updateItems('');
		})
	}

	const valueChangeHandler = (e) => {
		editDetails(prev => ({
			...prev, [e.target.name]: e.target.value
		}))
		updateItems({
			[e.target.name]: e.target.value,
			name: details?.name,
			country_code: details?.country_code
		});
		if (!e.target.value) {
			setError('required*');
			return;
		}
		if (e.target.name == 'mobile' && !Helper.validateMobile(e)) {
			setError('Invalid mobile number');
		} else setError('')

		if (e.target.name == 'email' && Helper.validateEmail(e)) setError('Invalid email');
		else if (e.target.name == 'telephone') setError('');

		if (e.target.name == 'telephone' && !Helper.validateMobile(e)) {
			setError('Invalid telephone number');
		} else if (e.target.name == 'telephone') setError('')
	}
	const changeOtpHandler = (e) => {
		setOtp(e.target.value);
		if (!e.target.value) {
			setOtpError('OTP is required.')
			return;
		}
		if (e.target.value && e.target.value.length != 4) {
			setOtpError('OTP should be of 4 digits.')
			return;
		}
		setOtpError('')

	}

	const submitHandler = () => {
		if (otpError.length) {
			return;
		}
		let data = {
			otp: parseInt(otp),
			mobile: parseInt(details?.mobile),
			country_code: parseInt(details?.country_code)
		}
		let formData = ApiHelper.renderFormData(data)
		ApiHelper.makeRequest('verify-otp', formData).then((res) => {
			if (res.status === 'true') {
				ApiHelper.Alert(res.message, 'success');
				setModalShow(false);
				getUserProfile();
			} else {
				ApiHelper.Alert(res.message, 'error');
			}
		})
	}
	const resendHandler = () => {
		let data = {
			mobile: parseInt(details?.mobile),
			country_code: parseInt(details?.country_code)
		}
		let formData = ApiHelper.renderFormData(data)
		ApiHelper.makeRequest('otp', formData).then((res) => {
			if (res.status === 'true') {
				ApiHelper.Alert(res.message, 'success');
			} else {
				ApiHelper.Alert(res.message, 'error');
			}
		})
	}
	const getUserProfile = () => {
		ApiHelper.getProfile().then((res) => {
			if (res.status === 'true') {
				setMobileVerified(res?.data?.is_verify);
				setEmailVerified(res?.data?.status);
			}
		})
	}
	const resendEmailHandler = () => {
		let formData = ApiHelper.renderFormData({ email: details?.email })
		ApiHelper.makeRequest('resend-verification', formData).then((res) => {
			if (res.status === 'true') {
				ApiHelper.Alert(res.message, 'success');
			} else {
				ApiHelper.Alert(res.message, 'error');
			}
		})
	}
	return (
		<div className="portal-right-wrapper portal_setting">
			<OtpModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				onChange={changeOtpHandler}
				otp={otp}
				error={otpError}
				submitHandler={submitHandler}
				resendHandler={resendHandler}
				closeHandler={() => setModalShow(false)}
			/>
			<h4 className=" black fr  font-28 ttc">{Helper.getGreetings()} {user?.name}.</h4>
			<p className="heading_with_border black font-28 mb-5">Control your profile settings here...</p>

			<b className="font-16"> Here you can control your Capatool profile settings.</b>
			<p className="black font-16"> From your logins and contact details to your profile image if you wish to include one!
		</p>
			<div className="border-0 card mb-5">
				<div className="p-4 df jcb border-bottom">
					<b className=" font-18">Personal Details</b>
				</div>
				<div className="px-4 py-3   border-bottom ">
					<span className="font-18 gray-200">Name</span>
					<button className="ml-1 btn1 py-2 px-3" onClick={() => onEditHandler('name')}>
						{edit == 'name' ? 'Save' : 'Edit'}</button>
					<br />{edit == 'name' ?
						<><input
							type="text"
							name="name"
							onChange={valueChangeHandler}
							value={details?.name || ''}
							className="font-18 mt-2 mb-0 df jcs aic edit" />
							<span className="hint">{error}</span></> :
						<p className="font-18 mt-2 mb-0 df jcs aic">{details?.name}</p>}
				</div>
				<div className="px-4 py-3   border-bottom ">
					<span className="font-18 gray-200">Email Address &nbsp;</span>
					{emailVerified == 1 ? <img height="20" width="20" src={images.verified} /> :
						<><span className="hint">(Not verified)  &nbsp;</span>
							<a onClick={resendEmailHandler}
								className="verification-mail primary font-12 fr tdu "
							>Send verification mail</a></>}
					<button className="ml-1 btn1 py-2 px-3" onClick={() => onEditHandler('email')}>
						{edit == 'email' ? 'Save' : 'Edit'}</button>
					<br />{edit == 'email' ?
						<><input
							type="text"
							name="email"
							onChange={valueChangeHandler}
							value={details?.email || ''}
							className="font-18 mt-2 mb-0 df jcs aic edit" />
							<span className="hint">{error}</span></> :
						<p className="font-18 mt-2 mb-0 df jcs aic">{details?.email}</p>}
				</div>
				<div className="px-4 py-3   border-bottom">
					<span className="font-18 gray-200">Telephone Number</span>
					<button className="ml-1 btn1 py-2 px-3" onClick={() => onEditHandler('telephone')}>
						{edit == 'telephone' ? 'Save' : 'Edit'}</button>
					<br />{edit == 'telephone' ?
						<><input
							type="text"
							name="telephone"
							onChange={valueChangeHandler}
							value={details?.telephone || ''}
							className="font-18 mt-2 mb-0 df jcs aic edit" />
							<span className="hint">{error}</span></> :
						<p className="font-18 mt-2 mb-0 df jcs aic">{details?.telephone}</p>}
				</div>

				<div className="px-4 py-3 border-bottom">
					<span className="font-18 gray-200">Mobile Number &nbsp;</span>
					{mobileVerified == 1 ? <img height="20" width="20" src={images.verified} /> :
						<><span className="hint">(Not verified) &nbsp;</span>
							<a onClick={() => setModalShow(!modalShow)} className="verification-mail primary font-12 fr tdu ">Verify mobile</a></>}
					<button className="ml-1 btn1 py-2 px-3" onClick={() => onEditHandler('mobile')}>
						{edit == 'mobile' ? 'Save' : 'Edit'}</button>
					<br />{edit == 'mobile' ?
						<>
							<input
								type="text"
								name="mobile"
								onChange={valueChangeHandler}
								value={details?.mobile || ''}
								className="font-18 mt-2 mb-0 df jcs aic edit" />
							<span className="hint">{error}</span></>
						:
						<p className="font-18 mt-2 mb-0 df jcs aic">{details?.mobile}</p>}
				</div>

				<div className="px-4 py-3 border-bottom">
					<span className="font-18 gray-200">Two-Factor Authentication</span>
					{/* <button className="ml-1 btn1 py-2 px-3" onClick={() => onEditHandler('is_enable_verification')} >
						{edit == 'is_enable_verification' ? 'Save' : 'Edit'}</button> */}
					<div className="mt-3 custom_checkbox at">
						<input
							type="checkbox"
							id="check-1"
							name="is_enable_verification"
							// value={details?.is_enable_verification}
							// checked={details.is_enable_verification}
							defaultChecked={checked}
							onChange={() => setChecked(checked ? 0 : 1)}
							disabled={edit != 'is_enable_verification'}
						/>
						<label htmlFor="check-1" className="font-18"> <span className="check_content">
							Two-Factor Authentication is {checked ? 'enabled' : 'disabled'}.</span></label>
						{/* <p className="pl-4 primary font-18"> (Please tick to confirm your consent)</p> */}
					</div>
				</div>
			</div>

			<div className="border-0 card mb-5">
				<div className="p-4 df jcb border-bottom">
					<b className=" font-18">Change Password</b>
				</div>

				<div className="px-4 py-3 border-bottom">
					<p className="font-18 mt-2 mb-0"> If you wish to change your password, please click the button below.</p>
				</div>

				<a className="btn1 ttu " onClick={props.changePasswordHandler}> Change Password</a>

			</div>
			<WhiteFooter />
		</div >
	);
}


const mapDispatchToProps = (dispatch) => ({
	updateUser: (data) => dispatch(updateProfile(data)),
});
export default connect(null, mapDispatchToProps)(Setting);