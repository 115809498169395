
import React from 'react';
import Helper from '../../utils/apiHelper/apiHelper';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux'
import { useState } from 'react';

function EmailView(props) {
    const { user, history } = props;
    const [data, setData] = useState({});

    useEffect(() => {
        setData(history?.location?.state);
        markAsRead(history?.location?.state?.id);
    }, [])

    const markAsRead = (id) => {
        let formData = Helper.renderFormData({ inbox_id: id })
        Helper.makeRequest('get-inbox-update', formData);
    }
    
    const returnToInboxHandler = () => {
        if (history) {
            history.goBack()
        }
    }

    return (
        <div className="portal-right-wrapper ">
            <div className="user-my_inbox_email_view w-100">

                <div className="text-right d-flex justify-content-end font-13 rin-box">
                    <a className="black" onClick={returnToInboxHandler}>
                        <img src={images.returnInbox} className="mr-2" alt="" />
                        <span className="ttu tdu ls gray-300"> RETURN TO MY INBOX</span>
                    </a>
                </div>

                <h2 className="heading_with_border font-28 black ttc">
                    {Helper.getGreetings()} {user?.userData?.name}. <br />
                    Your Capatool Financial Inbox:
                </h2>
                <p className="mb-0 font-16 ">Let's get things done. Here you'll find all copies of messages that we
                    send to you.</p>
                <p className="mb-0 font-16 black">These message have also been sent to your registered email with us.</p>

                <div className="white-box">
                    <div className="d-flex px-area bb justify-content-between">
                        <h4 className="tt font-18">From: <span className="black">Capatool Financial </span></h4>
                        <h4 className="tt font-18">Sent: <span className="black">{data?.created_at?.split('T')[0]} </span></h4>
                    </div>

                    <div className="px-area bb ">
                        <div className="row">

                            <div className="col-md-6">

                                <h2 className="tt2">
                                    <b>{data?.subject}</b>
                                    <span className="d-block primary"> For: Title Goes Here</span>
                                </h2>
                            </div>
                            <div className="col-md-6 text-md-right mt-md-0 mt-4">
                                <img src="images/together.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="px-area">
                        <div dangerouslySetInnerHTML={{ __html: data.message }} />
                    </div>
                    
                    <a href="mailto:support@maxcapfinancial.com" className="email-section mt-5">
                        <span>EMAIL US AT: SUPPORT@MAXCAPFINANCIAL.COM</span>
                    </a>
                </div>
            </div>

            <WhiteFooter />

        </div>);
}
const mapStateToProps = state => ({
    user: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(EmailView));
