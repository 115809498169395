import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../Config';
import images from '../../utils/imageHandler/imageHelper';
const LeftLayout = (props) => {

    return (
        <>
            <div className="left">
                <a href={baseUrl + "/"}> <img src={images.logoWhite} alt="" /></a>
                <div className="content">
                    <p className="primary">Capatool</p>
                    <p className="primary">{props.title}</p>
                    <p className="white">
                        Form</p>
                </div>
                <a href={baseUrl + "/"} className="primary home"> <img src={images.home} alt="" /> RETURN TO HOMEPAGE</a>
            </div>
        </>
    );
}

export default LeftLayout;