export function hideHeader(data) {
    return {
        type: 'HIDE_HEAD_FOOTER',
        payload: data
    }
}

export function toggleSidebar(data) {
    return {
        type: 'TOGGLE_SIDEBAR',
        payload: data
    }
}

export function saveInboxData(data) {
    return {
        type: 'INBOX_COUNT',
        payload: data
    }
}

export function saveLoanData(data) {
    return {
        type: 'LOAN_COUNT',
        payload: data
    }
}
export function saveSidebarItem(data) {
    return {
        type: 'SIDEBAR_ITEM',
        payload: data
    }
}