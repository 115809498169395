
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { baseUrl } from '../../Config';
import Helper from '../../utils/apiHelper/apiHelper';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import LoanList from '../userLoans/loansList';
import LoanListDraft from './loansListDraft';

function DraftForms(props) {
    const { user, history } = props;

    const [loan, setLoanDetails] = useState([]);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        getDrafts();
    }, [])

    const getDrafts = (lender_id) => {
        let formData = Helper.renderFormData({ request_id: id, lender_id: lender_id })
        Helper.makeRequest('get-draft-loan-request-list', formData).then((res) => {
            if (res.status === 'true') {
                setLoanDetails(res?.data?.data)
            }
        })
    }

    const viewMyFormHandler = (id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/form", state: { title: title, id: id } });
        }
    }
    const showDocumentHandler = (id, title) => {
        if (history) {
            history.push({ pathname: baseUrl + "/documents", state: { title: title, id: id } });
        }
    }
    return (
        <>
            <div class="portal-right-wrapper">
                <h4 class=" black fr  font-28 ttc">{Helper.getGreetings()}  {user?.name}.</h4>
                <p class="heading_with_border black font-28 mb-5">Your Capatool Financial Applied Uncompleted Loan Details:</p>

                <b class="font-16"> Your safe place to store copies of the required documents for your loan. </b>
                <p class="black font-16"> <span class="primary">100% secure.</span> We only share these documents with the
                    lender/s that you have
                    selected to proceed with.</p>

                <div class="border-0 card mb-4">
                    <div class="p-4 df jcb border-bottom">
                        <b class=" font-18">ID Verification Required for ALL Loans: </b>
                    </div>
                    <div class="px-4 py-3   border-bottom">
                        <button type="button" class="add-section "> <img src={images.add} alt="" />
                            <span>ADD YOUR PHOTO ID FOR VERIFICATION
                            </span>
                        </button>
                    </div>
                </div>

                {loan.length > 0 && loan.map((item, index) => (
                    <LoanListDraft
                        close={true}
                        addDocuments={true}
                        statusPreApplication={true}
                        key={index}
                        service={item?.service}
                        showDocumentHandler={showDocumentHandler}
                        viewMyFormHandler={viewMyFormHandler}
                        amount={item?.amount}
                        loan_request_date={item?.loan_request_date}
                        id={item?.id}
                        form_type_id={item?.form_type_id}
                        loan_title={item?.loan_title} />
                ))}
                <a href="" class="w-100 d-block btn1 tc font-18 mt-5 mb-5 ls4"> EMAIL US AT: SUPPORT@CAPATOOL.COM</a>
                <WhiteFooter />
            </div>
        </>
    );
}

export default withRouter(DraftForms);