import React from 'react';

const SolicitorDetails = (props) => {
    const { data, updateSolicitor, onChangeHandler, editable, id } = props;
    return (
        <div className="px-4 py-3   border-bottom">
            <div className="row">
                <div className=" form-group col-md-9">
                    <p className="font-18 mb-5  ">Solicitor Contact Details
                    </p>
                    {data.map((item, index) => (
                        <div className="row mb-3">
                            <div className="col-md-4 ">
                                <span className="font-18 primary">{item?.title.replace('SOLICITOR', '')}:</span>
                            </div>
                            <div className="col-md-8 ">
                                {!editable ? <span className="font-18 "> {item?.field_value}</span> :
                                    <input
                                        className="font-18 mt-2 mb-0 df jcs aic edit"
                                        type="text"
                                        onChange={(e) => onChangeHandler(e, item, id)}
                                        value={item?.field_value} />}
                            </div>
                        </div>
                    ))}
                </div>
                <div className=" form-group col-lg-3 col-md-4 text-md-right bottom-align">
                    <button onClick={() => updateSolicitor(id)} className="btn1">{!editable ? 'EDIT' : 'UPDATE'} </button>
                </div>
            </div>
        </div>
    );
}

export default SolicitorDetails;