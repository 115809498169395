import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FinanceSolutionHome from '../containers/financeSolutionHome';
import EligibilityCalculator from '../containers/eligibilityCalculator';
import applicationForm from '../containers/applicationForm';
import UserPortal from '../containers/userPortal';
import UserInbox from '../containers/userInbox';
import UserLoans from '../containers/userLoans';
import LoanProposals from '../components/userLoans/loanProposal';
import UserDocuments from '../containers/userDocuments';
import UserDrafts from '../containers/userDrafts';
import UserSettings from '../containers/userSettings';
import ChangePassword from '../components/changePassword';
import liveChat from '../containers/liveChat';
import { baseUrl } from '../Config';
import UserPortalFinance from '../components/userPortalHome/userPortalFinance';
import UserPortalEligibilityCalculator from '../components/userPortalHome/userPortalEligibilityCalculator';
import UserPortalLoanForm from '../components/userPortalHome/userPortalLoanForm';
import EmailView from '../components/userInbox/emailView';
import Valuations from '../components/userLoans/valuations';
import ViewValuations from '../components/userLoans/viewValuation';

const ProtectedRoutes = () => {
    return (
        < Switch >
            <Route exact path={baseUrl + "/"} component={UserPortal} />
            <Route exact path={baseUrl + "/finance"} component={UserPortalFinance} />
            <Route exact path={baseUrl + "/calculator"} component={UserPortalEligibilityCalculator} />
            <Route exact path={baseUrl + "/form"} component={UserPortalLoanForm} />
            <Route exact path={baseUrl + "/inbox"} component={UserInbox} />
            <Route exact path={baseUrl + "/email-view"} component={EmailView} />
            <Route exact path={baseUrl + "/loans"} component={UserLoans} />
            <Route exact path={baseUrl + "/documents"} component={UserDocuments} />
            <Route exact path={baseUrl + "/drafts"} component={UserDrafts} />
            <Route exact path={baseUrl + "/setting"} component={UserSettings} />
            <Route exact path={baseUrl + "/proposals"} component={LoanProposals} />
            <Route exact path={baseUrl + "/valuations"} component={Valuations} />
            <Route exact path={baseUrl + "/view-valuation"} component={ViewValuations} />
            <Route exact path={baseUrl + "/change-password"} component={ChangePassword} />
            <Route exact path={baseUrl + "/chat"} component={liveChat} />
            <Redirect exact path="*" to={baseUrl + "/"} />
        </Switch >
    );
}

export default ProtectedRoutes;
