import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { hideHeader } from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import Helper from '../../utils/apiHelper/apiHelper';

function Home(props) {
    const { history } = props;
    let location = useLocation();
    const [content, setContent] = useState(`<p>...loading</p>`);

    useEffect(() => {
        props.isHeader(true);
        let formData = Helper.renderFormData({ slug: location.pathname.split('/')[2] });
        Helper.makeRequest('get-static-page', formData).then(async (res) => {
            if (res.status === 'true') {
                await setContent(res?.data?.content);
            }
        })
        return () => {
            if (history.action === "POP") {
                history.push('/')
            }
        }
    }, [])
    
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </>
    );
}

 

const mapDispatchToProps = (dispatch) => ({
    isHeader: (data) => dispatch(hideHeader(data)),
});
export default withRouter(connect(null, mapDispatchToProps)(Home));

