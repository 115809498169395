import React from "react";

function ChooseLender() {
    return (
        <section className="lender">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mb-4">
                        <span className="primary font-11 ttu ls">CHOOSE YOUR LENDER</span>
                        <h4 className="heading_with_border white fr font-28">You're in control.</h4>

                        <p className="white font-18">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                        cumquat ariouse nibh
						euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                        <a href="#" className="btn1 mt-2">SEE OUR LENDING SERVICES</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChooseLender;