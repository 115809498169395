
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FinanceApplication from '../../components/financeApplication/financeApplication';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { baseUrl } from '../../Config';

function ApplicationForm(props) {
    const { history, match, header } = props;
    const auctionType = match.params;
    const [id, setID] = useState('');

    useEffect(() => {
        header(false);
        setID(props?.location?.state?.id);
        return () => {
            header(true);
        }
    }, [])
    const sendToHome = () => {
        if (history) {
            history.push(baseUrl + '/');
        }
    }
    return (
        <div className="auction-finance-Application-Form">
            <FinanceApplication
                sendToHome={sendToHome}
                form_id={props?.location?.state?.id}
                title={props?.location?.state?.title} />
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    header: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationForm));
