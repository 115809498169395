import React from 'react';
import { Formik } from 'formik';
import ApiHelper from '../../utils/apiHelper/apiHelper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Helper from '../../utils/apiHelper/apiHelper';

const ChangePassword = (props) => {
    const { user } = props
    const validate = values => {
        const errors = {};
        if (!values.current_password) {
            errors.current_password = 'Current Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.current_password)) {
            errors.current_password = 'Must be alphanumeric and contain at least one special character';
        }
        if (!values.new_password) {
            errors.new_password = 'New Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.new_password)) {
            errors.new_password = 'Must be alphanumeric and contain at least one special character';
        }
        if (!values.confirm_new_password) {
            errors.confirm_new_password = 'Current New Password is required.';
        } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/i.test(values.confirm_new_password)) {
            errors.confirm_new_password = 'Must be alphanumeric and contain at least one special character';
        }

        if (values.new_password != values.confirm_new_password) {
            errors.confirm_new_password = 'New password and confirm password should be same.';
        }

        return errors;
    }
    const handlePasswordHide = (id) => {
        let type = document.getElementById(id).type;
        document.getElementById(id).type = type == 'password' ? 'text' : 'password'
    }
    return (
        <div className="portal-right-wrapper portal_setting">

            <h4 className=" black fr  font-28">{Helper.getGreetings()}  {user?.userData?.name}.</h4>
            <p className="heading_with_border black font-28 mb-5">Change your password here....</p>

            <div className="border-0 card mb-5">

                <div className="p-4 df jcb border-bottom">
                    <b className=" font-18">Change Password </b>

                </div>

                <div className="px-4 py-3   border-bottom">
                    <Formik
                        initialValues={{ current_password: '', new_password: '', confirm_new_password: '' }}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            let data = {
                                current_password: values.current_password,
                                new_password: values.new_password
                            }
                            let formData = ApiHelper.renderFormData(data)
                            ApiHelper.makeRequest('change-password', formData).then((res) => {
                                if (res.status === 'true') {
                                    ApiHelper.Alert(res.message, 'success');
                                } else {
                                    ApiHelper.Alert(res.message, 'error');
                                }
                                setSubmitting(false);
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            return (
                                <form method="post" className="row" onSubmit={handleSubmit}>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="font-18 "> Old Password</label>
                                            <input
                                                type="password"
                                                name="current_password"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.current_password} />
                                            <span className="hint"> {errors.current_password && touched.current_password && errors.current_password}</span>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-18"> New Password </label>
                                            <input
                                                type="password"
                                                name="new_password"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.new_password} />
                                            <span className="hint"> {errors.new_password && touched.new_password && errors.new_password}</span>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-18"> Confirm New Password</label>
                                            <input
                                                type="password"
                                                name="confirm_new_password"
                                                className="form-control"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.confirm_new_password} />
                                            <span className="hint">{errors.confirm_new_password && touched.confirm_new_password && errors.confirm_new_password}</span>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn1 submit ttu w-100"> SUBMIT</button>
                                        </div>
                                    </div>

                                </form>
                            )
                        }}
                    </Formik>


                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(ChangePassword));