import React, { useState, useEffect } from 'react';
const Select = (props) => {
    const { label, options, name, onBlur, touched, errors, handleChange, value } = props;
    const [error, setError] = useState('');
    useEffect(() => {
        if (errors && errors[name] && touched && touched[name]) {
            setError(label + ' ' + errors[name])
        } else {
            setError('')
        }
    })
    return (
        <div className="form-group">
            <label htmlFor="" className="font-16"> {label} </label>
            <select
                className="form-control custom_select2"
                onChange={handleChange}
                value={value}
                onBlur={onBlur}
                name={name}>
                <option value=""> PLEASE SELECT...</option>
                {options.length > 0 && options.map((item, index) => (
                    < option key={index} value={item?.option_value}> {item?.option_value}</option>
                ))}
            </select>
            {error && < span className="hint">
                {error}
            </span>}
        </div >
    );
}
export default Select;
