import * as React from 'react';
import { API_URL, baseUrl } from "../../Config";
import cogoToast from 'cogo-toast';
import axios, { Axios } from 'axios';

// import moment from 'moment-timezone';
// import { Trans } from 'react-i18next';
// var nl2br = require('react-nl2br');
class Helper extends React.Component {
    static async makeRequest(url, data, method = "POST") {
        // alert(url)
        let finalUrl = API_URL + url;
        let token = await localStorage.getItem('token');

        let requestOptions = {};
        // alert(method)
        if (method === "POST") {
            // requestOptions = {
            //     method: 'POST',
            //     // mode: "cors",
            //     // cache: "no-cache",
            //     // credentials: "same-origin",
            //     headers: {
            //         // 'Accept': 'application/json',
            //         // 'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + token,
            //     },
            //     body: data
            // };

            return axios.post(finalUrl, data, {headers:  {'Authorization': 'Bearer ' + token}})
            .then(res => {
                // const { statusCode, data } = res;
                var responseJson = res;
                console.log('responseJson responseJson', res,responseJson)
                if (responseJson.status === 'false') {
                    // localStorage.clear();
                    this.Alert(responseJson?.message, 'error')
                    // window.location.href = baseUrl + '/';
                } else {
                    return responseJson;
                }
            }).catch(error => {
                return { status: 'false', message: "Something went wrong.", data: [] };
            });
        }
        else {
            // requestOptions = {
            //     method: 'GET',
            //     // mode: "cors",
            //     cache: "no-cache",
            //     // credentials: "same-origin",
            //     headers: {
            //         // 'Accept': 'application/json',
            //         // 'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + token,
            //     },
            // };
            return axios.get(finalUrl, {}, {headers:  {'Authorization': 'Bearer ' + token}})
            .then(this.processResponse)
            .then(res => {
                const { statusCode, data } = res;
                var responseJson = data;
                if (responseJson.status === 'false') {
                    // localStorage.clear();
                    this.Alert(responseJson?.message, 'error')
                    // window.location.href = baseUrl + '/';
                } else {
                    return responseJson;
                }
            }).catch(error => {
                return { status: 'false', message: "Something went wrong.", data: [] };
            });
        }
        
    }

    static renderFormData(values) {
        var formData = new FormData();
        Object.keys(values).map(function (key, index) {
            formData.append(key, values[key])
        });
        return formData
    }

    static processResponse(response) {
        const statusCode = response.status;
        const data = response.json();
        return Promise.all([statusCode, data]).then(res => ({
            statusCode: res[0],
            data: res[1]
        }));
    }

    static async Alert(message, type = 'success', hideAfter = 5) {
        if (type === 'error') {
            cogoToast.error(message, { hideAfter: hideAfter });
        } else if (type === 'warning') {
            cogoToast.warn(message, { hideAfter: hideAfter });
        } else if (type === 'loading') {
            cogoToast.loading(message, { hideAfter: hideAfter });
        } else if (type === 'info') {
            cogoToast.info(message, { hideAfter: hideAfter });
        } else {
            cogoToast.success(message, { hideAfter: hideAfter });
        }
    }
    static async Notification(message, title) {

        let data = (<div>
            <b>{title}</b>
            <div>{message}</div>
        </div>);
        const options = {
            position: 'top-right', hideAfter: 10, onClick: () => {
                hide();
            },
        }
        const { hide } = cogoToast.info(data, options)
    }

    static async getProfile() {
        return this.makeRequest('get-profile');
    }

    static handelMultiCheck(event, options) {
        let index
        if (event.target.checked) {
            options.push(+event.target.value)
        }
        else {
            index = options.indexOf(+event.target.value)
            options.splice(index, 1)
        }
        return options;
    }

    static fileUpload(e) {
        return {
            file: e.target.files[0],
            url: URL.createObjectURL(e.target.files[0])
        };
    }


    static async getFileType(file) {
        return file['type'].split('/')[0];
    }

    static FloatInput(e) {
        var ex = /^\d+(?:\.\d{1,2})?$/;
        // if (isNaN(e.target.value)) {
        //     return '';
        // } else {
        //     return e.target.value;
        // }
        var value = e.target.value
        if (value.match(/^(-?\d*)((\.(\d{0,2})?)?)$/i) !== null) {
            return e.target.value;
        } else {
            // value = value.toString();
            return value.slice(0, -1);
        }
    }

    static validateUsername(e) {
        return e.target.value.replace(/[^A-Za-z0-9._]/ig, '')
    }
    static validateName(e) {
        return e.target.value.replace(/[^A-Za-z ]/ig, '')
    }

    static validatePassword(e) {
        return e.target.value.replace(/\s+/g, '')
    }

    static validateEmail(e) {
        return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)

    }

    static validateMobile(e) {
        return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(e.target.value)
    }

    static push(route) {
        return baseUrl + '/' + route;
    }

    static getMonths() {
        return (
            <React.Fragment>
                {/* <option value="">Month</option> */}
                <option value={1}>January</option>
                <option value={2}>February</option>
                <option value={3}>March</option>
                <option value={4}>April </option>
                <option value={5}>May </option>
                <option value={6}>June</option>
                <option value={7}>July </option>
                <option value={8}>August </option>
                <option value={9}>September</option>
                <option value={10}>October </option>
                <option value={11}>November </option>
                <option value={12}>December </option>
            </React.Fragment>
        )
    }

    static getYears() {
        var arr = [];
        var start = new Date().getFullYear();
        var end = new Date().getFullYear();
        for (let i = start; i <= end + 15; i++) {
            arr.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <React.Fragment>
                {/* <option value="">Year</option> */}
                {arr.map((value, key) => {
                    return (value);
                })}
            </React.Fragment>
        )
    }

    static getTimezone() {
        // return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static convertTime(time, format) {
        // var time = moment(time).format("YYYY-MM-DD HH:mm:ss");
        // console.log(time);
        //var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //return moment.tz(time, timezone).format(format);
        // return moment.utc(time).tz(moment.tz.guess()).format(format);
    }

    static convertTimeago(time) {
        // var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // return moment.tz(time, timezone);
        // console.log(moment.utc(time).tz(moment.tz.guess()));
        // return moment.utc(time).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss")
    }

    static TimeAgo(time) {
        // return moment.utc(time).tz(moment.tz.guess()).fromNow();
    }


    static validateUrl(url) {
        var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
        if (regex.test(url)) {
            return true;
        } else {
            return false;
        }
    }

    static calculateStripeFee(amount) {
        if (amount > 0) {
            var amt = (amount * 2.9 / 100) + 0.30;
            var new_amt = parseFloat(amount) + parseFloat(amt);
            var amt = (new_amt * 2.9 / 100) + 0.30;
            return parseFloat(amt).toFixed(2);
            // return this.formatLimitDecimals(amt);
        } else {
            return 0;
        }
    }

    static calculateReferralBonus(amount, percentage) {
        var amount = parseFloat(amount);
        var percentage = parseFloat(percentage);
        // console.log(amount);
        // console.log(percentage);
        // return this.formatLimitDecimals(((amount * percentage) / 100));
        var smt = (amount * percentage) / 100;
        return parseFloat(smt).toFixed(2);
    }

    static getGreetings() {
        var data = [
            [0, 4, "Good night"],
            [5, 11, "Good morning"],
            [12, 16, "Good afternoon"],
            [17, 18, "Good evening"],
            [19, 24, "Good night"]
        ],
            hr = new Date().getHours();
        let greet = '';
        for (var i = 0; i < data.length; i++) {
            if (hr >= data[i][0] && hr <= data[i][1]) {
                greet = data[i][2];
            }
        }
        return greet;
    }

    static formatLimitDecimals(value, decimals = 2) {
        value = value.toString().split('.')

        if (value.length === 2) {
            return Number([value[0], value[1].slice(0, decimals)].join('.'))
        } else {
            return Number(value[0]);
        }
    }

    static handlePlay(e = null) {
        var x = document.getElementsByTagName("audio");
        var i;
        for (i = 0; i < x.length; i++) {
            if (e && e.target.src !== x[i].src) {
                x[i].pause()
            }
            if (!e) {
                x[i].pause()
            }
        }
    }

    static handleVideoPlay(e = null) {
        var x = document.getElementsByTagName("video");
        var i;
        for (i = 0; i < x.length; i++) {
            if (e && e.target.src !== x[i].src) {
                x[i].pause()
            }
            if (!e) {
                x[i].pause()
            }
        }
    }

    static handleSwipe() {
        Helper.handlePlay();
        Helper.handleVideoPlay();
    }

    static cformatLimitDecimals(value, decimals = 2) {
        value = value.toString().split('.')

        if (value.length === 2) {
            return Number([value[0], value[1].slice(0, decimals)].join('.'))
        } else {
            return Number(value[0]);
        }
    }


}

export default Helper;