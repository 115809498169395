
import React from 'react';
import images from '../../utils/imageHandler/imageHelper';
const IndicativeAuctionFinance = () => {
    return (
        <section class="residential-bridge-sec bg_gray">
            <div class="container">
                <h6 class="primary font-11 ttu ls">Indicative Auction Finance</h6>
                <h2 class="heading_with_border font-28 black">5 Auction Finance options we
                could arrange for you:</h2>
                <p class="mb-0 font-16 black"><strong class="fb">Great news!</strong> We have lenders who could oﬀer you
                the Auction Finance for your project.</p>
                <p class="mb-0 font-16 black">We've outlined these indicative packages below for you. Click your
                preferred option below to see more details:</p>

                <div class="table-responsive d-none d-md-block">
                    <table class="table table-bordered packages-table bg-white">
                        <thead>
                            <tr>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">Lender
                                option</th>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">GROSS
                                LOAN
                            </th>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">Rate</th>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">Term</th>
                                <th scope="col" class="font-16 primary fb ttu text-left ls4 border-bottom-0">Fees*
                            </th>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">NET LOAN
                                AMOUNT</th>
                                <th scope="col" class="font-16 primary fb ttu text-center ls4 border-bottom-0">Apply
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[2, 5, 8, 6, 6, 9].map((item, index) => {
                                return (<tr key={index}>
                                    <th scope="row">
                                        <table className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '60px', paddingRight: '20px' }}><img
                                                        src={images.favicon} alt="" /></td>
                                                    <td>
                                                        <h6 className="font-15 fb ttu mb-0 ls">Package</h6>
                                                        <h3 className="font-22 fb ttu mb-0 primary">One</h3>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </th>
                                    <td>£250K</td>
                                    <td>12%</td>
                                    <td>24 <br /> MONTHS</td>
                                    <td className="p-0">
                                        <table className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="border-bottom"><span className="font-13">ARRANGEMENT:</span>
                                                £XXXX</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="font-13">EXIT:</span> £XXXX</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="text-center">
                                        <span class="font-15"> £250K</span>
                                    </td>
                                    <td><a href="/"
                                        class="btn1 w-100 d-block text-center ttu font-12 ls">
                                        APPLY</a></td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="mobile_custom_table">
                    <div className="table_box">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                <div className="media">
                                    <img src={images.favicon} alt="" />
                                    <div className="media-body">
                                        <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                        <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <a href="auction-finance-Application-Form.html"
                                    className="btn1 w-100 d-block text-center ttu font-12 ls">
                                    APPLY</a>
                            </div>



                        </div>

                        <div className="row mt-2">

                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                <p className="font-14 mb-0 ">£250K</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                <p className="font-14 mb-0 ">12%</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                <p className="font-14 mb-0">24 MONTHS</p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0">NET LOAN
                AMOUNT</h3>
                                <p className="font-14 mb-0"> £250K</p>
                            </div>
                        </div>
                    </div>
                    <div className="table_box">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                <div className="media">
                                    <img src={images.favicon} alt="" />
                                    <div className="media-body">
                                        <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                        <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <a href="auction-finance-Application-Form.html"
                                    className="btn1 w-100 d-block text-center ttu font-12 ls">
                                    APPLY</a>
                            </div>



                        </div>

                        <div className="row mt-2">

                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                <p className="font-14 mb-0 ">£250K</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                <p className="font-14 mb-0 ">12%</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                <p className="font-14 mb-0">24 MONTHS</p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                           AMOUNT</h3>
                                <p className="font-14 mb-0"> £250K</p>
                            </div>
                        </div>



                    </div>

                    <div className="table_box">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                <div className="media">
                                    <img src={images.favicon} alt="" />
                                    <div className="media-body">
                                        <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                        <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <a href="auction-finance-Application-Form.html"
                                    className="btn1 w-100 d-block text-center ttu font-12 ls">
                                    APPLY</a>
                            </div>



                        </div>

                        <div className="row mt-2">

                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                <p className="font-14 mb-0 ">£250K</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                <p className="font-14 mb-0 ">12%</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                <p className="font-14 mb-0">24 MONTHS</p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                          AMOUNT</h3>
                                <p className="font-14 mb-0"> £250K</p>
                            </div>
                        </div>



                    </div>

                    <div className="table_box">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu  ls4">LENDER OPTION</h3>
                                <div className="media">
                                    <img src={images.favicon} alt="" />
                                    <div className="media-body">
                                        <h6 className="font-14 fb ttu  mb-0 ls text-truncate">Package </h6>
                                        <h3 className="font-12 fb ttu  mb-0 primary">One</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <a href="auction-finance-Application-Form.html"
                                    className="btn1 w-100 d-block text-center ttu font-12 ls">
                                    APPLY</a>
                            </div>



                        </div>

                        <div className="row mt-2">

                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0 ">GROSS LOAN</h3>
                                <p className="font-14 mb-0 ">£250K</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">RATE </h3>
                                <p className="font-14 mb-0 ">12%</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu  m-0">TERM </h3>
                                <p className="font-14 mb-0">24 MONTHS</p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-8">
                                <h3 className="font-12 primary fb ttu m-0 ">FEES*</h3>
                                <p className="font-14 mb-0">ARRANGEMENT: £000,000.00</p>
                            </div>
                            <div className="col-4">
                                <h3 className="font-12 primary fb ttu m-0">NET LOAN
                                                 AMOUNT</h3>
                                <p className="font-14 mb-0"> £250K</p>
                            </div>
                        </div>



                    </div>

                </div>

            </div>

        </section>)
}
export default IndicativeAuctionFinance;