import React, { useEffect } from 'react';
import FinancialSolutions from '../../components/home/financialSolutions';
import IntelligentLending from '../../components/home/intelligentLending';
import ChooseLender from '../../components/home/chooseLender';
import CustomerFeedback from '../../components/home/customerFeedback';
import QuickCompletions from '../../components/home/quickCompletions';
import Recommended from '../../components/home/recommended';
import LendingOptions from '../../components/home/lendingOptions';
import GetStarted from '../../components/home/getStarted';
import { withRouter } from 'react-router-dom';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { baseUrl } from '../../Config';

function Home(props) {
    const { history } = props;
    useEffect(() => {
        props.isHeader(true);
        return () => {
            if (history.action === "POP") {
                history.push('/')
            }
        }
    }, [])

    return (
        <>
            <FinancialSolutions />
            <IntelligentLending />
            <ChooseLender />
            <CustomerFeedback />
            <QuickCompletions />
            <Recommended />
            <LendingOptions />
            <GetStarted />
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    isHeader: (data) => dispatch(hideHeader(data)),
});
export default withRouter(connect(null, mapDispatchToProps)(Home));

