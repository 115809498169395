
import * as React from 'react';
import { SOCKET_URL } from "../../Config";
import socketClient from "socket.io-client";
export class Socket extends React.Component {
    // static data = localStorage.getItem('userData');
    // static user = JSON.parse(this.data)
    // static socket = socketClient(SOCKET_URL, { query: `user_id=${this.user?.id}` },
    //     {
    //         transports: ['websocket'],
    //         pingInterval: 1000 * 60 * 5,
    //         pingTimeout: 1000 * 60 * 3
    //     }
    // );
    // static async makeConnection() {

    //     this.socket.on('connect', (data) => {
    //         // console.log(`I'm connected with the back-end`, this.socket);
    //     });

    //     this.socket.on('user_joined', (data) => {
    //         // console.log(`test`, data);
    //     });
    // }

    // static newNotification = async () => {
    //     await this.socket.on('notification_response', (data) => {
    //         return data;
    //     });
    // }
}