import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { baseUrl } from "../../Config";
import Helper from "../../utils/apiHelper/apiHelper";
import images from "../../utils/imageHandler/imageHelper";

const AlmostDone = (props) => {
    const [agree, setAgree] = useState('');
    const { activeStep, isLastStep, form_id, repeat, field } = props;
    const submitHandler = async () => {
        if (agree == 'YES I AGREE') {
            let data = {
                [field[0]?.id]: agree
            }
            let formData = await Helper.renderFormData({ step: activeStep, is_last_step: isLastStep ? 1 : 0, form_type_id: form_id, repeat: repeat, form_data: JSON.stringify(data) })
            await Helper.makeRequest('add-loan-request', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert('Your Form has been submitted successfully.', 'success')
                    props?.history?.push(baseUrl + '/login')
                } else {
                    Helper.Alert(res?.message, 'error');
                }
            });
        } else {
            Helper.Alert('Please agree to the data protection act to continue.', 'info')
        }
    }
    return (
        <div className="tab">
            <div className="form_heading jcl">
                ALMOST DONE!
            </div>
            <p className="black font-13 fr mb-1 mb-4">In order to process your application, we need your
                agreement on our data protection policiy and procedures as outlined below.</p>

            <span className="black font-18 fr mb-1 ">Data Protection Act 2018:</span>
            <div className="fields my-3">
                <div className="terms_contetn black font-13 fr ">
                    In order to process the application for funding we will need to be supplied with
                    information, including accounts and personal
                    financial details, by you, the Client, and by other sources such as your Bank and
                    credit reference agencies.
                    We may also, when authorised by you in writing, apply for supplementary information
                    from your professional or other advisers.
                    We will record this information (whether in manually operated or automated systems)
                    and retain it only as long as we are required
                    by law to do so. We will keep it as securely as possible.
                    We will use the information to assess the application, to approach a number of
                    selected funders and, subsequently, in negotiations
                    for the issue of an offer of funding with them. In order to do so, we will disclose
                    this information as is necessary to negotiate terms
                    with a funding source, with employees of this business, any guarantor required by
                    the funder, any person or organisation necessary
                    to comply with any legal or regulatory requirements to which we may be subject, and
                    anyone else whom you may specifically
                    authorise in writing.
                    In the event that we search with credit reference agencies, we will need to disclose
                    information about you. The credit reference
                    agencies will record details of our searches. If you would like details of the
                    credit reference agencies please write to us at the
                    address stated.
                    You have the right to apply to us for details of the information we hold about you.
                    An administrative charge may be
                    made by us for doing so.
                    Credit Search Agreement
                    Capatool Wealth Ltd trading as Capatool Financial or any lender to whom we may submit
                    your application to may undertake checks
                    with credit reference agencies. When checking your details with the credit reference
                    agencies a record of the search will be made
                    which will be seen by other organisations that make searches and will create
                    association between joint applicants. You agree to
                    discuss the progress of this application and any associated searches, and agree to
                    send copies of any correspondence to our
                    professional advisers.
                    You must provide accurate and updated information. We will search records relating
                    to you at credit reference agencies (“records”).
                    They will add details of our search and your application to your records and this
                    will be seen by other organisations that make
                    searches. We may also check at fraud prevention agencies to prevent or detect fraud.
                    If false or inaccurate information is provided and fraud is identified, details will
                    be passed to fraud prevention agencies. Law
                    enforcement agencies may access and use this information. You may notify us at any
                    time if you believe that the information we
                    hold is inaccurate and apply to have such information corrected.
                    We and other organisations may also access and use this information to prevent fraud
                    and money laundering, for example, when:
                    • Checking details on applications for credit and credit related or other facilities
                    • Managing credit and credit related accounts or facilities
                    • Recovering debt
                    • Checking details on proposals and claims for all types of insurance

                </div>
                <div className="checkbox_outer inline_chek ">
                    {field[0]?.options?.length > 0 && field[0]?.options?.map((item, index) => {
                        return (
                            <div className="form-group custom_checkbox">
                                <input
                                    name={item.id}
                                    id={item?.id}
                                    type="radio"
                                    onChange={(e) => setAgree(e.target.value)}
                                    checked={item?.option_value == agree}
                                    value={item?.option_value}
                                />
                                <label htmlFor={item?.id}>{item?.option_value}</label>
                            </div>
                        )
                    }
                    )}
                    {/* <div className="form-group custom_checkbox">
                        <input
                            name="ack"
                            type="radio"
                            id="check-12"
                            value="YES, I AGREE"
                            onChange={(e) => setAgree(e.target.value)}
                            checked="true" />
                        <label htmlFor="check-12">YES, I AGREE</label>
                    </div>
                    <div className="form-group custom_checkbox"> */}
                    {/* <input
                            name="ack"
                            type="radio"
                            id="check-13"
                            value="No, I AGREE"
                            onChange={(e) => setAgree(e.target.value)} />
                        <label htmlFor="check-13">NO, I DISAGREE</label>
                    </div> */}
                </div>

                <div class="form-group mt-4">
                    <button type="button" onClick={submitHandler} class="btn1">CONTINUE
                    </button>
                </div>

                <div id="nextprevious">
                    <button type="button" onClick={props.onPrevClick} id="prevBtn" onclick="nextPrev(-1)">
                        <img src={images.right} alt="" /> BACK</button>


                    <button type="button" disabled id="nextBtn">SKIP & COME BACK LATER
                        <img src={images.right} alt="" /></button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(AlmostDone);