import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { baseUrl } from '../../../Config';
import NavItems from './navItems'

const Header = (props) => {
    const { history } = props;
    useEffect(() => {
    }, [])
    const onRegisterHandle = () => {
    }
    const onSignUpHandler = () => {
        if (history) history.push(baseUrl + "/register");
    }
    const onLoginInHandler = () => {
        if (history) history.push(baseUrl + "/login");
    }
    return (
        <div>
            <NavItems
                handleLogout={() => props.handle()}
                onSignUpClick={onSignUpHandler}
                onLoginInClick={onLoginInHandler}
            />
        </div>
    )
}

export default withRouter(Header)
