const images = {
    logo: require("../../assets/images/logo.svg").default,
    menu: require("../../assets/images/menu.svg").default,
    instagram: require("../../assets/images/instagram.svg").default,
    favicon: require('../../assets/images/favicon.svg').default,
    favicon2: require('../../assets/images/favicon2.svg').default,
    logo2: require('../../assets/images/logo2.svg').default,
    up: require('../../assets/images/up.svg').default,
    liveChat: require('../../assets/images/chat.svg').default,
    callIcon: require('../../assets/images/call.svg').default,
    mailIcon: require('../../assets/images/mail.svg').default,
    finance1: require('../../assets/images/finance-1.svg').default,
    finance2: require('../../assets/images/finance-2.svg').default,
    finance3: require('../../assets/images/finance-3.svg').default,
    finance4: require('../../assets/images/finance-4.svg').default,
    finance5: require('../../assets/images/finance-5.svg').default,
    finance6: require('../../assets/images/finance-6.svg').default,
    finance7: require('../../assets/images/finance-7.svg').default,
    finance8: require('../../assets/images/finance-8.svg').default,
    finance9: require('../../assets/images/finance-9.svg').default,
    finance10: require('../../assets/images/finance-10.svg').default,
    finance11: require('../../assets/images/finance-11.svg').default,
    finance12: require('../../assets/images/finance-12.svg').default,
    hover1: require('../../assets/images/hover-1.svg').default,
    hover2: require('../../assets/images/hover-2.svg').default,
    hover3: require('../../assets/images/hover-3.svg').default,
    hover4: require('../../assets/images/hover-4.svg').default,
    hover5: require('../../assets/images/hover-5.svg').default,
    hover6: require('../../assets/images/hover-6.svg').default,
    hover7: require('../../assets/images/hover-7.svg').default,
    hover8: require('../../assets/images/hover-8.svg').default,
    hover9: require('../../assets/images/hover-9.svg').default,
    hover10: require('../../assets/images/hover-10.svg').default,
    hover11: require('../../assets/images/hover-11.svg').default,
    hover12: require('../../assets/images/hover-12.svg').default,
    lander1: require('../../assets/images/lender1.png').default,
    lander2: require('../../assets/images/lender2.png').default,
    lander3: require('../../assets/images/lender3.png').default,
    rating: require('../../assets/images/rating.svg').default,
    start: require('../../assets/images/star.svg').default,
    edit: require('../../assets/images/edit.svg').default,
    edit2: require('../../assets/images/edit2.svg').default,
    user: require('../../assets/images/user.svg').default,
    user_ic: require('../../assets/images/user-ic.png').default,
    right: require('../../assets/images/right.svg').default,
    right2: require('../../assets/images/right2.svg').default,
    left: require('../../assets/images/left.svg').default,
    bestRates: require('../../assets/images/best-rates.svg').default,
    savesTime: require('../../assets/images/saves-times.svg').default,
    accurate: require('../../assets/images/accurate.svg').default,
    softCheck: require('../../assets/images/soft-checks.svg').default,
    loanCalculator: require('../../assets/images/loan-calculator.svg').default,
    mfCalculator: require('../../assets/images/mfcalculator.svg').default,
    logoWhite: require('../../assets/images/logo-white.svg').default,
    home: require('../../assets/images/home.svg').default,
    mydDraft: require('../../assets/images/mydraft.svg').default,
    chat2: require('../../assets/images/chat2.svg').default,
    sidebarLogo: require('../../assets/images/user-portal-sidebar-logo.svg').default,
    pdf_icon: require('../../assets/images/pdf-icon.svg').default,
    image_icon: require('../../assets/images/img-icon.svg').default,
    eye: require('../../assets/images/eye.svg').default,
    add: require('../../assets/images/add.svg').default,
    close: require('../../assets/images/close.svg').default,
    export: require('../../assets/images/export.svg').default,
    add2: require('../../assets/images/add-2.svg').default,
    info: require('../../assets/images/info.svg').default,
    menu: require('../../assets/images/hamburger-menu.svg').default,
    closeMenu: require('../../assets/images/closemenu.svg').default,
    verified: require('../../assets/images/verified.svg').default,
    returnInbox: require('../../assets/images/return-inbox.svg').default,
    check3: require('../../assets/images/check3.svg').default,
    id11: require('../../assets/images/id-11.svg').default,
    id22: require('../../assets/images/id-22.svg').default,
    id33: require('../../assets/images/id-33.svg').default,
    c3: require('../../assets/images/c3.svg').default,
    info_red: require('../../assets/images/info-red.svg').default,
    alert: require('../../assets/images/alert.svg').default,
    id1: require('../../assets/images/id-1.svg').default,
    profile: require('../../assets/images/profile.png').default,
    psd_img: require('../../assets/images/psd-img.png').default,
    send: require('../../assets/images/paper-plane.png').default,
    attach: require('../../assets/images/attach.png').default,
    gallary: require('../../assets/images/gallery.png').default,
    download: require('../../assets/images/download.png').default,
};
export default images;