
import React, { useEffect, useState } from 'react';
import images from '../../../utils/imageHandler/imageHelper';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { hideHeader, saveInboxData, saveSidebarItem, saveLoanData } from './../../../redux/actions/commonActions';
import { baseUrl, IMAGE_URL } from '../../../Config';
import Helper from '../../../utils/apiHelper/apiHelper';
import { updateProfile } from '../../../redux/actions/authActions';

const Sidebar = (props) => {
    const { user, updateUser } = props;
    const [activeLink, setActiveLink] = useState(1);

    useEffect(() => {
        props.hideHeader(false);
        getInbox();
        return () => {
            setActiveLink({});
            props.saveSidebarItem(1)
        };
    }, [])
    const toggleSidebar = () => {
        let element = document.getElementById("portalsidebar");
        element.classList.add("show");
        let element2 = document.getElementById("overlay");
        element2.classList.add("fade");
    }
    const hideSidebar = () => {
        let element = document.getElementById("portalsidebar");
        element.classList.remove("show");
        let element2 = document.getElementById("overlay");
        element2.classList.remove("fade");
    }
    const sidebarClickHandler = (link) => {
        hideSidebar();
        setActiveLink(link);
        props.saveSidebarItem(link)
        if (link == 2)
            getInbox();
    }
    const sidebarItems = [
        {
            id: 1,
            name: "PORTAL",
            to: "/",
            className: "icon icon-home-ic",
            img: '',
            count: ''
        },
        {
            id: 2,
            name: "INBOX",
            to: "/inbox",
            className: "icon icon-inbox-ic",
            img: '',
            count: props?.inboxCount?.count
        },
        {
            id: 3,
            name: "LOANS",
            to: "/loans",
            className: "icon icon-loan-ic",
            img: '',
            count: props?.inboxCount?.loanCount
        },
        {
            id: 4,
            name: "DOCUMENTS",
            to: "/documents",
            className: "icon icon-documents-ic",
            img: '',
            count: ''
        },
        {
            id: 5,
            name: "DRAFT FORMS",
            to: "/drafts",
            className: "side_nav_item",
            img: images.mydDraft,
            count: ''
        },
        {
            id: 6,
            name: "LIVE CHAT",
            to: "/chat",
            className: "side_nav_item",
            img: images.chat2,
            count: ''
        }, {
            id: 7,
            name: "SETTINGS",
            to: "/setting",
            className: "icon icon-settings-ic",
            img: '',
            count: ''
        },
        // {
        //     id: 8,
        //     name: "LOGOUT",
        //     to: "/login",
        //     className: "icon icon-logout-ic",
        //     img: '',
        //     count: ''
        // }
    ]

    const imageSelectHandler = (e) => {
        if (e.target.files && e.target.files.length) {
            if (e.target.files[0]?.type != ('image/jpeg' || 'image/jpeg')) {
                Helper.Alert('Please select only jpeg or jpg format.', 'error');
                document.getElementById("imgupload").value = "";
                return;
            }
            let formData = Helper.renderFormData({ profile_picture: e.target.files[0] });
            Helper.makeRequest('update-profile-image', formData).then((res) => {
                if (res.status === 'true') {
                    Helper.Alert(res.message);
                    updateUser(res)
                    document.getElementById("imgupload").value = "";
                }
                else {
                    Helper.Alert(res.message, 'error');
                    document.getElementById("imgupload").value = "";
                }
            })
        }
    }

    const getInbox = () => {
        let formData = Helper.renderFormData({
            page: 1,
            from_date: '',
            to_date: ''
        })
        Helper.makeRequest('get-inbox', formData).then(async (res) => {
            if (res.status === 'true') {
                props.inboxData(res?.total_unread_count)
            }
        })
        Helper.makeRequest('get-loan-request-list', {}).then(async (res) => {
            if (res.status === 'true') {
                props.loanData(res?.data?.total)
            }
        })
    }

    return (
        <>
            <div className="user-portal-sidebar" id="portalsidebar">
                <button className="close_open_menu">
                    <img src={images.closeMenu} onClick={hideSidebar} alt="" />
                </button>
                <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                        <span>
                            <span className="user-img d-block mx-auto">
                                <label htmlFor='imgupload' className="img-fit">
                                    <img
                                        htmlFor='imgupload'
                                        src={user.userData.profile_picture ? IMAGE_URL + user?.userData?.profile_picture : images.user_ic}
                                        alt="" />
                                </label>
                            </span>
                            <input type="file" id="imgupload" onChange={imageSelectHandler} className="imgUpload" />
                            <label className="edit-img" htmlFor='imgupload' >
                                {/* <img src={images.edit2} alt="" /> */}
                            </label>
                        </span>
                        <h3 className="text-truncate font-13 mt fb white text-center">{user?.userData?.name}</h3>
                        <h6 className="email-text font-13 fr white text-center">{user?.userData?.email}</h6>
                        <hr />
                    </div>

                    <div className="sidebar-menu">
                        {
                            sidebarItems.map(link => {
                                return (<NavLink to={baseUrl + link.to} key={link.id} exact>
                                    <li onClick={() => sidebarClickHandler(link.id)}
                                        className={(link.id == props.sidebar_item ? " active" : "")}>
                                        <a>
                                            {link.img ? <img src={link.img} alt="" /> : <span className={link.className}></span>}
                                            {link.name}{link.count ? <p> ({link.count})</p> : ""}
                                        </a>
                                    </li>
                                </NavLink>)
                            })
                        }
                        <NavLink to={baseUrl + "/"} onClick={props.onLogoutHandler} >
                            <li><a><span className="icon icon-logout-ic"></span>Logout </a></li>
                        </NavLink>
                    </div>

                    <div>
                        <img src={images.sidebarLogo} alt="" />
                    </div>
                </div >
            </div >
            <div className="portal-sidebar-overlay" onClick={hideSidebar} id="overlay"></div>
            <div className="mobile_header">
                <button className="btn-menu" onClick={toggleSidebar} id="sidebarBtnOpen">
                    <img src={images.menu} alt="" /></button>
                <div className="logodiv">
                    <a href="/"> <img src={images.logoWhite} alt="" /></a>
                </div>
            </div>
            {/* <button className="btn-menu" onClick={toggleSidebar} id="sidebarBtnOpen">
                <img src={images.menu} alt="" />
            </button> */}
        </>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer,
    inboxCount: state.commonReducer,
    sidebar_item: state.commonReducer?.sidebar_item,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
    updateUser: (data) => dispatch(updateProfile(data)),
    inboxData: (data) => dispatch(saveInboxData(data)),
    loanData: (data) => dispatch(saveLoanData(data)),
    saveSidebarItem: (data) => dispatch(saveSidebarItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
