import React from "react";
import IndicativeQuote from "./indicativeQuote";
import LoanCalculatorForm from "./loanCalculatorForm";

function LoanDetails(props) {
    return (
        <section className="loan-detail-sec">
            <div className="container">
                <div className="loan-detail-inner mx-auto">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-4">
                            <LoanCalculatorForm />
                        </div>
                        <div className="col-lg-6">
                            <IndicativeQuote />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoanDetails;