import React from 'react';
import { useState } from 'react';
import { IMAGE_URL } from '../../Config';
import images from '../../utils/imageHandler/imageHelper';

const DocumentApproved = (props) => {
    const { documentData, selectTypeHandler, type } = props;
    const [toggle, setToggle] = useState(false);
    const toggleDiv = () => {
        setToggle(!toggle)

        if (!toggle) {
            let cols = document.getElementsByClassName('toggle_div');
            for (let i = 0; i < cols.length; i++) {
                cols[i].style.display = 'block';
            }
        } else {
            let cols = document.getElementsByClassName('toggle_div');
            for (let i = 0; i < cols.length; i++) {
                cols[i].style.display = 'none';
            }
        }


    }
    return (
        <div className="border-0 card mb-5">
            <div className="p-4 df jcb border-bottom verify_btn_Part">
                <b className=" font-18">ID Verification Required for ALL Loans: </b>

                <div>
                    <span>id verified  </span>
                    <button className={toggle ? 'rotate' : ''}> <img src={images.right} onClick={toggleDiv} alt="" /></button>
                </div>
            </div>

            <div className="toggle_div">
                <div className="px-4 py-3  border-bottom">
                    <div className="row">
                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Document Verification

                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> Choose your ID type for us to verify.</p>
                        </div>

                        <div className=" form-group col-md-6 text-md-right">

                            <select className="form-control custom_select2 ls" value={type} onChange={selectTypeHandler}>
                                <option value="" className="ttu"> PLEASE SELECT... </option>
                                <option value="Driving License" className="ttu"> Driving License</option>
                                <option value="Passport" className="ttu">Passport</option>
                            </select>

                        </div>
                    </div>


                </div>

                <div className="px-4 py-3   border-bottom">
                    <div className="row">
                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo or Scan of Your Picture ID Document - <b>Front</b>
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>

                        <div className=" form-group col-md-6">

                            <div className="small_id">
                                {documentData?.identity_front != null ?
                                    <img src={IMAGE_URL + documentData?.identity_front} className="mr-2 w-100 " />
                                    : <img src={images.id11} className="mr-2 w-100 " />
                                }
                                <img src={images.close} className="close" />
                            </div>

                        </div>


                    </div>


                </div>

                <div className="px-4 py-3   border-bottom">


                    <div className="row">
                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo or Scan of Your Picture ID Document - <b>Back</b>
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>

                        <div className=" form-group col-md-6">

                            <div className="small_id">
                                {documentData?.identity_back != null ?
                                    <img src={IMAGE_URL + documentData?.identity_back} className="mr-2 w-100 " />
                                    : <img src={images.id22} className="mr-2 w-100 " />
                                }
                                <img src={images.close} className="close" />
                            </div>

                        </div>


                    </div>


                </div>


                <div className="px-4 py-3   border-bottom">


                    <div className="row">
                        <div className=" form-group col-md-6">
                            <span className="font-18 ">Photo (Selfie) of You Holding Your Picture ID Document
                            </span>
                            <p className="font-18 mt-2 mb-0 primary"> (i.e. Passport or Driving License)</p>
                        </div>

                        <div className=" form-group col-md-6">
                            <div className="small_id">
                                {documentData?.identity_front != null ?
                                    <img src={IMAGE_URL + documentData?.photo} className="mr-2 w-100 " />
                                    : <img src={images.id33} className="mr-2 w-100 " />
                                }
                                <img src={images.close} className="close" />
                            </div>

                        </div>


                    </div>


                </div>
            </div>
        </div>
    );
}

export default DocumentApproved;