import React from 'react';
import { withRouter } from 'react-router-dom';
import Loans from '../../components/userLoans/userLoans';
import { connect } from 'react-redux';

function UserLoans(props) {
    const { user } = props;
    return (
        <div className="user-portal-home">
            <Loans user={user?.userData} />
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer
});

export default withRouter(connect(mapStateToProps)(UserLoans));