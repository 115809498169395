
import React, { useState } from 'react';
import Helper from '../../utils/apiHelper/apiHelper';
import images from '../../utils/imageHandler/imageHelper';
import WhiteFooter from '../userLayout/footer/whiteFooter';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from 'react-device-detect';

function Inbox(props) {
    const {
        user,
        list,
        hasMore,
        paginationClickHandler,
        activePage,
        endDate,
        startDate,
        fetchMoreData,
        emailViewHandler,
        setStartDate,
        setEndDate,
        submitFilter
    } = props;
    return (
        <div className="portal-right-wrapper ">
            <div className="user-my_inbox w-100">
                <h2 className="heading_with_border font-28 black ttc">
                    {Helper.getGreetings()} {user?.name}. <br />
                    Your Capatool Financial Inbox:
                </h2>
                <p className="mb-0 font-14 ">Let's get things done. Here you'll find all copies of messages that we
                    send to you.</p>
                <p className="mb-0 font-14 black">These message have also been sent to your registered email with us.</p>
                <div className="table-responsive d-none d-md-block">
                    <div style={{ float: 'right' }}>
                        <DatePicker
                            className="date-picker"
                            selected={startDate}
                            placeholderText="From Date"
                            maxDate={new Date()}
                            onChange={setStartDate} />
                        <DatePicker
                            className="date-picker"
                            selected={endDate}
                            placeholderText="To Date"
                            maxDate={new Date()}
                            onChange={setEndDate} />
                        <button type='button'
                            onClick={submitFilter}
                            className="btn1">SUBMIT</button>
                    </div>
                    {list?.data?.length > 0 ?
                        <table className="table table-bordered admin-table bg-white font-14">
                            <tbody>
                                {list?.data && list.data.map((item, index) => (
                                    <tr className={item.read_status ? "cursor-pointer" : "fb cursor-pointer"}
                                        onClick={() => emailViewHandler(item)}
                                        key={index}>
                                        <td>Capatool Financial</td>
                                        <td>{item?.subject}</td>
                                        <td>{item?.created_at.split('T')[0]}</td>
                                    </tr>
                                ))}
                                {list?.data && <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <button className="btn1" disabled={activePage == 1} onClick={() => paginationClickHandler('previous')}> Previous</button>
                                        <button className="btn1"> {activePage}</button>
                                        <button className="btn1" disabled={list?.last_page == activePage} onClick={() => paginationClickHandler('next')}> Next</button>
                                    </td>
                                </tr>}
                            </tbody>
                        </table> :
                        <table className="table table-bordered admin-table bg-white font-14">
                            <p>No record found.</p>
                        </table>}

                </div>

                {/* mobile view */}
                {isMobile && <div className="mobile_custom_table inbx">
                    <div style={{ display: 'flex', width: '100%', margin: '20px 0 20px 0' }}>
                        <DatePicker
                            className="date-picker"
                            selected={startDate}
                            placeholderText="From Date"
                            maxDate={new Date()}
                            onChange={setStartDate} />
                        <DatePicker
                            className="date-picker"
                            selected={endDate}
                            placeholderText="To Date"
                            maxDate={new Date()}
                            onChange={setEndDate} />
                        <button type='button'
                            onClick={submitFilter}
                            className="btn1">SUBMIT</button>
                    </div>
                    <InfiniteScroll
                        dataLength={list?.data?.length || 0}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >

                        {list?.data && list.data.map((item, index) => (
                            <div className="table_box"
                                key={index}
                                onClick={() => emailViewHandler(item)}
                            >
                                <div className="row">
                                    <div className="col-9">
                                        <h3 className="font-14 black fb ttu mb-0  ">Capatool Financial</h3>
                                        <div className="media mt-2">
                                            <div className="media-body">
                                                <p className="font-12 fb black mb-0">{item.subject}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <span className="font-13 black">{item?.created_at.split('T')[0]}</span>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </InfiniteScroll>
                </div>}


                <a href="mailto:support@capatool.com" className="email-section">
                    <span>EMAIL US AT: SUPPORT@CAPATOOL.COM</span>
                </a>
            </div>

            <WhiteFooter />

        </div>);
}

export default Inbox;
