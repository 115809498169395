import React from "react";

function Recommended(props) {
    return (
        <section className="recommended">
		<div>
			<span className="primary font-11 fb ttu ls">EVERYTHING AT YOUR FINGERTIPS</span>
			<h4 className=" black fr font-28">Capatool Financial comes well recommended.</h4>
			<a href="" className="btn1 font-12">GET STARTED WITH YOUR LOAN</a>
		</div>
	</section>
    );
}

export default Recommended;