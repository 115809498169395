
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import images from '../../utils/imageHandler/imageHelper';
import SolutionsTypes from '../home/solutionsTypes';
import UserPortalFooter from '../userLayout/footer';
import { hideHeader } from './../../redux/actions/commonActions';
import { connect } from 'react-redux';
import Helper from '../../utils/apiHelper/apiHelper';
import { baseUrl } from '../../Config';
import axios, { Axios } from 'axios';

function UserPortalHome(props) {
    const { history, user, hideHeader } = props;
    const [services, setServices] = useState([]);

    useEffect(() => {
        // Helper.makeRequest('get-services', {}, 'GET').then((res) => {
        //     if (res.status === 'true') {
        //         setServices(res.data)
        //     }
        // })
        axios.get('https://capatool-be.dreamztesting.com/api/get-services', {}).then((res) => {
        console.log('res', res)
        })
    }, [])

    const solutionsClickHandler = async (title, id) => {
        if (history) {
            await history.push({ pathname: baseUrl + "/finance", state: { title: title, id: id } });
        }
    }
    return (
        <div className="user-portal-content portal-home">
            <div className="portal-content-inner">
                <h3 className="font-28  fr white ttc ">{Helper.getGreetings()} {user?.name}.</h3>
                <h3 className="heading_with_border font-28 fr white">What lending service would you like?</h3>
                <p className="font-16 white fr mb-1">We take the fuss out of ﬁnance and make lending super simple.</p>
                <p className="font-16 white fr mb-1">Simply choose your lending service below and we'll  deliver the very
                    best
                    lending solutions direct to your inbox.</p>

                <div className="user_portal_part">
                    <div className="financial_solutions p-0">
                        <div className="row">
                            {services.length ? services.map((item, index) => {
                                return <SolutionsTypes key={index} item={item} solutionsClickHandler={solutionsClickHandler} />
                            }) : ''}
                        </div>
                    </div>
                </div>
            </div>
            <UserPortalFooter />
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    hideHeader: (data) => dispatch(hideHeader(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPortalHome));
