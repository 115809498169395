import React, { useState, useEffect } from 'react';
const AmountInput = (props) => {
    const { label, placeholder, type, name, onBlur, touched, errors, handleChange, value } = props;
    const [error, setError] = useState('');
    useEffect(() => {
        if (errors && errors[name] && touched && touched[name]) {
            setError(label + ' ' + errors[name])
        } else {
            setError('')
        }
    })
    return (
        <div className="form-group amount">
            <label className="font-16">{label}</label>
            <span>£</span>
            <input type="number"
                name={name}
                placeholder={placeholder}
                className="form-control "
                onChange={handleChange}
                onBlur={onBlur}
                value={value} />
            {error && < span className="hint" style={{
                marginTop: '40px',
                left: 0
            }}>
                {error}
            </span>}
        </div>
    );
}
export default AmountInput;
