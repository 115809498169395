
import React from 'react';
import images from '../../../utils/imageHandler/imageHelper';

const AddButton = (props) => {
    const { label, onClickHandler } = props;

    return (
        <button type="button" class="add-section my-5 ttu cursor-pointer" onClick={onClickHandler} >
            <img src={images.add} alt="" />
            <span>{label} </span>
        </button>
    );
}

export default AddButton;
